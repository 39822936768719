// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,
.content {
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle  */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
    background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;IAKI,WAAW;IACX,0CAA0C;AAC9C;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA,UAAU;AACV;IACI,mBAAmB;AACvB;;AAEA,YAAY;AACZ;IACI,gBAAgB;AACpB;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');\n\nhtml,\nbody,\n#root,\n.app,\n.content {\n    width: 100%;\n    font-family: 'Source Sans Pro', sans-serif;\n}\n\n.app {\n    display: flex;\n    position: relative;\n}\n\n::-webkit-scrollbar {\n    width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n    background: #e0e0e0;\n}\n\n/* Handle  */\n::-webkit-scrollbar-thumb {\n    background: #888;\n}\n\n/* Handle on Hover */\n::-webkit-scrollbar-track:hover {\n    background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
