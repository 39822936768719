import {Box, TextField} from "@mui/material";
import {
    CONTENT_TYPE,
    METHOD,
    ParentAssistInitExternalIntegration,
    ParentAssistTenantSetting
} from "../../../../../../../interfaces/SettingType";
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import MultiKeyValueInput from "../../../../../../../components/MultiInputKeyValue";
import {toEntries, toKeyPairs, toMap} from "../../../../../../../share/ObjectUtil";
import TagInputExt from "../../../../../../../components/TagInput";
import React, {FunctionComponent} from "react";
import {contentTypeOptions, methodOptions} from "../../../../../../../share/SettingTypeConstant";

const InitConversationIntegrationSettingDetail: FunctionComponent<InitConversationIntegrationSettingProps> = ({
                                                                     values,
                                                                     parentAssistTenantSetting,
                                                                     setParentAssistTenantSetting,
                                                                     handleBlur,
                                                                     handleChange
}) => {

  return (
    <>
        <TextField
            name="parentAssistMapping.initConversationIntegrations.initUrl"
            label="Init Url"
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            value={
                (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initUrl
            }
            required={true}
        />

        <Box
            display="grid"
            gap="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <AutocompleteExt
                name="parentAssistMapping.initConversationIntegrations.initMethod"
                multiSelection={false}
                label="Init Method"
                selectedValue={
                    (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initMethod
                }
                options={methodOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                initConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                    initMethod: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initMethod = v
                    }
                }}
                disableUnselectAll={true}
                required={true}
            />

            <AutocompleteExt
                name="parentAssistMapping.initConversationIntegrations.initContentType"
                multiSelection={false}
                label="Init Content Type"
                selectedValue={
                    (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initContentType
                }
                options={contentTypeOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                initConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                    initContentType: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initContentType = v
                    }
                }}
                disableUnselectAll={true}
                required={true}
            />
        </Box>

        <MultiKeyValueInput
            style={{marginBottom: `30px`}}
            name="parentAssistMapping.initConversationIntegrations.initCustomHeaders"
            label="Init Custom Headers"
            values={
                toKeyPairs((values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initCustomHeaders)
            }
            onBlurEvent={handleBlur}
            onChangeEvent={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                    const valueChanged = toMap(v)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            initConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                initCustomHeaders: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initCustomHeaders = valueChanged
                }
            }}
            required={false}
        />

        <MultiKeyValueInput
            style={{marginBottom: `30px`}}
            name="parentAssistMapping.initConversationIntegrations.initRequestPayload"
            label="Init Request Payload"
            values={
                toKeyPairs((values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initRequestPayload)
            }
            onBlurEvent={handleBlur}
            onChangeEvent={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                    const valueChanged = new Map(v.map(({ key, value }) => [key, value]));

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            initConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                initRequestPayload: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initRequestPayload = valueChanged
                }
            }}
            required={true}
        />

        <Box
            display="grid"
            gap="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <TextField
                name="parentAssistMapping.initConversationIntegrations.initResponseIdField"
                label="Init Response Id Field"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={
                    (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseIdField
                }
                required={true}
            />

            <TextField
                name="parentAssistMapping.initConversationIntegrations.initResponseReplyField"
                label="Init Response Reply Field"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={
                    (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseReplyField
                }
                required={true}
            />
        </Box>

        <TagInputExt
            name="parentAssistMapping.initConversationIntegrations.initResponseAdditionalFields"
            label="Init Response Additional Fields"
            values={
                (values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields.map((v) => {
                    return {
                        id: v,
                        text: v,
                    }
                })
            }
            onItemAdd={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                    const valueChanged = []
                    if ((values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields) {
                        valueChanged.push(...(values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields)
                    }
                    valueChanged.push(v.id)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            initConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                initResponseAdditionalFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields = valueChanged
                }
            }}
            onItemDelete={(index) => {
                if (values.parentAssistMapping && values.parentAssistMapping.initConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations) {

                    const valueChanged: string[] = (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields
                    valueChanged.splice(index, 1)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            initConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration),
                                initResponseAdditionalFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration).initResponseAdditionalFields = valueChanged
                }
            }}
            onBlurEvent={handleBlur}
        />
    </>
  );
}

export interface InitConversationIntegrationSettingProps {
  values: ParentAssistTenantSetting;
  parentAssistTenantSetting: ParentAssistTenantSetting;
  setParentAssistTenantSetting: (value: ParentAssistTenantSetting) => void;
  handleBlur: (event: any) => void;
  handleChange: (event: any) => void;
}

/**
 * Default init conversation integration values
 */
export const defaultInitConversationIntegrations = {
    initUrl: '',
    initMethod: METHOD.POST,
    initContentType: CONTENT_TYPE.APPLICATION_JSON,
    initCustomHeaders: new Map<string, string>(), // Use Map instead of {}
    initRequestPayload: new Map<string, string>(), // Use Map instead of {}
    initResponseIdField: '',
    initResponseReplyField: '',
    initResponseAdditionalFields: [],
} as ParentAssistInitExternalIntegration

/**
 * Check if the init conversation integration is fulfilled
 * @param convertedValues
 */
export const isInitConversationConfigFulfilled = (convertedValues: any) => {
    const initIntegration = convertedValues.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration;

    if (Object.values(initIntegration.initCustomHeaders).some((v) => v === '' || v === null)) {
        return false;
    }

    if (Object.values(initIntegration.initRequestPayload).some((v) => v === '' || v === null)) {
        return false;
    }

    return true;
}

/**
 * Convert the init conversation integration values
 * @param values
 */
export const convertInitConversationIntegration = (values: ParentAssistTenantSetting) => {
    return {
        ...values.parentAssistMapping?.initConversationIntegrations,
        initCustomHeaders: toEntries((values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initCustomHeaders),
        initRequestPayload: toEntries((values.parentAssistMapping?.initConversationIntegrations as ParentAssistInitExternalIntegration).initRequestPayload),
    }
}

export default InitConversationIntegrationSettingDetail;