/**
 * Create Auth Provider
 */
import { createAuthProvider } from 'react-token-auth'
import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../extensions/EncryptStorage'
import {NEUROFROG_REACT_AUTH_TOKEN} from '../share/AuthConstant'
import { removeAllConfigurations } from './configuration'
import store from '../redux/store'
import { UPDATE_USER } from '../redux/actionType'
import axios from "axios";
import {removeToken} from "../extensions/SecureAxios";

const baseAuthURL = process.env.REACT_APP_AUTH_URL
const authClientID = process.env.REACT_APP_AUTH_CLIENT_ID
const authClientSecret = process.env.REACT_APP_AUTH_CLIENT_SECRET

/**
 * Create default encrypt store which compatible with 'react-token-auth'
 *
 * @param initData
 * @returns
 */
export const createDefaultEncryptStore = (initData = {}) => {
    const data: any = initData

    const getItem = (key: string) => {
        return JSON.stringify(data[key])
    }

    const setItem = (key: string, value: AuthToken) => {
        data[key] = value
        updateValueByKey(key, value)
    }

    const removeItem = (key: string) => {
        delete data[key]
        removeKey(key)
    }

    return { getItem, setItem, removeItem }
}

export const [useAuth, authFetch, login, logout] =
    createAuthProvider<AuthToken>({
        accessTokenKey: 'token',
        localStorageKey: NEUROFROG_REACT_AUTH_TOKEN,
        storage: createDefaultEncryptStore({
            [NEUROFROG_REACT_AUTH_TOKEN]: getValueByKey(NEUROFROG_REACT_AUTH_TOKEN),
        }),
        onUpdateToken: (token: AuthToken) => refresh(token),
    })

/**
 * Complete signout
 */
export async function signout() {
    try {
        const token = store.getState().user.user;

        await axios.post(`${baseAuthURL}/auth/revoke`, {
            username: token.user.username ? token.user.username : token.user.email,
            token: token.refreshToken,
            token_type_hint: 'refresh_token'
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    } catch (error) {
        // Add console log but prevent throwing exception
        // In case there is an error upon invoking logout api
        console.log(error)
    } finally {
        removeAllConfigurations()
        logout()
    }
}

/**
 * Signin
 * @param {*} credential
 * @returns
 */
export async function signin(credential: any) {
    const { data } = await axios.post(`${baseAuthURL}/auth/token`, {
        grant_type: 'password',
        client_id: authClientID,
        client_secret: authClientSecret,
        username: credential.username,
        password: credential.password
    }, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })

    store.dispatch({
        type: UPDATE_USER,
        payload: data,
    })

    return data
}

/**
 * Exchange new token
 * @param {*} token
 * @returns
 */
export async function refresh(token: AuthToken) {
    try {
        console.log('Refreshing token...')
        const { data } = await axios.post(
            `${baseAuthURL}/auth/token`,
            {
                grant_type: 'refresh_token',
                client_id: authClientID,
                client_secret: authClientSecret,
                username: token.user.username ? token.user.username : token.user.email,
                refresh_token: token.refreshToken,
                external_id: token.externalId
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        )
        return data
    } catch (error) {
        removeToken()
        return undefined
    }
}

/**
 * Transit token
 * @param {*} token
 * @returns
 */
export async function transit(token: AuthToken) {
    return internalTransit(token, true);
}

/**
 * Transit token
 * @param {*} token
 * @returns
 */
async function internalTransit(token: AuthToken, retry: boolean): Promise<any> {
    console.log('Transit token...')

    return axios.post(
        `${baseAuthURL}/auth/transit`,
        {
            token: token.token,
            data: JSON.stringify(token)
        },
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
    ).then(response => {
        return response.data;
    }).catch(async error => {
        if (retry && error.response && error.response.status === 401) {
            const newToken = await refresh(token)
            if (newToken) {
                token['token'] = newToken.token
                token['accessToken'] = newToken.accessToken
                token['refreshToken'] = newToken.refreshToken
                token['externalId'] = newToken.externalId

                updateValueByKey(NEUROFROG_REACT_AUTH_TOKEN, newToken);

                return internalTransit(token, false);
            }
        }

        throw error;
    });
}

export async function persistTransitData(code: string | null, state: string | null) {
    if (code === null || state === null) {
        return;
    }

    const { data } = await axios.get(`${baseAuthURL}/auth/transit/data?code=${code}&state=${state}`)

    if (data.data && data.data !== null) {
        const authToken = JSON.parse(data.data);
        login(authToken)
        store.dispatch({
            type: UPDATE_USER,
            payload: JSON.parse(data.data),
        })
    }
}

export function constructAuthRedirectUrl(code: string, dashboardUrl: string) {
    const encodedRedirectUrl = encodeURIComponent(`${dashboardUrl}?code=${code}`);
    return `${baseAuthURL}/auth/transit?redirect_uri=${encodedRedirectUrl}`;
}

export interface User {
    id: string
    externalIds: string[]
    email: string
    username: string
    preferName: string
    firstName: string
    lastName: string
    phoneNumber: string
    role: string
    scopes: AuthScope[]
    dashboards: Dashboard[]
}

export interface AuthScope {
    id: string
    type: string
}

export interface Dashboard {
    url: string
    name: string
}

export interface AuthToken {
    token: string
    accessToken: string
    refreshToken: string
    externalId: string
    user: User
}