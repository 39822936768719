import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import Header from '../../../../components/Header'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ApiError } from '../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../components/ErrorMessage'
import ButtonExt from '../../../../components/ButtonExt'
import {AccessControl, AccessControlWrapper} from "../../../../interfaces/AccessControlType";
import {updateAccessControl} from "../../../../actions/security";
import SwitchExt from "../../../../components/Switch";
import {connect} from "react-redux";

const accessControlSchema = yup.object().shape({
    email: yup.string().required('required'),
    whitelisted: yup.boolean().required('required'),
    blacklisted: yup.boolean().required('required'),
})

const SecurityAccessControlDetail: FunctionComponent<AccessControlWrapper> = ({
                                                                                  userSetting,
                                                                                  isNew = false,
                                                                                  wrapper,
                                                                              }) => {
    const navigate = useNavigate()
    const [accessControl, setAccessControl] = useState<AccessControl>(
        wrapper
            ? wrapper
            : {
                id: undefined,
                email: '',
                whitelisted: false,
                blacklisted: false,
            }
    )

    /**
     * Mutate access control create
     */
    const accessControlCreateMutation = useMutation<AccessControl, ApiError, AccessControl>(
        updateAccessControl,
        {
            onSuccess: (data) => {
                setAccessControl(data)
            },
        }
    )

    /**
     * Mutate access control update
     */
    const accessControlUpdateMutation = useMutation<AccessControl, ApiError, AccessControl>(
        updateAccessControl,
        {
            onSuccess: (data) => {
                setAccessControl(data)
            },
        }
    )

    if (accessControlCreateMutation.isSuccess) {
        navigate(`/security/access`)
    }

    /**
     * Invoke an action to create/ update access control
     * @param {*} e - event
     */
    const onSave = (values: AccessControl) => {
        if (!values.id) {
            accessControlCreateMutation.mutate(values)
        } else {
            accessControlUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing access control details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/security/access`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Locale" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {accessControlCreateMutation.isError && (
                    <ErrorMessage error={accessControlCreateMutation.error} />
                )}

                {accessControlUpdateMutation.isError && (
                    <ErrorMessage error={accessControlUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={accessControl}
                validationSchema={accessControlSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {accessControl.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            {isNew && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                            )}

                            <SwitchExt
                                name="whitelisted"
                                label="Whitelist"
                                value={values.whitelisted}
                                onChange={(enabled) => {
                                    setAccessControl({
                                        ...accessControl,
                                        whitelisted: enabled
                                    })
                                    values.whitelisted = enabled
                                }}
                            />

                            <SwitchExt
                                name="blacklisted"
                                label="Blacklist"
                                value={values.blacklisted}
                                onChange={(enabled) => {
                                    setAccessControl({
                                        ...accessControl,
                                        blacklisted: enabled
                                    })
                                    values.blacklisted = enabled
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    accessControlCreateMutation.isLoading ||
                                    accessControlUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    accessControlCreateMutation.isLoading ||
                                    accessControlUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.accessControlPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(SecurityAccessControlDetail)