import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../../extensions/EncryptStorage'
import {REMOVE_USER, UNSET_USER, UPDATE_USER} from '../actionType'

export const CURRENT_USER = 'CURRENT_USER'
export const SWITCH_USER = 'SWITCH_USER'

/**
 * Default state declaration
 */
const initialState = {
    user: getValueByKey(CURRENT_USER),
    switchUser: getValueByKey(SWITCH_USER),
}

/**
 * Update/ load user data redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function user(state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER: {
            updateValueByKey(CURRENT_USER, action.payload)
            return Object.assign({}, state, {
                user: action.payload,
            })
        }
        case REMOVE_USER: {
            removeKey(CURRENT_USER)
            return Object.assign({}, state, {
                user: undefined,
            })
        }
        case SWITCH_USER: {
            updateValueByKey(SWITCH_USER, action.payload)
            return Object.assign({}, state, {
                switchUser: action.payload,
            })
        }
        case UNSET_USER: {
            removeKey(SWITCH_USER)
            return Object.assign({}, state, {
                switchUser: undefined,
            })
        }
        default:
            return state
    }
}
