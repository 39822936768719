// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .w-md-editor-toolbar {
    background-color: #1F2A40;
} */

.w-md-editor-toolbar li > button {
    /* background-color: #141b2d; */
    margin-left: 10px;
    width: 25px;
    height: 25px;
}

/* .wmde-markdown {
    background-color: #141b2d;
  
} */

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
    width: 93px;
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul  {
    padding-top: 2px;
   
}`, "",{"version":3,"sources":["webpack://./src/styles/RichTextEditor.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;;;GAGG;;AAEH;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;;AAEpB","sourcesContent":["/* .w-md-editor-toolbar {\n    background-color: #1F2A40;\n} */\n\n.w-md-editor-toolbar li > button {\n    /* background-color: #141b2d; */\n    margin-left: 10px;\n    width: 25px;\n    height: 25px;\n}\n\n/* .wmde-markdown {\n    background-color: #141b2d;\n  \n} */\n\n.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {\n    width: 93px;\n    padding-left: 10px;\n    padding-top: 7px;\n    padding-bottom: 7px;\n}\n\n.w-md-editor-toolbar-child .w-md-editor-toolbar ul  {\n    padding-top: 2px;\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
