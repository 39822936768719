import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";

/**
 * An action to fetch server config from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchServerConfigs = async (searchOptions) => {
    let localeUrl = `/admin/server/config?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        localeUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(localeUrl)
    return data
}

/**
 * An action to update the server config
 */
export async function updateServerConfig(serverConfig) {
    const { data } = await SecureAxios.put(
        `/admin/server/config/${serverConfig.key}/update`,
        serverConfig.value,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: {
            key: 'server-configs',
            keyField: 'key',
            data: {
                ...serverConfig,
                value: data.data
            }
        },
    })

    return data.data
}