import React from 'react';
import { Box } from '@mui/material';
import {ErrorNotificationServerConfig} from "../../../../../../interfaces/ServerConfigType";
import * as yup from "yup";
import ButtonExt from "../../../../../../components/ButtonExt";
import {Formik} from "formik";
import SwitchExt from "../../../../../../components/Switch";
import TagInputExt from "../../../../../../components/TagInput";
import {connect} from "react-redux";

const errorNotificationServerConfigSchema = yup.object().shape({
    enabled: yup.boolean().required('required'),
    senders: yup.array().min(1).required('required'),
    httpStatusExclusions: yup.array().optional(),
})

const ErrorNotificationServerConfigFC: React.FC<ErrorNotificationServerConfig> = ({
                                                                                      userSetting,
                                                                                      key,
                                                                                      value,
                                                                                      setValue,
                                                                                      onChange,
                                                                                      isLoading,
                                                                                  }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={errorNotificationServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <SwitchExt
                            name="enabled"
                            label="Enabled"
                            value={values.enabled}
                            onChange={(enabled) => {
                                setValue({
                                    ...values,
                                    enabled: enabled
                                })
                                values.enabled = enabled
                            }}
                        />

                        <TagInputExt
                            name="senders"
                            values={values.senders.map((each) => {
                                return {id: each, text: each}
                            })}
                            onItemAdd={(v) => {
                                setValue({
                                    ...values,
                                    senders: [...values.senders, v.id]
                                })
                                values.senders.push(v.id)
                            }}
                            onItemDelete={(index) => {
                                setValue({
                                    ...values,
                                    senders: values.senders.filter((_, i) => i !== index)
                                })
                                values.senders.splice(index, 1)
                            }}
                            allowDragDrop={true}
                            onItemDrag={(v, currentIndex, dragIndex) => {
                                const cloneSenders = [...values.senders]
                                cloneSenders.splice(currentIndex, 1)
                                cloneSenders.splice(dragIndex, 0, v.id)
                                setValue({
                                    ...values,
                                    senders: cloneSenders
                                })
                                values.senders = cloneSenders
                            }}
                        />

                        <TagInputExt
                            name="httpStatusExclusions"
                            values={values.httpStatusExclusions.map((each) => {
                                return {id: each.toString(), text: each.toString()}
                            })}
                            onItemAdd={(v) => {
                                const value = parseInt(v.id)

                                if (!isNaN(value)) {
                                    setValue({
                                        ...values,
                                        httpStatusExclusions: [...values.httpStatusExclusions, value]
                                    })
                                    values.httpStatusExclusions.push(value)
                                }
                            }}
                            onItemDelete={(index) => {
                                setValue({
                                    ...values,
                                    httpStatusExclusions: values.httpStatusExclusions.filter((_, i) => i !== index)
                                })
                                values.httpStatusExclusions.splice(index, 1)
                            }}
                            allowDragDrop={true}
                            onItemDrag={(v, currentIndex, dragIndex) => {
                                const cloneHttpStatusExclusions = [...values.httpStatusExclusions]
                                cloneHttpStatusExclusions.splice(currentIndex, 1)
                                cloneHttpStatusExclusions.splice(dragIndex, 0, parseInt(v.id))
                                setValue({
                                    ...values,
                                    httpStatusExclusions: cloneHttpStatusExclusions
                                })
                                values.httpStatusExclusions = cloneHttpStatusExclusions
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                (!userSetting?.administratorRolePermission?.superAdmin && !userSetting?.administratorRolePermission?.serverConfigPermission?.editable)
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ErrorNotificationServerConfigFC)
