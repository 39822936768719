import React from 'react';
import {Box, TextField} from '@mui/material';
import * as yup from "yup";
import {Formik} from "formik";
import {connect} from "react-redux";
import {ContentServerConfig} from "../../../../../interfaces/ServerConfigType";
import AutocompleteExt from "../../../../../components/Autocomplete";
import {referenceItemMapperStrategyDialectOptions} from "../../../../../share/SystemConfigConstant";
import ButtonExt from "../../../../../components/ButtonExt";

const contentServerConfigSchema = yup.object().shape({
    referenceItemConversionMapperDialect: yup.string().required('required'),
    defaultSearchFilterRelationshipId: yup.string().required('required'),
    contentTypeMainPriorityRegex: yup.string().required('required'),
})

const ContentServerConfigFC: React.FC<ContentServerConfig> = ({
                                                                                          userSetting,
                                                                                          key,
                                                                                          value,
                                                                                          setValue,
                                                                                          onChange,
                                                                                          isLoading,
                                                                                      }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={contentServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <AutocompleteExt
                            name="referenceItemConversionMapperDialect"
                            multiSelection={false}
                            label="Reference Item Conversion Mapper Dialect"
                            selectedValue={
                                values.referenceItemConversionMapperDialect
                            }
                            options={referenceItemMapperStrategyDialectOptions}
                            onBlurEvent={handleBlur}
                            onSelect={(v) => {
                                setValue({
                                    ...values,
                                    referenceItemConversionMapperDialect: v
                                })
                                values.referenceItemConversionMapperDialect = v
                            }}
                            disableUnselectAll={true}
                            required={true}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Default Search Filter Relationship Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.defaultSearchFilterRelationshipId}
                            name="defaultSearchFilterRelationshipId"
                            error={!!touched.defaultSearchFilterRelationshipId && !!errors.defaultSearchFilterRelationshipId}
                            helperText={touched.defaultSearchFilterRelationshipId && errors.defaultSearchFilterRelationshipId}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Content Type Main Priority Regex"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.contentTypeMainPriorityRegex}
                            name="contentTypeMainPriorityRegex"
                            error={!!touched.contentTypeMainPriorityRegex && !!errors.contentTypeMainPriorityRegex}
                            helperText={touched.contentTypeMainPriorityRegex && errors.contentTypeMainPriorityRegex}
                        />

                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentServerConfigFC)