import React, {useState} from 'react'
import {Box} from '@mui/material'
import {connect} from "react-redux";
import {
    ContentTenantInterestMapping,
    ContentTenantInterestMappingFilterCustomFilterOptions
} from "../../../../../interfaces/ContentAssistType";
import {TenantSetting} from "../../../../../interfaces/SettingType";
import DataGridFilter, {SearchOptionsProp} from "../../../../../components/DataGridFilter";
import {fetchAllTenants} from "../../../../../actions/tenant";
import {useAuthQueryWithQueryFunction} from "../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../interfaces/ErrorType";
import {Tenant} from "../../../../../interfaces/TenantType";
import AutocompleteExt from "../../../../../components/Autocomplete";
import Header from "../../../../../components/Header";
import {fetchContentTenantInterestMappings} from "../../../../../actions/contentAssists";
import ContentTenantInterestMappingDetail from "./detail";

/**
 * Content tenant interest mappings
 *
 * @returns content tenant interest mappings page
 */
function ContentTenantInterestMappings(props: {userSetting: TenantSetting}) {

    const {userSetting} = props

    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentTenantInterestMappingFilterCustomFilterOptions>({
            tenantId: '',
        })

    const expandRow = (row: ContentTenantInterestMapping) => (
        <ContentTenantInterestMappingDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContentTenantInterestMappings(searchOptions)
    }

    /**
     * Fetch all tenants
     * */
    const tenantQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Tenant[]
        >('tenants', fetchAllTenants, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTenantOptions = tenantQuery.data?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant.id,
        }
    })

    const customSearchOptionsRenderer = () => (
        <AutocompleteExt
            name="tenantId"
            multiSelection={false}
            label="Tenant..."
            selectedValue={customSearchOptions.tenantId}
            onSelect={(value) => {
                setCustomSearchOptions({
                    ...customSearchOptions,
                    tenantId: value,
                })
            }}
            options={availableTenantOptions}
        />
    )

    const columns = [
        {
            dataField: 'tenant.name',
            text: 'Tenant',
            sort: false,
        },
        {
            dataField: 'developmentStage.type',
            text: 'Development Stage',
            sort: false,
        },
        {
            dataField: 'relationship.name',
            text: 'Relationship',
            sort: false,
        },
        {
            dataField: `eligibleRangeStart`,
            text: `Eligible Range Start`,
            sort: true,
        },
        {
            dataField: `eligibleRangeEnd`,
            text: `Eligible Range End`,
            sort: true,
        },
    ]

    /**
     * Render the content tenant interest mappings page
     */
    return (
        <Box m="20px">
            <Header title="Content Tenant Interest Mappings" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`contentTenantInterestMappings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/content/tenant/interest/mapping/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.contentAssistPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentTenantInterestMappings)