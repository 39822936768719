import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { Box } from '@mui/material'
import SecurityAccessControlDetail from "./detail";
import {AccessControl} from "../../../interfaces/AccessControlType";
import {fetchAccessControl} from "../../../actions/security";
import {connect} from "react-redux";
import {TenantSetting} from "../../../interfaces/SettingType";

/**
 * Security Access Control list
 *
 * @returns security access control list page
 */
function SecurityAccessControl(props: {userSetting: TenantSetting}) {
    const {userSetting} = props

    const expandRow = (row: AccessControl) => (
        <SecurityAccessControlDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAccessControl(searchOptions)
    }

    const columns = [
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'whitelisted',
            text: 'Whitelist',
            sort: false,
        },
        {
            dataField: 'blacklisted',
            text: 'Blacklist',
            sort: false,
        },
    ]

    /**
     * Render the access control through Table component
     */
    return (
        <Box m="20px">
            <Header title="Access Controls" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`access-control`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/security/access/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.accessControlPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}


/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(SecurityAccessControl)
