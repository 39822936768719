import {Box, TextField} from "@mui/material";
import {
    ASYNC_PREFERENCE,
    CONTENT_TYPE,
    METHOD, PARAM_TYPE,
    ParentAssistResumeExternalIntegration,
    ParentAssistTenantSetting
} from "../../../../../../../interfaces/SettingType";
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import MultiKeyValueInput from "../../../../../../../components/MultiInputKeyValue";
import {toEntries, toKeyPairs, toMap} from "../../../../../../../share/ObjectUtil";
import TagInputExt from "../../../../../../../components/TagInput";
import React, {FunctionComponent} from "react";
import {
    contentTypeOptions,
    methodOptions,
    paramTypeOptions,
    resumeAsyncPreferenceOptions
} from "../../../../../../../share/SettingTypeConstant";
import CheckboxExt from "../../../../../../../components/Checkbox";
import PasswordInput from "../../../../../../../components/PasswordInput";

const ResumeConversationIntegrationSettingDetail: FunctionComponent<ResumeConversationIntegrationSettingProps> = ({
                                                                     values,
                                                                     parentAssistTenantSetting,
                                                                     setParentAssistTenantSetting,
                                                                     handleBlur,
                                                                     handleChange
}) => {

  return (
    <>
        <TextField
            style={{marginTop: `30px`}}
            name="parentAssistMapping.resumeConversationIntegrations.resumeUrl"
            label="Resume Url"
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            value={
                (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeUrl
            }
            required={true}
        />

        <Box
            display="grid"
            gap="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <AutocompleteExt
                name="parentAssistMapping.resumeConversationIntegrations.resumeMethod"
                multiSelection={false}
                label="Resume Method"
                selectedValue={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeMethod
                }
                options={methodOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                resumeConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                    resumeMethod: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeMethod = v
                    }
                }}
                disableUnselectAll={true}
                required={true}
            />

            <AutocompleteExt
                name="parentAssistMapping.resumeConversationIntegrations.resumeContentType"
                multiSelection={false}
                label="Resume Content Type"
                selectedValue={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeContentType
                }
                options={contentTypeOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                resumeConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                    resumeContentType: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeContentType = v
                    }
                }}
                disableUnselectAll={true}
                required={true}
            />
        </Box>

        <MultiKeyValueInput
            style={{marginBottom: `30px`}}
            name="parentAssistMapping.resumeConversationIntegrations.resumeCustomHeaders"
            label="Resume Custom Headers"
            values={
                toKeyPairs((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeCustomHeaders)
            }
            onBlurEvent={handleBlur}
            onChangeEvent={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged = toMap(v)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeCustomHeaders: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeCustomHeaders = valueChanged
                }
            }}
        />

        <Box
            display="grid"
            gap="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <TextField
                name="parentAssistMapping.resumeConversationIntegrations.resumeRequestIdField"
                label="Resume Request Id Field"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestIdField
                }
                required={true}
            />

            <AutocompleteExt
                name="parentAssistMapping.resumeConversationIntegrations.resumeRequestIdType"
                multiSelection={false}
                label="Resume Request Id Type"
                selectedValue={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestIdType
                }
                options={paramTypeOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                resumeConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                    resumeRequestIdType: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestIdType = v
                    }
                }}
                disableUnselectAll={true}
                required={true}
            />
        </Box>

        <TagInputExt
            name="parentAssistMapping.resumeConversationIntegrations.resumeRequestPayloadFields"
            label="Resume Request Payload Fields"
            values={
                (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields.map((v) => {
                    return {
                        id: v,
                        text: v,
                    }
                })
            }
            onItemAdd={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged = []
                    if ((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields) {
                        valueChanged.push(...(values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields)
                    }
                    valueChanged.push(v.id)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeRequestPayloadFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields = valueChanged
                }
            }}
            onItemDelete={(index) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged: string[] = (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields
                    valueChanged.splice(index, 1)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeRequestPayloadFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestPayloadFields = valueChanged
                }
            }}
            onBlurEvent={handleBlur}
        />

        <MultiKeyValueInput
            style={{marginTop: `30px`, marginBottom: `30px`}}
            name="parentAssistMapping.resumeConversationIntegrations.resumeRequestDefaultPayloads"
            label="Resume Request Default Payloads"
            values={
                toKeyPairs((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestDefaultPayloads)
            }
            onBlurEvent={handleBlur}
            onChangeEvent={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged = toMap(v)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeRequestDefaultPayloads: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestDefaultPayloads = valueChanged
                }
            }}
        />

        <Box
            display="grid"
            gap="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <TextField
                style={{marginTop: `30px`}}
                name="parentAssistMapping.resumeConversationIntegrations.resumeResponseAnswerField"
                label="Resume Response Answer Field"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAnswerField
                }
                required={true}
            />
        </Box>

        <TagInputExt
            name="parentAssistMapping.resumeConversationIntegrations.resumeResponseAdditionalFields"
            label="Resume Response Additional Fields"
            values={
                (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields.map((v) => {
                    return {
                        id: v,
                        text: v,
                    }
                })
            }
            onItemAdd={(v) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged = []
                    if ((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields) {
                        valueChanged.push(...(values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields)
                    }
                    valueChanged.push(v.id)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeResponseAdditionalFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields = valueChanged
                }
            }}
            onItemDelete={(index) => {
                if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                    && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                    const valueChanged: string[] = (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields
                    valueChanged.splice(index, 1)

                    setParentAssistTenantSetting({
                        ...parentAssistTenantSetting,
                        parentAssistMapping: {
                            ...parentAssistTenantSetting.parentAssistMapping,
                            resumeConversationIntegrations: {
                                ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                resumeResponseAdditionalFields: valueChanged
                            },
                        },
                    });
                    (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeResponseAdditionalFields = valueChanged
                }
            }}
            onBlurEvent={handleBlur}
        />

        <Box
            display="grid"
            gap="30px"
            mt="30px"
            mb="30px"
            gridTemplateColumns="repeat(2, minmax(0,1fr))"
        >
            <CheckboxExt
                name="parentAssistMapping.resumeConversationIntegrations.resumeAsync"
                label="Resume Async"
                onBlurEvent={handleBlur}
                onChangeEvent={handleChange}
                value={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsync
                }
            />

            <AutocompleteExt
                name="parentAssistMapping.resumeConversationIntegrations.resumeAsyncPreference"
                multiSelection={false}
                label="Resume Async Preference"
                selectedValue={
                    (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsyncPreference
                }
                options={resumeAsyncPreferenceOptions}
                onBlurEvent={handleBlur}
                onSelect={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.resumeConversationIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations) {

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                resumeConversationIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration),
                                    resumeAsyncPreference: v,
                                },
                            },
                        });
                        (values.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsyncPreference = v
                    }
                }}
                disableUnselectAll={true}
                required={(values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsync && (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsyncPreference === 'REDIRECT_URL'}
            />
        </Box>

        <TextField
            name="parentAssistMapping.resumeConversationIntegrations.resumeRedirectUrl"
            label="Resume Redirect Url"
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            value={
                (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRedirectUrl
            }
            required={(values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsync && (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsyncPreference === 'REDIRECT_URL'}
        />

        <PasswordInput
            style={{marginTop: `30px`}}
            name="parentAssistMapping.resumeConversationIntegrations.resumeRedirectUrlAuthorizationKey"
            label="Resume Redirect Url Authorization Key"
            onBlurEvent={handleBlur}
            onChangeEvent={handleChange}
            value={
                (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRedirectUrlAuthorizationKey
            }
            required={(values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsync && (values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeAsyncPreference === 'REDIRECT_URL'}
        />
    </>
  );
}

export interface ResumeConversationIntegrationSettingProps {
  values: ParentAssistTenantSetting;
  parentAssistTenantSetting: ParentAssistTenantSetting;
  setParentAssistTenantSetting: (value: ParentAssistTenantSetting) => void;
  handleBlur: (event: any) => void;
  handleChange: (event: any) => void;
}

/**
 * Default resume conversation integration setting
 */
export const defaultResumeConversationIntegrations = {
        resumeUrl: '',
        resumeMethod: METHOD.POST,
        resumeContentType: CONTENT_TYPE.APPLICATION_JSON,
        resumeCustomHeaders: new Map<string, string>(), // Use Map instead of {}
        resumeRequestIdField: '',
        resumeRequestIdType: PARAM_TYPE.PATH,
        resumeRequestPayloadFields: [],
        resumeRequestDefaultPayloads: new Map<string, string>(), // Use Map instead of {}
        resumeResponseAnswerField: '',
        resumeResponseAdditionalFields: [],
        resumeAsync: false,
        resumeAsyncPreference: ASYNC_PREFERENCE.STATUS,
        resumeRedirectUrl: '',
        resumeRedirectUrlAuthorizationKey: '',
    } as ParentAssistResumeExternalIntegration

/**
 * Check if the resume conversation integration setting is fulfilled
 * @param convertedValues
 */
export const isResumeConversationConfigFulfilled = (convertedValues: any) => {
    const resumeIntegration = convertedValues.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration;

    if (Object.values(resumeIntegration.resumeCustomHeaders).some((v) => v === '' || v === null)) {
        return false;
    }

    return true;
}

/**
 * Convert the resume conversation integration setting
 * @param values
 */
export const convertResumeConversationIntegrationSetting = (values: ParentAssistTenantSetting) => {
    return {
        ...values.parentAssistMapping?.resumeConversationIntegrations,
        resumeCustomHeaders: toEntries((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeCustomHeaders),
        resumeRequestDefaultPayloads: toEntries((values.parentAssistMapping?.resumeConversationIntegrations as ParentAssistResumeExternalIntegration).resumeRequestDefaultPayloads),
    }
}

export default ResumeConversationIntegrationSettingDetail;