export const translationProviderOptions = [
    {
        label: 'Aws',
        value: 'AWS',
    },
    {
        label: 'Google',
        value: 'GOOGLE',
    },
    {
        label: 'Open AI',
        value: 'OPENAI',
    }
]

export const referenceItemMapperStrategyDialectOptions = [
    {
        label: 'Default',
        value: 'DEFAULT',
    },
    {
        label: 'Advance',
        value: 'ADVANCE',
    }
]