import { Alert, AlertTitle } from '@mui/material'
import { ApiError } from '../interfaces/ErrorType'
import { FunctionComponent } from 'react'

const ErrorMessage: FunctionComponent<ErrorMessageProp> = ({ error }) => {
    const knownError = error?.response?.data?.error
    const errorCode = knownError?.code
    const errorMessage = knownError?.message
        ? knownError?.message
        : 'Unknown Server Error'

    return (
        <Alert severity="error">
            <AlertTitle>Error {errorCode}</AlertTitle>
            {errorMessage}
        </Alert>
    )
}

interface ErrorMessageProp {
    error?: ApiError | undefined
}

export default ErrorMessage
