import React from 'react';
import { Box } from '@mui/material';
import * as yup from "yup";
import ButtonExt from "../../../../../../components/ButtonExt";
import {Formik} from "formik";
import {AccessControlServerConfig} from "../../../../../../interfaces/ServerConfigType";
import SwitchExt from "../../../../../../components/Switch";
import {connect} from "react-redux";

const accessControlServerConfigSchema = yup.object().shape({
    enabled: yup.boolean().required('required'),
})

const AccessControlServerConfigFC: React.FC<AccessControlServerConfig> = ({
                                                                              userSetting,
                                                                              key,
                                                                              value,
                                                                              setValue,
                                                                              onChange,
                                                                              isLoading,
                                                              }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={accessControlServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <SwitchExt
                            name="enabled"
                            label="Enabled"
                            value={values.enabled}
                            onChange={(enabled) => {
                                setValue({
                                    ...values,
                                    enabled: enabled
                                })
                                values.enabled = enabled
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(AccessControlServerConfigFC)
