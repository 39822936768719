import React, { FunctionComponent, useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SendIcon from '@mui/icons-material/Send'
import ButtonExt from '../../../../components/ButtonExt'
import TextareaAutosizeExt from '../../../../components/TextareaAutosize'
import { InputLabel } from '@mui/material'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapForm: {
            display: 'flex',
            justifyContent: 'center',
            width: '98%',
            margin: `${theme.spacing(0)} auto`,
        },
        wrapText: {
            width: '100%',
        },
        button: {
            margin: theme.spacing(1),
        },
    })
)

const Reply: FunctionComponent<InputListener> = ({
                                                         onMessage,
                                                         disabled = false,
                                                         maxLength,
                                                     }) => {
    const classes = useStyles()
    const [value, setValue] = useState<string>()

    const handleSend = () => {
        if (value) {
            onMessage(value)
            setValue('')
        }
    }

    const calcRemainingCharactersLimit = () => {
        return value ? maxLength - value.length : maxLength
    }

    return (
        <>
            <form className={classes.wrapForm} noValidate autoComplete="off">
                <TextareaAutosizeExt
                    style={{width: `100%`}}
                    name="reply"
                    label=""
                    value={value}
                    onChange={(value) => setValue(value)}
                    editable={!disabled}
                    maxLength={maxLength}
                    minRows={6}
                    maxRows={6}
                />
                <ButtonExt
                    icon={<SendIcon />}
                    value={''}
                    onClickEvent={handleSend}
                    disabled={disabled || !value}
                    className={classes.button}
                />
            </form>
            <InputLabel style={{color: `black`, width: `100%`, marginLeft: `50px`}}>{`Write your query here [ Maximum ${maxLength} characters (${calcRemainingCharactersLimit()} remaining) ]`}</InputLabel>
        </>
    )
}

interface InputListener {
    onMessage: (v: string) => void // Handle new message request
    disabled?: boolean | undefined
    maxLength: number
}

export default Reply