export const supportedMediaTypeOptions = [
    {
        value: "IMAGE",
        label: "Image"
    },
    {
        value: "AUDIO",
        label: "Audio"
    },
    {
        value: "VIDEO",
        label: "Video"
    }
]