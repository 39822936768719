import React, { useState } from 'react'
import { connect } from 'react-redux'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import {
    fetchContents,
    fetchRelationships,
    generateContentReport
} from '../../../actions/contentAssists'
import { useAuthQueryWithQueryFunction } from '../../../extensions/UseAuthQuery'
import { Box, TextField } from '@mui/material'
import Header from '../../../components/Header'
import SelectExt from '../../../components/Select'
import ContentAssistDetail from './detail'
import AutocompleteExt from '../../../components/Autocomplete'
import {ContentAssist, ContentAssistFilterCustomFilterOptions} from '../../../interfaces/ContentAssistType'
import { stateOptions } from '../../../share/DefaultConstant'
import { ApiError } from '../../../interfaces/ErrorType'
import {KeyPairs, TenantSetting} from "../../../interfaces/SettingType";
import {
    calculateWeekRangeByMonthAndSelectedDevelopmentStage,
    fetchDevelopmentStages
} from "../../../actions/developmentStage";
import {useMutation} from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import CheckboxExt from "../../../components/Checkbox";

/**
 * Render Content Assist page
 *
 * @returns contents
 */
function ContentAssists(props: { localeList: any, userSetting: TenantSetting | undefined }) {
    const [change, setChange] = useState<string>()
    const translationList = props.localeList
    const contentLocalizations = props.userSetting?.contentAssistMapping?.localizations
    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentAssistFilterCustomFilterOptions>({
            states: [],
            developmentStage: undefined,
            month: NaN,
            weekFrom: NaN,
            weekTo: NaN,
            delayWeekFrom: NaN,
            delayWeekTo: NaN,
            relationshipId: '',
            contentLocalizationCode: [],
            translateTo: '',
            contentTypeRegex: '',
            contentTypeExclusiveRegex: '',
            milestone: false,
        })

    const translationOptions = translationList.map((locale: any) => {
        return {
            label: locale.name,
            value: locale.code,
        }
    })

    const contentLocalizationOptions = contentLocalizations?.map((contentLocalization: KeyPairs) => {
        return {
            label: `${contentLocalization.values[1]} (${contentLocalization.values[0]})`,
            value: contentLocalization.values[0],
        }
    })

    const expandRow = (row: ContentAssist) => (
        <ContentAssistDetail key={row.id} isNew={false} wrapper={row}  callback={setChange} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContents(searchOptions)
    }

    const onDownloadEvent = (searchOptions: SearchOptionsProp) => {
        return generateContentReport(searchOptions)
    }

    /**
     * Fetch relationship list
     * */
    const contentRelationshipQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentRelationships', fetchRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Calculate week range by month and selected development stage
     */
    const calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekRangeByMonthAndSelectedDevelopmentStage)

    /**
     * Available relationship options
     */
    const availableRelationshipOptions = contentRelationshipQuery.data?.map((contentRelationship) => {
        return {
            value: contentRelationship.id,
            label: contentRelationship.name,
        }
    })

    const developmentStages = developmentStageQuery.data

    const availableDevelopmentStageOptions = developmentStages?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    })

    const customSearchOptionsRenderer = () => (
        <>
            <SelectExt
                name="states"
                multiSelection={true}
                label="States..."
                selectedValue={customSearchOptions.states}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        states: value,
                    })
                }}
                options={stateOptions}
            />

            <AutocompleteExt
                name="developmentStageId"
                multiSelection={false}
                label="Development Stage..."
                selectedValue={customSearchOptions.developmentStage?.id}
                onSelect={(value) => {
                    const selectedDevelopmentStage = developmentStages?.find((developmentStage) => developmentStage.id === value)
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        developmentStage: selectedDevelopmentStage,
                        month: NaN,
                        weekFrom: selectedDevelopmentStage?.weekFrom,
                        weekTo: selectedDevelopmentStage?.weekTo,
                        delayWeekFrom: NaN,
                        delayWeekTo: NaN,
                    })
                }}
                options={availableDevelopmentStageOptions}
            />

            {customSearchOptions.developmentStage?.supportedMonthConversion && (
                <TextField
                    type="number"
                    variant="filled"
                    name="month"
                    value={customSearchOptions.month}
                    label={`Month (${customSearchOptions.developmentStage?.validMonthFrom} - ${customSearchOptions.developmentStage?.validMonthTo})`}
                    InputProps={{
                        inputProps: {
                            min: customSearchOptions.developmentStage?.validMonthFrom,
                            max: customSearchOptions.developmentStage?.validMonthTo,
                        },
                    }}
                    onBlur={(event) => {
                        if (!customSearchOptions.month) {
                            return;
                        }

                        if (customSearchOptions.month < customSearchOptions.developmentStage!!.validMonthFrom || customSearchOptions.month > customSearchOptions.developmentStage!!.validMonthTo) {
                            return;
                        }

                        calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.mutate({
                            month: customSearchOptions.month,
                            developmentStageId: customSearchOptions.developmentStage?.id,
                        }, {
                            onSuccess: (data) => {
                                setCustomSearchOptions({
                                    ...customSearchOptions,
                                    month: customSearchOptions.month,
                                    weekFrom: data[0],
                                    weekTo: data[1],
                                    delayWeekFrom: NaN,
                                    delayWeekTo: NaN,
                                })
                            }
                        })
                    }}
                    onChange={(event) => {
                        if (!event.target.value) {
                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: NaN,
                                weekFrom: NaN,
                                weekTo: NaN,
                                delayWeekFrom: NaN,
                                delayWeekTo: NaN,
                            })
                        } else {
                            const month = parseInt(event.target.value)

                            setCustomSearchOptions({
                                ...customSearchOptions,
                                month: month,
                            })
                        }
                    }}
                />
            )}

            <TextField
                type="number"
                variant="filled"
                name="weekFrom"
                value={customSearchOptions.weekFrom}
                label={customSearchOptions.developmentStage?.id ? `Week (${customSearchOptions.developmentStage?.weekFrom} - ${customSearchOptions.developmentStage?.weekTo})` : 'Week...' }
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.developmentStage?.weekFrom,
                        max: customSearchOptions.developmentStage?.weekTo,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekFrom: NaN,
                            weekTo: NaN,
                            delayWeekFrom: NaN,
                            delayWeekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekFrom: parseInt(event.target.value),
                            weekTo: NaN,
                            delayWeekFrom: NaN,
                            delayWeekTo: NaN,
                        })
                    }
                }}
                required={customSearchOptions.developmentStage?.id !== undefined}
            />

            <TextField
                type="number"
                variant="filled"
                name="weekTo"
                value={customSearchOptions.weekTo}
                label={customSearchOptions.developmentStage?.id ? `Week To (Max ${customSearchOptions.developmentStage?.weekTo})` : 'Week To...' }
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.weekFrom ? customSearchOptions.weekFrom : customSearchOptions.developmentStage?.weekFrom,
                        max: customSearchOptions.developmentStage?.weekTo,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekTo: NaN,
                            delayWeekFrom: NaN,
                            delayWeekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            weekTo: parseInt(event.target.value),
                            delayWeekFrom: NaN,
                            delayWeekTo: NaN,
                        })
                    }
                }}
                disabled={!customSearchOptions.weekFrom}
            />

            <TextField
                type="number"
                variant="filled"
                name="delayWeekFrom"
                value={customSearchOptions.delayWeekFrom}
                label={`${customSearchOptions.developmentStage?.id && customSearchOptions.developmentStage?.maxThreshold ? `Delay Week (1 - ${customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo})` : 'Delay Week...'} `}
                InputProps={{
                    inputProps: {
                        min: 1,
                        max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            delayWeekFrom: NaN,
                            delayWeekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            delayWeekFrom: parseInt(event.target.value),
                            delayWeekTo: NaN,
                        })
                    }
                }}
                disabled={customSearchOptions.developmentStage && (!customSearchOptions.developmentStage?.maxThreshold || customSearchOptions.weekFrom !== customSearchOptions.developmentStage.weekTo)}
            />

            <TextField
                type="number"
                variant="filled"
                name="delayWeekTo"
                value={customSearchOptions.delayWeekTo}
                label={`${customSearchOptions.developmentStage?.id ? `Delay Week To (Max ${customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1})` : 'Delay Week To...'} `}
                InputProps={{
                    inputProps: {
                        min: customSearchOptions.delayWeekFrom ? customSearchOptions.delayWeekFrom : (customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1),
                        max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1,
                    },
                }}
                onChange={(event) => {
                    if (!event.target.value) {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            delayWeekTo: NaN,
                        })
                    } else {
                        setCustomSearchOptions({
                            ...customSearchOptions,
                            delayWeekTo: parseInt(event.target.value),
                        })
                    }
                }}
                disabled={!customSearchOptions.delayWeekFrom || (customSearchOptions.developmentStage && !customSearchOptions.developmentStage?.maxThreshold)}
            />

            <AutocompleteExt
                name="relationshipId"
                multiSelection={false}
                label="Relationship..."
                selectedValue={customSearchOptions.relationshipId}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        relationshipId: value
                    })
                }}
                options={availableRelationshipOptions}
            />

            <AutocompleteExt
                name="contentLocalizationCode"
                multiSelection={true}
                label="Content localization..."
                selectedValue={customSearchOptions.contentLocalizationCode}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentLocalizationCode: value,
                    })
                }}
                options={contentLocalizationOptions}
            />

            <TextField
                variant="filled"
                name="contentTypeRegex"
                value={customSearchOptions.contentTypeRegex}
                label="Content Type Regex..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentTypeRegex: event.target.value,
                    })
                }}
            />

            <TextField
                variant="filled"
                name="contentTypeExclusiveRegex"
                value={customSearchOptions.contentTypeExclusiveRegex}
                label="Content Type Exclusive Regex..."
                onChange={(event) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        contentTypeExclusiveRegex: event.target.value,
                    })
                }}
                disabled={!customSearchOptions.contentTypeRegex}
            />

            <CheckboxExt
                name="milestone"
                label="Milestone only..."
                value={customSearchOptions.milestone}
                onChange={(v) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        milestone: v,
                    })
                }}
            />

            <AutocompleteExt
                name="translateTo"
                multiSelection={false}
                label="Translate to..."
                selectedValue={customSearchOptions.translateTo}
                onSelect={(value) => {
                    setCustomSearchOptions({
                        ...customSearchOptions,
                        translateTo: value,
                    })
                }}
                options={translationOptions}
            />
        </>
    )

    const columns = [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
        },
        {
            dataField: 'contentTypes',
            text: 'Content Type',
            sort: false,
            converter: (value: any) => {
                if (!value || !Array.isArray(value) || value.length === 0) {
                    return '';
                }

                const joinTypesRecursive = (item: any): string => {
                    if (!item || !item.type) {
                        return '';
                    }

                    if (item.parent && item.parent.type) {
                        return joinTypesRecursive(item.parent) + ' > ' + item.type;
                    } else {
                        return item.type;
                    }
                };

                let concatenatedTypes = joinTypesRecursive(value[value.length - 1]);

                // Capitalized all first letter
                concatenatedTypes = concatenatedTypes.replace(/\w\S*/g, (txt) => {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                })

                return concatenatedTypes;
            },
        },
        {
            dataField: 'milestone',
            text: 'Milestone',
            converter: (value: any) => {
                if (value) {
                    return 'Yes'
                }
                return 'No'
            },
        },
        {
            dataField: 'locale',
            text: 'Locale',
            sort: true,
        },
        {
            dataField: 'week',
            text: 'Week',
            sort: true,
        },
        {
            dataField: 'day',
            text: 'Day of Week',
        },
        {
            dataField: 'due',
            text: 'Due',
        },
        {
            dataField: 'weekTo',
            text: 'Week To',
            converter: (value: any) => {
                if (!value) {
                    return '-';
                }
                return value
            }
        },
        {
            dataField: 'dueTo',
            text: 'Due To',
            converter: (value: any) => {
                if (!value) {
                    return '-';
                }
                return value
            }
        },
        {
            dataField: 'priorityOrder',
            text: 'Priority',
            converter: (value: any) => {
                if (value === 1) {
                    return "Main"
                }
                return "Random"
            },
        },
        {
            dataField: 'state',
            text: 'State',
        },
    ]

    /**
     * Page containing content
     */
    return (
        <Box m="20px">
            <LoadingOverlay active={calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                <Header title="Content Assist" />
                <DataGridFilter
                    keyField="id"
                    useQueryKey={`contentAssists`}
                    change={change}
                    columns={columns}
                    onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                    reportTitle={'Content-Report'}
                    onDownloadEvent={onDownloadEvent}
                    searchFilterCols={3}
                    customSearchOptions={customSearchOptions}
                    resetCustomSearchOptions={setCustomSearchOptions}
                    customSearchOptionsRenderer={customSearchOptionsRenderer()}
                    disabledMatchAll={true}
                    expandRow={expandRow}
                    maxItemsPerPage={100000}
                    preloadEnabled={false}
                />
            </LoadingOverlay>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentAssists)
