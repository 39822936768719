import React, { useState } from 'react'
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { Box, IconButton, Typography, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { tokens } from '../../theme'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import AssistantIcon from '@mui/icons-material/Assistant'
import RecommendIcon from '@mui/icons-material/Recommend';
import HubIcon from '@mui/icons-material/Hub';
import FlagIcon from '@mui/icons-material/Flag'
import LanguageIcon from '@mui/icons-material/Language'
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import ClearIcon from '@mui/icons-material/Clear'
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import LinkIcon from '@mui/icons-material/Link';
import ScaleIcon from '@mui/icons-material/Scale';
import { connect } from 'react-redux'
import { Tenant } from '../../interfaces/TenantType'
import { useMutation } from 'react-query'
import { unsetCurrentTenant } from '../../actions/tenant'
import {AuthToken} from "../../actions/auth";
import {AuthUser} from "../../interfaces/UserType";
import {TenantSetting} from "../../interfaces/SettingType";
import {unsetCurrentUser} from "../../actions/user";

interface ItemProps {
    title: string
    to: string
    icon: React.ReactNode
    selected: string
    setSelected: (title: string) => void
}

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    return (
        <MenuItem
            active={selected === title}
            style={{ color: colors.grey[100] }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography>{title}</Typography>
            <Link to={to} />
        </MenuItem>
    )
}

const Sidebar = (props: { user: AuthToken; switchTenant: Tenant, switchUser: AuthUser, userSetting: TenantSetting }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Dashboard')
    const { user, switchTenant, switchUser, userSetting } = props

    /**
     * Mutate switch user
     */
    const switchUserMutation = useMutation<any, Error, any>(unsetCurrentUser)

    /**
     * Mutate unset tenant
     */
    const unsetTenantMutation = useMutation<any, Error, any>(unsetCurrentTenant)

    return (
        <Box
            sx={{
                '& .pro-sidebar': {
                    minHeight: '100vh'
                },
                '& .pro-sidebar-inner': {
                    background: `${colors.primary[400]} !important`,
                },
                '& .pro-icon-wrapper': {
                    backgroundColor: 'transparent !important',
                },
                '& .pro-inner-item': {
                    padding: '5px 35px 5px 20px !important',
                },
                '& .pro-inner-item:hover': {
                    color: '#868dfb !important',
                },
                '& .pro-menu-item.active': {
                    color: '#6870fa !important',
                },
            }}
        >
            <ProSidebar collapsed={isCollapsed}>
                <Menu iconShape="square">
                    {/* Logo and Menu Items */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: '10px 0 10px 0',
                            color: colors.grey[100],
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <img
                                    alt="Neurofrog"
                                    width="auto"
                                    height="auto"
                                    src={`/neurofrog.png`}
                                />
                                <Typography
                                    variant="h3"
                                    color={colors.grey[100]}
                                >
                                    Platform
                                </Typography>
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                    {/* User */}
                    {!isCollapsed && (
                        <Box mb="10px">
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    alt="profile-user"
                                    width="100px"
                                    height="100px"
                                    src={`/assets/user.png`} //ref: https://icons8.com/icon/z-JBA_KtSkxG/test-account
                                />
                            </Box>

                            <Box textAlign="center">
                                <Typography
                                    variant="h4"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: '10px 0 0 0' }}
                                >
                                    {user?.user?.username ? user?.user?.username : user?.user?.preferName}
                                </Typography>
                                {!switchUser?.id && switchTenant?.id && (
                                    <Typography
                                        variant="h5"
                                        title={`Tenant Id: ${switchTenant.id}`}
                                        color={colors.greenAccent[500]}
                                    >
                                        {switchTenant.name}
                                        <IconButton
                                            disabled={
                                                unsetTenantMutation.isLoading
                                            }
                                            onClick={() => {
                                                unsetTenantMutation.mutate({})
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Typography>
                                )}
                                {switchUser?.id && (
                                    <Typography
                                        variant="h5"
                                        title={`User Id: ${switchUser.id}`}
                                        color={colors.greenAccent[500]}
                                    >
                                        {switchUser.username ? switchUser.username : switchUser.email}
                                        <IconButton
                                            disabled={
                                                switchUserMutation.isLoading
                                            }
                                            onClick={() => {
                                                switchUserMutation.mutate({})
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Menu Items */}
                    <Box paddingLeft={isCollapsed ? undefined : '2%'}>
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.contentAssistPermission?.enabled) && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '5px 0 5px 20px' }}
                                >
                                    Content
                                </Typography>

                                <Item
                                    title="Assist"
                                    to="/content/assist"
                                    icon={<AssistantIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                                <Item
                                    title="Assist (Graph)"
                                    to="/content/assist/graph"
                                    icon={<HubIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.contentAssistRecommendationPermission?.enabled) && (
                                    <>
                                        <Item
                                            title="Recommendation"
                                            to="/content/assist/recommendation"
                                            icon={<RecommendIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Recommendation (Graph)"
                                            to="/content/assist/recommendation/graph"
                                            icon={<HubIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Interest Mapping"
                                            to="/content/tenant/interest/mapping"
                                            icon={<LinkIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                        <Item
                                            title="Milestone Skill Mapping"
                                            to="/content/tenant/milestone/skill/mapping"
                                            icon={<LinkIcon />}
                                            selected={selected}
                                            setSelected={setSelected}
                                        />
                                    </>
                                )}

                                <Item
                                    title="Default Reference Mappings"
                                    to="/default/content/reference/mapping"
                                    icon={<LinkIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                {/*<Item*/}
                                {/*    title="Public API"*/}
                                {/*    to="/content/assist/public/api"*/}
                                {/*    icon={<PublicIcon />}*/}
                                {/*    selected={selected}*/}
                                {/*    setSelected={setSelected}*/}
                                {/*/>*/}
                            </>
                        )}

                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '5px 0 5px 20px' }}
                        >
                            Calculation
                        </Typography>

                        <Item
                            title="Unit of Measure"
                            to="/uom"
                            icon={<ScaleIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.tenantPermission?.enabled || userSetting?.administratorRolePermission?.superAdmin) && (
                            <Typography
                                variant="h6"
                                color={colors.grey[300]}
                                sx={{ m: '5px 0 5px 20px' }}
                            >
                                Client
                            </Typography>
                        )}

                        {userSetting?.administratorRolePermission?.superAdmin && (
                            <Item
                                title="User"
                                to="/user"
                                icon={<GroupsIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.tenantPermission?.enabled) && (
                            <Item
                                title="Tenant"
                                to="/tenant"
                                icon={<GroupsIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.serverConfigPermission?.enabled || userSetting?.administratorRolePermission?.localizationPermission?.enabled) && (
                            <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '5px 0 5px 20px' }}
                            >
                                Config
                            </Typography>
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.serverConfigPermission?.enabled) && (
                            <Item
                                title="Server"
                                to="/server"
                                icon={<PermDataSettingIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.localizationPermission?.enabled) && (
                            <>
                                <Item
                                    title="Locale"
                                    to="/locale"
                                    icon={<LanguageIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Aws Locale Mapping"
                                    to="/aws/locale/mapping"
                                    icon={<LanguageIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Google Locale Mapping"
                                    to="/google/locale/mapping"
                                    icon={<LanguageIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />

                                <Item
                                    title="Country"
                                    to="/country"
                                    icon={<FlagIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.userSettingPermission?.enabled) && (
                            <>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[300]}
                                    sx={{ m: '15px 0 5px 20px' }}
                                >
                                    Setting
                                </Typography>

                                <Item
                                    title={`${switchTenant ? 'Tenant' : 'User'}`}
                                    to="/setting/tenant"
                                    icon={<SettingsAccessibilityIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </>
                        )}

                        {(userSetting?.administratorRolePermission?.superAdmin || userSetting?.administratorRolePermission?.accessControlPermission?.enabled) && (
                          <>
                              <Typography
                                  variant="h6"
                                  color={colors.grey[300]}
                                  sx={{ m: '15px 0 5px 20px' }}
                              >
                                  Security
                              </Typography>

                              <Item
                                  title={`Access Control`}
                                  to="/security/access"
                                  icon={<SecurityIcon />}
                                  selected={selected}
                                  setSelected={setSelected}
                              />
                          </>
                        )}
                    </Box>
                </Menu>
            </ProSidebar>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant, switchUser: state.user.switchUser, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(Sidebar)
