import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    AwsLocaleMapping, AwsLocaleMappingWrapper,
    Locale,
} from '../../../../../../../interfaces/LocalizationType'
import {
    updateAwsLocaleMapping,
} from '../../../../../../../actions/localization'
import { ApiError } from '../../../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../../../components/ErrorMessage'
import ButtonExt from '../../../../../../../components/ButtonExt'
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import {connect} from "react-redux";

const awsLocaleMappingSchema = yup.object().shape({
    localeId: yup.string().required('required'),
    languageCode: yup.string().min(2).max(10).required('required'),
})

const AwsLocaleMappingDetail: FunctionComponent<AwsLocaleMappingWrapper> = ({
                                                                                localeList,
                                                                                userSetting,
                                                                                isNew = false,
                                                                                wrapper,
}) => {
    const [awsLocaleMapping, setAwsLocaleMapping] = useState<AwsLocaleMapping>({
        ...wrapper!!,
        localeId: wrapper!!.locale.id!!,
    })

    const localeOptions = localeList?.map((locale: Locale) => {
        return {
            label: locale.name,
            value: locale.id,
        }
    })

    /**
     * Mutate locale mapping update
     */
    const awsLocaleMappingUpdateMutation = useMutation<AwsLocaleMapping, ApiError, AwsLocaleMapping>(
        updateAwsLocaleMapping,
        {
            onSuccess: (data) => {
                setAwsLocaleMapping(data)
            },
        }
    )

    /**
     * Invoke an action to update locale
     * @param {*} e - event
     */
    const onSave = (values: AwsLocaleMapping) => {
        awsLocaleMappingUpdateMutation.mutate(values)
    }

    /**
     * Page containing locale mapping details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {awsLocaleMappingUpdateMutation.isError && (
                    <ErrorMessage error={awsLocaleMappingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={awsLocaleMapping}
                validationSchema={awsLocaleMappingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {awsLocaleMapping.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <AutocompleteExt
                                name="localeId"
                                multiSelection={false}
                                label="Locale Id"
                                selectedValue={values.locale.id}
                                options={localeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setAwsLocaleMapping({
                                        ...awsLocaleMapping,
                                        localeId: v,
                                    })
                                    values.localeId = v
                                }}
                                required={true}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Language Code Mapping"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.languageCode}
                                name="languageCode"
                                error={!!touched.languageCode && !!errors.languageCode}
                                helperText={touched.languageCode && errors.languageCode}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    awsLocaleMappingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    awsLocaleMappingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.localizationPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}


/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(AwsLocaleMappingDetail)
