import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { connect } from 'react-redux'
import { Box, IconButton, TextField } from '@mui/material'
import Header from '../../../../../components/Header'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
    Country,
    CountryWrapper,
    Locale,
} from '../../../../../interfaces/LocalizationType'
import {
    createCountry,
    updateCountry,
} from '../../../../../actions/localization'
import AutocompleteExt from '../../../../../components/Autocomplete'
import ButtonExt from '../../../../../components/ButtonExt'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../components/ErrorMessage'

const countrySchema = yup.object().shape({
    code: yup.string().required('required'),
    name: yup.string().required('required'),
    locales: yup.array<Locale>().min(1).optional(),
})

const CountryDetail: FunctionComponent<CountryWrapper> = ({
    localeList,
    userSetting,
    isNew = false,
    wrapper,
}) => {
    const navigate = useNavigate()
    const [country, setCountry] = useState<Country>(
        wrapper
            ? wrapper
            : {
                  id: undefined,
                  code: '',
                  name: '',
                  locales: [],
              }
    )

    const localeOptions = localeList?.map((locale: Locale) => {
        return {
            label: locale.name,
            value: locale.id,
        }
    })

    /**
     * Mutate country create
     */
    const countryCreateMutation = useMutation<Country, ApiError, Country>(
        createCountry,
        {
            onSuccess: (data) => {
                setCountry(data)
            },
        }
    )

    /**
     * Mutate country update
     */
    const countryUpdateMutation = useMutation<Country, ApiError, Country>(
        updateCountry,
        {
            onSuccess: (data) => {
                setCountry(data)
            },
        }
    )

    if (countryCreateMutation.isSuccess) {
        navigate(`/country`)
    }

    /**
     * Invoke an action to create/ update country
     * @param {*} e - event
     */
    const onSave = (values: Country) => {
        if (!values.id) {
            countryCreateMutation.mutate(values)
        } else {
            countryUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing country details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/country`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Country" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {countryCreateMutation.isError && (
                    <ErrorMessage error={countryCreateMutation.error} />
                )}

                {countryUpdateMutation.isError && (
                    <ErrorMessage error={countryUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={country}
                validationSchema={countrySchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {country.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.code}
                                name="code"
                                error={!!touched.code && !!errors.code}
                                helperText={touched.code && errors.code}
                                disabled={values.id != null}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                            />

                            <AutocompleteExt
                                name="locales"
                                multiSelection={true}
                                label="Locales"
                                selectedValue={values.locales.map(
                                    (locale) => locale.id
                                )}
                                options={localeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (localeList && localeList.length > 0) {
                                        const selectedValue = localeList.filter(
                                            (locale) => v.includes(locale.id)
                                        )
                                        setCountry({
                                            ...country,
                                            locales: localeList.filter(
                                                (locale) =>
                                                    v.includes(locale.id)
                                            ),
                                        })
                                        values.locales = selectedValue
                                    }
                                }}
                                required={true}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    countryCreateMutation.isLoading ||
                                    countryUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    countryCreateMutation.isLoading ||
                                    countryUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.localizationPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(CountryDetail)
