import { Box, FormHelperText, TextareaAutosize } from '@mui/material'
import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { InputLabel } from '@mui/material'

const TextareaAutosizeExt: FunctionComponent<TextareaAutosizeProp> = ({
    style,
    name,
    value,
    label,
    maxLength,
    required = false,
    editable = true,
    resizeable = false,
    minRows = 1,
    maxRows = 1,
    onBlurEvent,
    onChange,
    onChangeEvent,
    error,
    helperText,
}) => {
    const uuid = useMemo(() => uuidv4(), [])

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = async (event: any) => {
        if (onChange) {
            onChange(event.target.value)
        }

        if (onChangeEvent) {
            onChangeEvent(event)
        }
    }

    return (
        <FormHelperText style={style} onBlur={onInternalBlurEvent} error={error}>
            <Box display="grid">
                <InputLabel
                    style={{
                        fontSize: `x-small`,
                        paddingLeft: `15px`,
                        paddingBottom: `5px`,
                    }}
                >
                    {label}
                </InputLabel>
                <TextareaAutosize
                    key={uuid}
                    name={name}
                    value={value}
                    onBlur={onInternalBlurEvent}
                    onChange={onInternalChangeEvent}
                    minRows={minRows}
                    maxRows={maxRows}
                    style={{
                        resize: `${!resizeable ? 'none' : 'both'}`,
                        width: `auto`,
                    }}
                    required={required}
                    maxLength={maxLength}
                    disabled={!editable}
                />

                {helperText}
            </Box>
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface TextareaAutosizeProp {
    style?: React.CSSProperties
    name: string
    label: string
    value: string | undefined
    onChange?: (v: any) => void // Handle event upon onclick
    onChangeEvent?: (event: any) => void // Handle event upon onclick
    onBlurEvent?: (event: any) => void // Handle event upon onblur
    required?: boolean
    maxLength?: number | undefined
    resizeable?: boolean
    minRows?: number | undefined
    maxRows?: number | undefined
    editable?: boolean
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default TextareaAutosizeExt
