import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const SelectExt: FunctionComponent<SelectProp> = ({
    name,
    label,
    options,
    selectedValue,
    multiSelection = false,
    isClearable = true,
    required = false,
    editable = true,
    onBlurEvent,
    onSelect,
    onSelectEvent,
    error,
    helperText,
}) => {
    const uuid = useMemo(() => uuidv4(), [])

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalSelectEvent = async (event: any) => {
        if (onSelect) {
            onSelect(event.target.value)
        }

        if (onSelectEvent) {
            onSelectEvent(event)
        }
    }

    const onInternalClearEvent = async () => {
        if (onSelect) {
            onSelect(multiSelection ? [] : null)
        }
    }

    return (
        <FormHelperText onBlur={onInternalBlurEvent} error={error}>
            {options && options.length > 0 && (
                <FormControl sx={{
                    "& .MuiFilledInput-input": {
                        paddingRight: `0 !important`,
                        paddingTop: `22px !important`,
                    }
                }} fullWidth={true}>
                    {label && (
                        <InputLabel
                            style={{
                                marginTop: `${
                                    (multiSelection &&
                                        selectedValue?.length > 0) ||
                                    (!multiSelection && selectedValue)
                                        ? `1em`
                                        : `0`
                                }`,
                            }}
                        >
                            {label}
                        </InputLabel>
                    )}
                    {isClearable && (
                        <Select
                            variant="filled"
                            fullWidth={true}
                            key={uuid}
                            name={name}
                            label={label}
                            multiple={multiSelection}
                            value={selectedValue}
                            onBlur={onInternalBlurEvent}
                            onChange={onInternalSelectEvent}
                            disabled={!editable}
                            required={required}
                            endAdornment={
                                <IconButton
                                    sx={{
                                        visibility: (
                                            multiSelection
                                                ? selectedValue?.length > 0
                                                : selectedValue
                                        )
                                            ? 'visible'
                                            : 'hidden',
                                    }}
                                    onClick={() => onInternalClearEvent()}
                                >
                                    <ClearIcon />
                                </IconButton>
                            }
                        >
                            {options?.map((option) => {
                                return (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    )}

                    {!isClearable && (
                        <Select
                            variant="filled"
                            fullWidth={true}
                            key={uuid}
                            name={name}
                            label={label}
                            multiple={multiSelection}
                            value={selectedValue}
                            onBlur={onInternalBlurEvent}
                            onChange={onInternalSelectEvent}
                            disabled={!editable}
                            required={required}
                        >
                            {options?.map((option) => {
                                return (
                                    <MenuItem value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    )}
                </FormControl>
            )}

            {(!options || options.length === 0) && (
                <TextField
                    variant="filled"
                    type="text"
                    label={label}
                    value="Not Available"
                    name={name}
                    disabled={true}
                />
            )}

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface SelectProp {
    name: string | undefined
    label?: string | undefined
    options: any[] | undefined
    selectedValue?: any | any[]
    multiSelection?: boolean
    isClearable?: boolean
    required?: boolean
    editable?: boolean
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onSelect?: (v: any) => void // Handle event upon onSelect
    onSelectEvent?: (event: any) => void // Handle event upon onSelect
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default SelectExt
