import {Box, TextField} from '@mui/material'
import React, {FunctionComponent, useState} from 'react'
import RichTextEditor from '../../../../components/RichTextEditor'
import AutocompleteExt from '../../../../components/Autocomplete'
import {ContentAssist, ContentAssistWrapper} from '../../../../interfaces/ContentAssistType'
import PlayerExt from "../../../../components/Player";
import SwitchExt from "../../../../components/Switch";
import AccordionExt from "../../../../components/AccordionExt";
import TextareaAutosizeExt from "../../../../components/TextareaAutosize";
import ExpandableImage from "../../../../components/ExpandableImage";
import CheckboxExt from "../../../../components/Checkbox";

const ContentAssistDetail: FunctionComponent<ContentAssistWrapper> = ({
                                                                                  wrapper,
                                                                                  callback
                                                                              }) => {

    const [content] = useState<ContentAssist>(wrapper!!)

    return (
        <Box>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0,1fr))"
                sx={{
                    '& > div': { gridColumn: ' span 2' },
                }}
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={content.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Week"
                    value={content.week}
                    name="week"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Day of Week"
                    value={content.day}
                    name="day"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Due"
                    value={content.due}
                    name="due"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Week To"
                    value={content.weekTo ? content.weekTo : '-'}
                    name="weekTo"
                />

                <TextField
                    variant="filled"
                    type="number"
                    label="Due To"
                    value={content.dueTo ? content.dueTo : '-'}
                    name="dueTo"
                />

                <SwitchExt
                    name="priorityOrder"
                    label="Main"
                    value={content.priorityOrder === 1}
                />

                <CheckboxExt
                    name="milestone"
                    label="Milestone"
                    value={content.milestone}
                />
            </Box>

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0,1fr))"
                sx={{
                    '& > div': { gridColumn: ' span 1' },
                }}
                style={{ paddingTop: `30px`, paddingBottom: `30px` }}
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Title"
                    value={content.title}
                    name="title"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="SubTitle"
                    value={content.subTitle}
                    name="title"
                />

                {(!content.references || content.references.length === 0) && (
                    <TextField
                        variant="filled"
                        type="text"
                        label="Reference"
                        value={"Not Available"}
                        name="references"
                    />
                )}

                {content.references && content.references.map((reference, index) => {
                    return (
                        <AccordionExt
                            name={`reference-${index}`}
                            title={`Reference: ${reference.text}`}
                            component={
                                <Box
                                    display="grid"
                                    gap="30px"
                                    gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                >
                                    {reference.urls.map((url, index) => {
                                        return (
                                            <TextField
                                                key={index}
                                                variant="filled"
                                                type="text"
                                                label='Reference Url'
                                                value={url}
                                                name="references.url"
                                            />
                                        )
                                    })}
                                </Box>
                            }
                        />
                    )
                })}

                <RichTextEditor
                    value={content.body}
                    editable={false}
                />

                {content.domains?.length > 0 && (
                    <AccordionExt
                        name="domains"
                        title="Domains"
                        component={
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(2, minmax(0,1fr))"
                            >
                                {content.domains.map((domain, index) => {
                                    return (
                                        <>
                                            <TextField
                                                key={`domain-${index}`}
                                                variant="filled"
                                                type="text"
                                                label='Domain'
                                                value={domain.key}
                                                name="domains"
                                            />
                                            <TextField
                                                key={`domain-value-${index}`}
                                                variant="filled"
                                                type="text"
                                                label='Attribute'
                                                value={domain.value}
                                                name="domains"
                                            />
                                        </>
                                    )
                                })}
                            </Box>
                        }
                    />
                )}

                {content.milestone && content.milestoneData && (
                    <AccordionExt
                        name="milestone"
                        title="Milestone"
                        component={
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(1, minmax(0,1fr))"
                            >
                                {content.milestoneData!!.skillCategories.map((skillCategory, index) => {
                                    return (
                                        <AccordionExt
                                            key={index}
                                            name={`skillCategory-${index}`}
                                            title={`Skill Category: ${skillCategory.name}`}
                                            component={
                                                <Box
                                                    display="grid"
                                                    gap="30px"
                                                    gridTemplateColumns="repeat(2, minmax(0,1fr))"
                                                >
                                                    {skillCategory.skills.map((skill, index) => {
                                                        return (
                                                            <TextField
                                                                key={`skill-${index}`}
                                                                variant="filled"
                                                                type="text"
                                                                label='Skill'
                                                                value={skill}
                                                                name="milestoneData.skillCategories.skills"
                                                            />
                                                        )
                                                    })}
                                                </Box>
                                            }
                                        />
                                    )
                                })}

                                {content.milestoneData!!.milestoneChecklist.map((checklist, index) => {
                                    return (
                                        <TextField
                                            key={index}
                                            variant="filled"
                                            type="text"
                                            label='Checklist'
                                            value={checklist}
                                            name="milestoneData.milestoneChecklist"
                                        />
                                    )
                                })}
                            </Box>
                        }
                    />
                )}
            </Box>

            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <AutocompleteExt
                    name="contentTypes"
                    multiSelection={true}
                    label="Content Types"
                    selectedValue={content.contentTypes.map((type) => type.id)}
                    options={content.contentTypes.map((type) => {
                        return {
                            label: type.type,
                            value: type.id,
                        }
                    })}
                    disableUnselectAll={true}
                />

                <AutocompleteExt
                    name="relationships"
                    multiSelection={true}
                    label="Relationships"
                    selectedValue={content.relationships?.map((relationship) => relationship.id)}
                    options={content.relationships?.map((relationship) => {
                        return {
                            label: relationship.name,
                            value: relationship.id,
                        }
                    })}
                    disableUnselectAll={true}
                />

                <ExpandableImage
                    style={{ objectFit: 'contain', width: 300 }}
                    url={content.media?.url}
                    label="Media"
                    name="media"
                    className='me-3'
                />

                <TextareaAutosizeExt
                    name="mediaPromptUsed"
                    label="Media Prompt Used"
                    value={content.mediaPromptUsed ? content.mediaPromptUsed : '-'}
                    minRows={8}
                    maxRows={8}
                />

                <ExpandableImage
                    style={{ objectFit: 'contain', width: 300 }}
                    url={content.banner?.url}
                    label="Banner"
                    name="Banner"
                    className='me-3'
                />

                <TextareaAutosizeExt
                    name="bannerPromptUsed"
                    label="Banner Prompt Used"
                    value={content.bannerPromptUsed ? content.bannerPromptUsed : '-'}
                    minRows={8}
                    maxRows={8}
                />

                {content.video?.url && (
                    <>
                        <PlayerExt label="Video" url={content.video.url} />

                        <TextareaAutosizeExt
                            name="videoPromptUsed"
                            label="Video Prompt Used"
                            value={content.videoPromptUsed ? content.videoPromptUsed : '-'}
                            minRows={8}
                            maxRows={8}
                        />
                    </>
                )}

                {content.audio?.url && (
                    <>
                        <PlayerExt label="Audio" url={content.audio.url} />

                        <TextareaAutosizeExt
                            name="audioPromptUsed"
                            label="Audio Prompt Used"
                            value={content.audioPromptUsed ? content.audioPromptUsed : '-'}
                            minRows={8}
                            maxRows={8}
                        />
                    </>
                )}

                <TextField
                    variant="filled"
                    type="text"
                    label="Last Update"
                    value={content.createdAt}
                    name="createdAt"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="State"
                    value={content.state}
                    name="state"
                />
            </Box>
        </Box>
    )
}

export default ContentAssistDetail
