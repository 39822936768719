import React, {FunctionComponent} from "react";
import {
    ParentAssistApiKeyIntegration,
    ParentAssistTenantSetting
} from "../../../../../../../interfaces/SettingType";
import {Box} from "@mui/material";
import PasswordInput from "../../../../../../../components/PasswordInput";

const AuthKeySettingDetail: FunctionComponent<AuthKeySettingProps> = ({
                                                                        values,
                                                                        parentAssistTenantSetting,
                                                                        setParentAssistTenantSetting,
                                                                        handleBlur,
                                                                        handleChange
                                                                    }) => {
    return (
        <>
            <Box
                display="grid"
                gap="30px"
                mb="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <PasswordInput
                    name="parentAssistMapping.authIntegrations.key"
                    label="Key"
                    onBlurEvent={handleBlur}
                    onChangeEvent={handleChange}
                    value={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistApiKeyIntegration).key
                    }
                    required={true}
                />
            </Box>
        </>
    );
}

export interface AuthKeySettingProps {
    values: ParentAssistTenantSetting;
    parentAssistTenantSetting: ParentAssistTenantSetting;
    setParentAssistTenantSetting: (value: ParentAssistTenantSetting) => void;
    handleBlur: (event: any) => void;
    handleChange: (event: any) => void;
}

export default AuthKeySettingDetail;