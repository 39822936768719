import {KeyPair, ParentAssistOAuth2Integration} from "../interfaces/SettingType";

export const isEmptyObject = (obj: any) => {
    if (obj === undefined) {
        return true;
    }

    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const toKeyPairs = (obj: Object): KeyPair[] => {
    if (obj === undefined) {
        return [];
    }

    if (obj instanceof Map) {
        return Array.from(obj).map(([key, value]) => ({ key, value }));
    }

    return Object.entries(obj).map(([key, value]) => ({ key, value }));
}

export const toMap = (keyPairs: KeyPair[]): Map<string, string> => {
    return new Map(keyPairs.map(({ key, value }) => [key, value]));
}

export const toEntries = (obj: Object): any => {
    if (obj === undefined) {
        return new Object();
    }

    if (obj instanceof Map) {
        return Object.fromEntries(obj);
    }

    if (obj instanceof Object) {
        return obj;
    }
}