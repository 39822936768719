import SecureAxios from "../extensions/SecureAxios";

/**
 * An action to fetch development stages from neuro api
 */
export const fetchDevelopmentStages = async () => {
    const {data} = await SecureAxios.get(`/development/stage?keyword=&page=1&size=100&sort=weekFrom&sortDir=ASC`)
    return data.data.content
}

/**
 * Calculate week range by month and selected development stage
 *
 * @param param
 * @returns {*}
 */
export const calculateWeekRangeByMonthAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/month/to/week/range?month=${param.month}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate due by week and selected development stage
 */
export const calculateDueByWeekAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/week/to/due?week=${param.week}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate month by week and selected development stage
 */
export const calculateMonthByWeekAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/week/to/month?week=${param.week}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate due by month and selected development stage
 */
export const calculateDueByMonthAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/month/to/due?month=${param.month}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate month by due and selected development stage
 */
export const calculateMonthByDueAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/due/to/month?due=${param.due}&developmentStageId=${param.developmentStageId}`)
    return data.data
}

/**
 * Calculate week by due and selected development stage
 */
export const calculateWeekByDueAndSelectedDevelopmentStage = async (param) => {
    const {data} = await SecureAxios.get(`/development/stage/convert/due/to/week?due=${param.due}&developmentStageId=${param.developmentStageId}`)
    return data.data
}