import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import Header from '../../../../components/Header'
import { Box } from '@mui/material'
import { Locale } from '../../../../interfaces/LocalizationType'
import { fetchLocales } from '../../../../actions/localization'
import LocaleDetail from './detail'
import {connect} from "react-redux";
import {TenantSetting} from "../../../../interfaces/SettingType";

/**
 * Locale list
 *
 * @returns locale list page
 */
function Locales(props: {userSetting: TenantSetting}) {

    const {userSetting} = props

    const expandRow = (row: Locale) => (
        <LocaleDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchLocales(searchOptions)
    }

    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
    ]

    /**
     * Render the countries through Table component
     */
    return (
        <Box m="20px">
            <Header title="Locales" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`locales`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/locale/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.localizationPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(Locales)
