export const stateOptions = [
    {
        label: 'Draft',
        value: 'DRAFT',
    },
    {
        label: 'Publish',
        value: 'PUBLISH',
    },
]
