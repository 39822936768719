import React, {FunctionComponent, useState} from 'react'
import { useMutation } from 'react-query'
import {Box, TextField} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    ContentAssistTenantMappingWrapper,
    ContentAssistTenantSetting, DynamicMongoDatabaseMapping,
    KeyPair,
    TenantSetting,
} from '../../../../../interfaces/SettingType'
import {
    supportedMediaTypeOptions,
} from '../../../../../share/ContentAssistConstant'
import { updateUserSetting } from '../../../../../actions/user'
import AutocompleteExt from '../../../../../components/Autocomplete'
import CheckboxExt from '../../../../../components/Checkbox'
import ErrorMessage from '../../../../../components/ErrorMessage'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ButtonExt from '../../../../../components/ButtonExt'
import {connect} from "react-redux";
import {Locale} from "../../../../../interfaces/LocalizationType";
import AccordionExt from "../../../../../components/AccordionExt";

const contentAssistTenantSettingSchema = yup.object().shape({
    contentAssistMapping: yup.object().shape({
        enabled: yup.boolean().required(),
        mediaEnabled: yup.boolean().required(),
        recommendationEnabled: yup.boolean().required(),
        localizations: yup
            .array<KeyPair>()
            .of(
                yup.object().shape({
                    key: yup.string().required(),
                })
            )
            .optional(),
    }),
})

const ContentAssistTenantSettingDetail: FunctionComponent<
    ContentAssistTenantMappingWrapper
> = ({ localeList, userSetting, wrapper, callback }) => {
    const [contentAssistTenantSetting, setContentAssistTenantSetting] =
        useState<ContentAssistTenantSetting>(
            wrapper
                ? {
                    ...wrapper,
                    contentAssistMapping: {
                        ...wrapper.contentAssistMapping!!,
                        recommendationEnabled: wrapper.contentAssistMapping!!.recommendationEnabled ?? true,
                    }
                }
                : {
                      contentAssistMapping: {
                          enabled: true,
                          mediaEnabled: true,
                          recommendationEnabled: true,
                          supportedMediaTypes: supportedMediaTypeOptions.map(supportedMediaTypeOptions => supportedMediaTypeOptions.value),
                          localizations: [],
                      },
                  }
        )

    const defaultDynamicMongoDatabaseMapping: DynamicMongoDatabaseMapping = {
        host: '',
        username: 'mongodb',
        password: '',
        database: '',
        autoIndexCreation: true,
        retryWrites: true,
        retryReads: true,
        connectionPoolSettingsMaxSize: 3,
        connectionPoolSettingsMinSize: 1,
        connectionPoolSettingsMaxConnectionLifeTimeInSeconds: 0,
        connectionPoolSettingsMaxConnectionIdleTimeInSeconds: 0,
        connectionPoolSettingsMaxWaitTimeInSeconds: 60,
        socketSettingsReadTimeoutInSeconds: 10,
        socketSettingsConnectTimeoutInSeconds: 60,
    }

    const [dynamicMongoDatabaseMappingEnabled, setDynamicMongoDatabaseMappingEnabled] = useState<boolean>(contentAssistTenantSetting.contentAssistMapping?.dynamicMongoDatabaseMapping !== undefined)

    const localeOptions = localeList?.map((locale: Locale) => {
        return {
            label: locale.name,
            value: locale.id,
        }
    })

    /**
     * Mutate content assist tenant setting update
     */
    const contentAssistTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        ContentAssistTenantSetting
    >(updateUserSetting, {
        onSuccess: (data) => {
            callback(data)
        },
    })

    /**
     * Invoke an action to update content assist tenant setting
     * @param values Content assist tenant setting
     */
    const onSave = (values: ContentAssistTenantSetting) => {
        contentAssistTenantSettingUpdateMutation.mutate(values)
    }

    /**
     * Page containing default tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {contentAssistTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={contentAssistTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={contentAssistTenantSetting}
                validationSchema={contentAssistTenantSettingSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            <CheckboxExt
                                name="contentAssistMapping.enabled"
                                label="Enabled"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={
                                    values.contentAssistMapping?.enabled
                                }
                            />

                            <CheckboxExt
                                name="contentAssistMapping.mediaEnabled"
                                label="Media Enabled"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={
                                    values.contentAssistMapping?.mediaEnabled
                                }
                            />

                            <CheckboxExt
                                name="contentAssistMapping.recommendationEnabled"
                                label="Recommendation Enabled"
                                onBlurEvent={handleBlur}
                                onChangeEvent={handleChange}
                                value={
                                    values.contentAssistMapping?.recommendationEnabled
                                }
                            />

                            <AutocompleteExt
                                name="contentAssistMapping.localizations"
                                multiSelection={true}
                                label="Localizations"
                                selectedValue={values.contentAssistMapping?.localizations.map(
                                    (locale) => locale.key
                                )}
                                options={localeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (
                                        localeList &&
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        const selectedLocalizations = v.map(
                                            (each: any) => {
                                                return {
                                                    key: each,
                                                }
                                            }
                                        )

                                        setContentAssistTenantSetting({
                                            ...contentAssistTenantSetting.contentAssistMapping,
                                            contentAssistMapping: {
                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                localizations: selectedLocalizations,
                                            }
                                        })

                                        values.contentAssistMapping = {
                                            ...values.contentAssistMapping,
                                            localizations: selectedLocalizations,
                                        }
                                    }
                                }}
                            />

                            {values.contentAssistMapping?.mediaEnabled && (
                                <AutocompleteExt
                                    name="contentAssistMapping.supportedMediaTypes"
                                    multiSelection={true}
                                    label="Supported Media Types"
                                    selectedValue={values.contentAssistMapping?.supportedMediaTypes}
                                    options={supportedMediaTypeOptions}
                                    onBlurEvent={handleBlur}
                                    onSelect={(v) => {
                                        if (
                                            values.contentAssistMapping &&
                                            contentAssistTenantSetting.contentAssistMapping
                                        ) {
                                            setContentAssistTenantSetting({
                                                ...contentAssistTenantSetting,
                                                contentAssistMapping: {
                                                    ...contentAssistTenantSetting.contentAssistMapping,
                                                    supportedMediaTypes: v,
                                                },
                                            })
                                            values.contentAssistMapping = {
                                                ...values.contentAssistMapping,
                                                supportedMediaTypes: v,
                                            }
                                        }
                                    }}
                                />
                            )}

                            <CheckboxExt
                                name="dynamicMongoDatabaseMappingEnabled"
                                value={dynamicMongoDatabaseMappingEnabled}
                                label="Connect to External Mongo Database?"
                                onChangeEvent={(e) => {
                                    if (
                                        values.contentAssistMapping &&
                                        contentAssistTenantSetting.contentAssistMapping
                                    ) {
                                        setDynamicMongoDatabaseMappingEnabled(e.target.checked)
                                        if (e.target.checked) {
                                            setContentAssistTenantSetting({
                                                ...contentAssistTenantSetting,
                                                contentAssistMapping: {
                                                    ...contentAssistTenantSetting.contentAssistMapping,
                                                    dynamicMongoDatabaseMapping: defaultDynamicMongoDatabaseMapping
                                                }
                                            })
                                            values.contentAssistMapping = {
                                                ...values.contentAssistMapping,
                                                dynamicMongoDatabaseMapping: defaultDynamicMongoDatabaseMapping
                                            }
                                        } else {
                                            setContentAssistTenantSetting({
                                                ...contentAssistTenantSetting,
                                                contentAssistMapping: {
                                                    ...contentAssistTenantSetting.contentAssistMapping,
                                                    dynamicMongoDatabaseMapping: undefined
                                                }
                                            })
                                            values.contentAssistMapping = {
                                                ...values.contentAssistMapping,
                                                dynamicMongoDatabaseMapping: undefined
                                            }
                                        }
                                    }
                                }}
                            />
                        </Box>

                        {dynamicMongoDatabaseMappingEnabled && (
                            <Box
                                gap="30px"
                            >
                                <AccordionExt name="dynamicMongoDatabaseMapping" title="External Mongo Database Configuration" component={
                                    <>
                                        <Box
                                            display="grid"
                                            gap="30px"
                                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                                        >
                                            <TextField
                                                variant="filled"
                                                type="text"
                                                label="Host"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.host
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.host"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="text"
                                                label="Username"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.username
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.username"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="password"
                                                label="Password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.password
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.password"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="text"
                                                label="Database Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.database
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.database"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <CheckboxExt
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.autoIndexCreation"
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.autoIndexCreation
                                                }
                                                label="Auto Index Creation"
                                                onChangeEvent={(e) => {
                                                    if (
                                                        values.contentAssistMapping &&
                                                        contentAssistTenantSetting.contentAssistMapping &&
                                                        values.contentAssistMapping.dynamicMongoDatabaseMapping
                                                    ) {
                                                        setContentAssistTenantSetting({
                                                            ...contentAssistTenantSetting,
                                                            contentAssistMapping: {
                                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                                dynamicMongoDatabaseMapping: {
                                                                    ...contentAssistTenantSetting.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                    autoIndexCreation: e.target.checked
                                                                }
                                                            }
                                                        })
                                                        values.contentAssistMapping = {
                                                            ...values.contentAssistMapping,
                                                            dynamicMongoDatabaseMapping: {
                                                                ...values.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                autoIndexCreation: e.target.checked
                                                            }
                                                        }
                                                    }
                                                }}
                                            />

                                            <CheckboxExt
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.retryWrites"
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.retryWrites
                                                }
                                                label="Retry Writes"
                                                onChangeEvent={(e) => {
                                                    if (
                                                        values.contentAssistMapping &&
                                                        contentAssistTenantSetting.contentAssistMapping &&
                                                        values.contentAssistMapping.dynamicMongoDatabaseMapping
                                                    ) {
                                                        setContentAssistTenantSetting({
                                                            ...contentAssistTenantSetting,
                                                            contentAssistMapping: {
                                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                                dynamicMongoDatabaseMapping: {
                                                                    ...contentAssistTenantSetting.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                    retryWrites: e.target.checked
                                                                }
                                                            }
                                                        })
                                                        values.contentAssistMapping = {
                                                            ...values.contentAssistMapping,
                                                            dynamicMongoDatabaseMapping: {
                                                                ...values.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                retryWrites: e.target.checked
                                                            }
                                                        }
                                                    }
                                                }}
                                            />

                                            <CheckboxExt
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.retryReads"
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.retryReads
                                                }
                                                label="Retry Reads"
                                                onChangeEvent={(e) => {
                                                    if (
                                                        values.contentAssistMapping &&
                                                        contentAssistTenantSetting.contentAssistMapping &&
                                                        values.contentAssistMapping.dynamicMongoDatabaseMapping
                                                    ) {
                                                        setContentAssistTenantSetting({
                                                            ...contentAssistTenantSetting,
                                                            contentAssistMapping: {
                                                                ...contentAssistTenantSetting.contentAssistMapping,
                                                                dynamicMongoDatabaseMapping: {
                                                                    ...contentAssistTenantSetting.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                    retryReads: e.target.checked
                                                                }
                                                            }
                                                        })
                                                        values.contentAssistMapping = {
                                                            ...values.contentAssistMapping,
                                                            dynamicMongoDatabaseMapping: {
                                                                ...values.contentAssistMapping.dynamicMongoDatabaseMapping!!,
                                                                retryReads: e.target.checked
                                                            }
                                                        }
                                                    }
                                                }}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Connection Pool Settings Max Size"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.connectionPoolSettingsMaxSize
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.connectionPoolSettingsMaxSize"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Connection Pool Settings Min Size"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.connectionPoolSettingsMinSize
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.connectionPoolSettingsMinSize"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Connection Pool Settings Max Connection Life Time In Seconds"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.connectionPoolSettingsMaxConnectionLifeTimeInSeconds
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.connectionPoolSettingsMaxConnectionLifeTimeInSeconds"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Connection Pool Settings Max Connection Idle Time In Seconds"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.connectionPoolSettingsMaxConnectionIdleTimeInSeconds
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.connectionPoolSettingsMaxConnectionIdleTimeInSeconds"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Connection Pool Settings Max Wait Time In Seconds"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.connectionPoolSettingsMaxWaitTimeInSeconds
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.connectionPoolSettingsMaxWaitTimeInSeconds"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Socket Settings Read Timeout In Seconds"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.socketSettingsReadTimeoutInSeconds
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.socketSettingsReadTimeoutInSeconds"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />

                                            <TextField
                                                variant="filled"
                                                type="number"
                                                label="Socket Settings Connect Timeout In Seconds"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values.contentAssistMapping?.dynamicMongoDatabaseMapping?.socketSettingsConnectTimeoutInSeconds
                                                }
                                                name="contentAssistMapping.dynamicMongoDatabaseMapping.socketSettingsConnectTimeoutInSeconds"
                                                required={dynamicMongoDatabaseMappingEnabled}
                                            />
                                        </Box>
                                    </>
                                }>
                                </AccordionExt>
                            </Box>
                        )}

                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    contentAssistTenantSettingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    contentAssistTenantSettingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.userSettingPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentAssistTenantSettingDetail)