import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";

/**
 * An action to fetch access control from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchAccessControl = async (searchOptions) => {
    let accessControlSearchUrl = `/admin/access/control?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        accessControlSearchUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    const { data } = await SecureAxios.get(accessControlSearchUrl)
    return data
}

/**
 * An action to update the access control
 */
export async function updateAccessControl(accessControl) {
    const { data } = await SecureAxios.put(
        `/admin/access/control/update`,
        accessControl,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'access-control', keyField: 'id', data: data.data },
    })

    return data.data
}