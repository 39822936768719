import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Tag, WithContext as ReactTags } from 'react-tag-input'
import { Box, FormHelperText, InputLabel, useTheme } from '@mui/material'
import { tokens } from '../theme'

const TagInputExt: FunctionComponent<TagInputProp> = ({
    name,
    values,
    label,
    required = false,
    editable = true,
    onItemAdd,
    onItemDelete,
    allowDragDrop = false,
    onItemDrag,
    onBlurEvent,
    error,
    helperText,
}) => {
    const uuid = useMemo(() => uuidv4(), [])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalItemAdd = async (v: Tag) => {
        if (onItemAdd) {
            onItemAdd(v)
        }
    }

    const onInternalItemDelete = async (index: number) => {
        if (onItemDelete) {
            onItemDelete(index)
        }
    }

    const onInternalItemDrag = async (
        v: Tag,
        currentIndex: number,
        dragIndex: number
    ) => {
        if (onItemDrag) {
            onItemDrag(v, currentIndex, dragIndex)
        }
    }

    return (
        <Box
            sx={{
                '& .ReactTags__tags': {
                    position: `relative`,
                },

                '& .ReactTags__tagInput': {
                    width: `100%`,
                    borderRadius: `6px`,
                    display: `inline-block`,
                    marginTop: `10px`,
                    border: `1px solid #ccc`,
                    resize: `vertical`,
                },

                '& .ReactTags__tagInput input.ReactTags__tagInputField, .ReactTags__tagInput input.ReactTags__tagInputField:focus':
                    {
                        height: `31px`,
                        margin: 0,
                        width: `100%`,
                        border: `1px solid #eee`,
                    },

                '& .ReactTags__selected span.ReactTags__tag': {
                    border: `1px solid #ddd`,
                    color: `${colors.blueAccent[100]} !important`,
                    background: colors.blueAccent[700],
                    display: `inline-block`,
                    padding: `5px`,
                    margin: `0 5px`,
                    borderRadius: `2px`,
                },

                '& .ReactTags__selected a.ReactTags__remove': {
                    color: `#aaa`,
                    marginLeft: `5px`,
                    cursor: `pointer`,
                },

                /* Styles for suggestions */
                '& .ReactTags__suggestions': {
                    position: `absolute`,
                },

                '& .ReactTags__suggestions ul': {
                    listStyleType: `none`,
                    boxShadow: `0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2)`,
                    background: `white`,
                    width: `100%`,
                },

                '& .ReactTags__suggestions li': {
                    borderBottom: `1px solid #ddd`,
                    padding: `5px 10px`,
                    margin: 0,
                },

                '& .ReactTags__suggestions li mark': {
                    textDecoration: `underline`,
                    background: `none`,
                    fontWeight: 600,
                },

                '& .ReactTags__suggestions ul li.ReactTags__activeSuggestion': {
                    textDecoration: `underline`,
                    background: `#b7cfe0`,
                    cursor: `pointer`,
                },

                '& .ReactTags__remove': {
                    border: `none`,
                    cursor: `pointer`,
                    background: `none`,
                    color: `white`,
                },
            }}
            style={{ marginLeft: `1em` }}
        >
            <FormHelperText onBlur={onInternalBlurEvent} error={error}>
                {label && (
                    <InputLabel
                        style={{ fontSize: `x-small`, paddingBottom: `10px` }}
                    >
                        {label}
                    </InputLabel>
                )}
                <ReactTags
                    key={uuid}
                    name={name}
                    tags={values}
                    readOnly={!editable}
                    handleAddition={onInternalItemAdd}
                    handleDelete={onInternalItemDelete}
                    autofocus={false} // We probably don't need this
                    allowDragDrop={allowDragDrop}
                    handleDrag={onInternalItemDrag}
                />

                {helperText}
            </FormHelperText>
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface TagInputProp {
    name: string | undefined
    values: Tag[] | undefined
    label?: string
    required?: boolean // TODO: this is not implemented yet
    editable?: boolean
    onItemAdd?: (v: Tag) => void // Handle event upon onenter
    onItemDelete?: (index: number) => void // Handle event upon onenter
    allowDragDrop?: boolean
    onItemDrag?: (v: Tag, currentIndex: number, dragIndex: number) => void // Handle event upon ondrag
    onBlurEvent?: (event: any) => void // Handle event upon onblur
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default TagInputExt
