import React from 'react';
import { TextField, Box } from '@mui/material';
import {MongoServerConfig} from "../../../../../interfaces/ServerConfigType";
import * as yup from "yup";
import ButtonExt from "../../../../../components/ButtonExt";
import {Formik} from "formik";
import {connect} from "react-redux";

const mongoServerConfigSchema = yup.object().shape({
    maxRetryAttempts: yup.number().required('required'),
    delayBetweenRetryAttemptsInSeconds: yup.number().required('required'),
})

const MongoServerConfigFC: React.FC<MongoServerConfig> = ({
                                                              userSetting,
                                                              key,
                                                              value,
                                                              setValue,
                                                              onChange,
                                                              isLoading,
                                                          }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={mongoServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <TextField
                            variant="filled"
                            type="number"
                            label="Maximum Retry Attempts"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.maxRetryAttempts}
                            name="maxRetryAttempts"
                            error={!!touched.maxRetryAttempts && !!errors.maxRetryAttempts}
                            helperText={touched.maxRetryAttempts && errors.maxRetryAttempts}
                        />

                        <TextField
                            variant="filled"
                            type="number"
                            label="Cache Release In Seconds"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.delayBetweenRetryAttemptsInSeconds}
                            name="delayBetweenRetryAttemptsInSeconds"
                            error={!!touched.delayBetweenRetryAttemptsInSeconds && !!errors.delayBetweenRetryAttemptsInSeconds}
                            helperText={touched.delayBetweenRetryAttemptsInSeconds && errors.delayBetweenRetryAttemptsInSeconds}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(MongoServerConfigFC)