import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import {
    fetchContentForGraphs,
    fetchContentTypes,
    fetchRelationships,
} from '../../../../actions/contentAssists'
import { useAuthQueryWithQueryFunction } from '../../../../extensions/UseAuthQuery'
import {Box, TextField, useTheme} from '@mui/material'
import Header from '../../../../components/Header'
import AutocompleteExt from '../../../../components/Autocomplete'
import {
    ContentAssist,
    ContentAssistFilterCustomFilterOptions,
    ContentType
} from '../../../../interfaces/ContentAssistType'
import { ApiError } from '../../../../interfaces/ErrorType'
import {KeyPairs, TenantSetting} from "../../../../interfaces/SettingType";
import {
    calculateWeekRangeByMonthAndSelectedDevelopmentStage,
    fetchDevelopmentStages
} from "../../../../actions/developmentStage";
import ErrorMessage from "../../../../components/ErrorMessage";
import {Formik} from "formik";
import ButtonExt from "../../../../components/ButtonExt";
import SearchIcon from "@mui/icons-material/Search";
import RestoreIcon from "@mui/icons-material/Restore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {GraphType} from "../../../../interfaces/GraphType";
// @ts-ignore
import Graph from "react-graph-vis";
// import "./styles.css";
// need to import the vis network css in order to show tooltip
// import "./network.css";
import { v4 as uuidv4 } from 'uuid'
import {tokens} from "../../../../theme";
import {useMutation} from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import SelectExt from "../../../../components/Select";
import {stateOptions} from "../../../../share/DefaultConstant";
import CheckboxExt from "../../../../components/Checkbox";

/**
 * Render Content Assist Graphs page
 *
 * @returns contents
 */
function ContentAssistGraphs(props: { localeList: any, userSetting: TenantSetting | undefined }) {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const contentLocalizations = props.userSetting?.contentAssistMapping?.localizations
    const defaultSearchOptions = {
        states: [],
        developmentStage: undefined,
        month: NaN,
        weekFrom: NaN,
        weekTo: NaN,
        delayWeekFrom: NaN,
        delayWeekTo: NaN,
        relationshipId: '',
        contentLocalizationCode: [],
        translateTo: '',
        contentTypeRegex: '',
        contentTypeExclusiveRegex: '',
        milestone: false,
    }

    // Use state to track whether the search area is visible or not
    const [isSearchAreaVisible, setIsSearchAreaVisible] = useState(true);
    const [graphData, setGraphData] = useState<GraphType>({nodes: [], edges: []})
    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentAssistFilterCustomFilterOptions>(defaultSearchOptions)
    const [reset, setReset] = useState(false)

    /**
     * Fetch types list
     * */
    const contentTypesQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        ContentType[]
        >('contentTypes', fetchContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Calculate week range by month and selected development stage
     */
    const calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekRangeByMonthAndSelectedDevelopmentStage)

    const contentLocalizationOptions = contentLocalizations?.map((contentLocalization: KeyPairs) => {
        return {
            label: `${contentLocalization.values[1]} (${contentLocalization.values[0]})`,
            value: contentLocalization.values[0],
        }
    })

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContentForGraphs(searchOptions)
    }

    const getGraphData = (data: any) => {
        const contentNodes = data.data.content.map((node: any) => {
            return {
                id: `content-${node.id}`,
                label: `${node.title}, ${node.due}, ${node.dueTo ?? '-'}, M: ${node.milestone ? 'Y' : 'N'}`,
                title: `${node.subTitle}`,
            };
        });

        const contentTypeNodes = contentTypesQuery.data?.map((node) => {
            return {
                id: `type-${node.id}`,
                label: node.type,
                title: node.type,
            };
        });

        const edges = data.data.content.flatMap((edge: ContentAssist) => {
            return edge.contentTypes?.map((contentType) => {
                return {
                    from: `content-${edge.id}`,
                    to: `type-${contentType.id}`,
                };
            }) || [];
        });

        // Extract unique node IDs from edges
        const connectedNodeIds = new Set([...edges.map((edge: any) => edge.from), ...edges.map((edge: any) => edge.to)]);

        // Filter nodes that have relations in the edges
        const filteredContentNodes = contentNodes.filter((node: any) => connectedNodeIds.has(node.id));
        const filteredContentTypeNodes = contentTypeNodes?.filter((node) => connectedNodeIds.has(node.id)) || [];

        return {
            nodes: [...filteredContentNodes, ...filteredContentTypeNodes],
            edges: edges,
        };
    };

    const { data, error, status, refetch } = useAuthQueryWithQueryFunction<
        any,
        ApiError,
        any
        >(
        ['content-recommendation-graph', customSearchOptions],
        () => onSearchPageUseQueryEvent({uuid: undefined, keyword: undefined, customSearchOptions,}),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: false, // disable this query from automatically running
            onSuccess(data) {
                const newGraphData = getGraphData(data);
                setGraphData(newGraphData)

                if (reset) {
                    setReset(false)
                }
            },
            onError(error) {
                if (reset) {
                    setReset(false)
                }
            },
        },
    )

    /**
     * Fetch relationship list
     * */
    const contentRelationshipQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentRelationships', fetchRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableRelationshipOptions = contentRelationshipQuery.data?.map((contentRelationship) => {
        return {
            value: contentRelationship.id,
            label: contentRelationship.name,
        }
    })

    const developmentStages = developmentStageQuery.data

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    });

    // Function to toggle the visibility of the search area
    const toggleSearchArea = () => {
        setIsSearchAreaVisible(!isSearchAreaVisible);
    };

    const onSearch = (values: ContentAssistFilterCustomFilterOptions) => {
        refetch()
    }

    const onReset = async () => {
        setReset(true)

        setCustomSearchOptions({
            ...defaultSearchOptions,
        })

        setReset(false)
    }

    const options = {
        layout: {
            hierarchical: false,
        },
        nodes: {
            color: {
                background: theme.palette.mode === 'dark'
                    ? colors.blueAccent[700]
                    : colors.grey[200],
            },
            font: {
                color: theme.palette.mode === 'dark'
                    ? colors.primary[200]
                    : colors.primary[900],
            },
        },
        edges: {
            color:
                theme.palette.mode === 'dark'
                    ? colors.blueAccent[700]
                    : colors.grey[800],
        },
        physics: {
            enabled: true,
            stabilization: {
                enabled: true,
                iterations: 1000, // Adjust the number of stabilization iterations
            },
            barnesHut: {
                gravitationalConstant: -3000,
                centralGravity: 0.1,
                springLength: 150,
                springConstant: 0.05,
            },
        },
        height: `600px`,
    };

    const events = {
        select: function(event: any) {
            var { nodes, edges } = event;
        }
    };

    /**
     * Page containing content assist graphs
     */
    return (
        <Box m="20px">
            <LoadingOverlay active={calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                <Header title="Content Assist Graph" />

                <Box style={{ marginBottom: `1em` }}>
                    {status === 'error' && <ErrorMessage error={error} />}
                </Box>

                <Formik
                    onSubmit={onSearch}
                    initialValues={customSearchOptions}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="10px"
                                gridTemplateColumns="repeat(5, minmax(0,1fr))"
                            >
                                <AutocompleteExt
                                    name="developmentStageId"
                                    multiSelection={false}
                                    label="Development Stage..."
                                    selectedValue={customSearchOptions.developmentStage?.id}
                                    onSelect={(value) => {
                                        setCustomSearchOptions({
                                            ...customSearchOptions,
                                            developmentStage: developmentStages?.find((developmentStage) => developmentStage.id === value),
                                            month: NaN,
                                            weekFrom: NaN,
                                            weekTo: NaN,
                                            delayWeekFrom: NaN,
                                            delayWeekTo: NaN,
                                        })
                                    }}
                                    options={availableDevelopmentStageOptions}
                                    required={true}
                                />

                                {customSearchOptions.developmentStage?.supportedMonthConversion && (
                                    <TextField
                                        type="number"
                                        variant="filled"
                                        name="month"
                                        value={customSearchOptions.month}
                                        label={`Month (${customSearchOptions.developmentStage?.validMonthFrom} - ${customSearchOptions.developmentStage?.validMonthTo})`}
                                        InputProps={{
                                            inputProps: {
                                                min: customSearchOptions.developmentStage?.validMonthFrom,
                                                max: customSearchOptions.developmentStage?.validMonthTo,
                                            },
                                        }}
                                        onBlur={(event) => {
                                            if (!customSearchOptions.month) {
                                                return;
                                            }

                                            if (customSearchOptions.month < customSearchOptions.developmentStage!!.validMonthFrom || customSearchOptions.month > customSearchOptions.developmentStage!!.validMonthTo) {
                                                return;
                                            }

                                            calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.mutate({
                                                month: customSearchOptions.month,
                                                developmentStageId: customSearchOptions.developmentStage?.id,
                                            }, {
                                                onSuccess: (data) => {
                                                    setCustomSearchOptions({
                                                        ...customSearchOptions,
                                                        month: customSearchOptions.month,
                                                        weekFrom: data[0],
                                                        weekTo: data[1],
                                                        delayWeekFrom: NaN,
                                                        delayWeekTo: NaN,
                                                    })
                                                }
                                            })
                                        }}
                                        onChange={(event) => {
                                            if (!event.target.value) {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    month: NaN,
                                                    weekFrom: NaN,
                                                    weekTo: NaN,
                                                    delayWeekFrom: NaN,
                                                    delayWeekTo: NaN,
                                                })
                                            } else {
                                                const month = parseInt(event.target.value)

                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    month: month,
                                                })
                                            }
                                        }}
                                    />
                                )}

                                <TextField
                                    type="number"
                                    variant="filled"
                                    name="weekFrom"
                                    value={customSearchOptions.weekFrom}
                                    label={`${customSearchOptions.developmentStage?.id ? `Week (${customSearchOptions.developmentStage?.weekFrom} - ${customSearchOptions.developmentStage?.weekTo})` : 'Week...'} `}
                                    InputProps={{
                                        inputProps: {
                                            min: customSearchOptions.developmentStage?.weekFrom,
                                            max: customSearchOptions.developmentStage?.weekTo,
                                        },
                                    }}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            setCustomSearchOptions({
                                                ...customSearchOptions,
                                                weekFrom: NaN,
                                                weekTo: NaN,
                                                delayWeekFrom: NaN,
                                                delayWeekTo: NaN,
                                            })
                                        } else {
                                            setCustomSearchOptions({
                                                ...customSearchOptions,
                                                weekFrom: parseInt(event.target.value),
                                                weekTo: NaN,
                                                delayWeekFrom: NaN,
                                                delayWeekTo: NaN,
                                            })
                                        }
                                    }}
                                    required={true}
                                />

                                <TextField
                                    type="number"
                                    variant="filled"
                                    name="weekTo"
                                    value={customSearchOptions.weekTo}
                                    label="Week To..."
                                    InputProps={{
                                        inputProps: {
                                            min: customSearchOptions.weekFrom ? customSearchOptions.weekFrom : customSearchOptions.developmentStage?.weekFrom,
                                            max: customSearchOptions.developmentStage?.weekTo,
                                        },
                                    }}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            setCustomSearchOptions({
                                                ...customSearchOptions,
                                                weekTo: NaN,
                                                delayWeekFrom: NaN,
                                                delayWeekTo: NaN,
                                            })
                                        } else {
                                            setCustomSearchOptions({
                                                ...customSearchOptions,
                                                weekTo: parseInt(event.target.value),
                                                delayWeekFrom: NaN,
                                                delayWeekTo: NaN,
                                            })
                                        }
                                    }}
                                    disabled={true}
                                />

                                {/* Conditionally render the search area based on visibility state */}
                                {isSearchAreaVisible && (
                                    <>
                                        <TextField
                                            type="number"
                                            variant="filled"
                                            name="delayWeekFrom"
                                            value={customSearchOptions.delayWeekFrom}
                                            label={`${customSearchOptions.developmentStage?.id && customSearchOptions.developmentStage?.maxThreshold ? `Delay Week (1 - ${customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo})` : 'Delay Week...'} `}
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1,
                                                },
                                            }}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setCustomSearchOptions({
                                                        ...customSearchOptions,
                                                        delayWeekFrom: NaN,
                                                        delayWeekTo: NaN,
                                                    })
                                                } else {
                                                    setCustomSearchOptions({
                                                        ...customSearchOptions,
                                                        delayWeekFrom: parseInt(event.target.value),
                                                        delayWeekTo: NaN,
                                                    })
                                                }
                                            }}
                                            disabled={customSearchOptions.developmentStage && (!customSearchOptions.developmentStage?.maxThreshold || customSearchOptions.weekFrom !== customSearchOptions.developmentStage.weekTo)}
                                        />

                                        <TextField
                                            type="number"
                                            variant="filled"
                                            name="delayWeekTo"
                                            value={customSearchOptions.delayWeekTo}
                                            label={`${customSearchOptions.developmentStage?.id && customSearchOptions.developmentStage?.maxThreshold ? `Delay Week To (Max ${customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo})` : 'Delay Week To...'} `}
                                            InputProps={{
                                                inputProps: {
                                                    min: customSearchOptions.delayWeekFrom ? customSearchOptions.delayWeekFrom : (customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1),
                                                    max: customSearchOptions.developmentStage?.maxThreshold ? customSearchOptions.developmentStage?.maxThreshold - customSearchOptions.developmentStage?.weekTo : 1,
                                                },
                                            }}
                                            onChange={(event) => {
                                                if (!event.target.value) {
                                                    setCustomSearchOptions({
                                                        ...customSearchOptions,
                                                        delayWeekTo: NaN,
                                                    })
                                                } else {
                                                    setCustomSearchOptions({
                                                        ...customSearchOptions,
                                                        delayWeekTo: parseInt(event.target.value),
                                                    })
                                                }
                                            }}
                                            disabled={!customSearchOptions.delayWeekFrom || (customSearchOptions.developmentStage && !customSearchOptions.developmentStage?.maxThreshold)}
                                        />
                                        <SelectExt
                                            name="states"
                                            multiSelection={true}
                                            label="States..."
                                            selectedValue={customSearchOptions.states}
                                            onSelect={(value) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    states: value,
                                                })
                                            }}
                                            options={stateOptions}
                                        />

                                        <AutocompleteExt
                                            name="relationshipId"
                                            multiSelection={false}
                                            label="Relationship..."
                                            selectedValue={customSearchOptions.relationshipId}
                                            onSelect={(value) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    relationshipId: value
                                                })
                                            }}
                                            options={availableRelationshipOptions}
                                        />

                                        <AutocompleteExt
                                            name="contentLocalizationCode"
                                            multiSelection={true}
                                            label="Content localization..."
                                            selectedValue={customSearchOptions.contentLocalizationCode}
                                            onSelect={(value) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    contentLocalizationCode: value,
                                                })
                                            }}
                                            options={contentLocalizationOptions}
                                        />

                                        <TextField
                                            variant="filled"
                                            name="contentTypeRegex"
                                            value={customSearchOptions.contentTypeRegex}
                                            label="Content Type Regex..."
                                            onChange={(event) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    contentTypeRegex: event.target.value,
                                                })
                                            }}
                                        />

                                        <TextField
                                            variant="filled"
                                            name="contentTypeExclusiveRegex"
                                            value={customSearchOptions.contentTypeExclusiveRegex}
                                            label="Content Type Exclusive Regex..."
                                            onChange={(event) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    contentTypeExclusiveRegex: event.target.value,
                                                })
                                            }}
                                            disabled={!customSearchOptions.contentTypeRegex}
                                        />

                                        <CheckboxExt
                                            name="milestone"
                                            label="Milestone Only..."
                                            value={customSearchOptions.milestone}
                                            onChange={(v) => {
                                                setCustomSearchOptions({
                                                    ...customSearchOptions,
                                                    milestone: v,
                                                })
                                            }}
                                        />
                                    </>
                                )}
                            </Box>

                            <Box textAlign='right'>
                                <ButtonExt
                                    type="submit"
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        margin: `5px`,
                                    }}
                                    icon={<SearchIcon />}
                                    value={
                                        status === 'loading' && !reset
                                            ? 'Searching...'
                                            : 'Search'
                                    }
                                />
                                <ButtonExt
                                    style={{
                                        width: `auto`,
                                        height: `auto`,
                                        margin: `5px`,
                                    }}
                                    icon={<RestoreIcon />}
                                    value={reset ? 'Resetting...' : 'Reset'}
                                    onClickEvent={() => onReset()}
                                />
                            </Box>

                            <ButtonExt
                                style={{
                                    width: `auto`,
                                    height: `auto`,
                                    margin: `5px`,
                                }}
                                icon={isSearchAreaVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                value={isSearchAreaVisible ? 'Hide' : 'Show'}
                                onClickEvent={toggleSearchArea}
                            />
                        </form>
                    )}
                </Formik>

                <Box style={{ marginTop: `0.5em`}}>
                    <Graph
                        key={uuidv4()}
                        graph={graphData}
                        options={options}
                        events={events}
                    />
                </Box>
            </LoadingOverlay>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentAssistGraphs)