import React from 'react';
import { Box } from '@mui/material';
import * as yup from "yup";
import ButtonExt from "../../../../../../components/ButtonExt";
import {Formik} from "formik";
import {
    TranslationProviderServerConfig
} from "../../../../../../interfaces/ServerConfigType";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import {translationProviderOptions} from "../../../../../../share/SystemConfigConstant";
import {connect} from "react-redux";

const translationProviderServerConfigSchema = yup.object().shape({
    type: yup.string().required('required'),
})

const TranslationProviderServerConfigFC: React.FC<TranslationProviderServerConfig> = ({
                                                                                          userSetting,
                                                                                          key,
                                                                                          value,
                                                                                          setValue,
                                                                                          onChange,
                                                                                          isLoading,
                                                                                      }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={translationProviderServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <AutocompleteExt
                            name="type"
                            multiSelection={false}
                            label="Translation Provider"
                            selectedValue={
                                values.type
                            }
                            options={translationProviderOptions}
                            onBlurEvent={handleBlur}
                            onSelect={(v) => {
                                setValue({
                                    ...values,
                                    type: v
                                })
                                values.type = v
                            }}
                            disableUnselectAll={true}
                            required={true}
                        />
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(TranslationProviderServerConfigFC)