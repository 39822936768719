import store from '../redux/store'
import SecureAxios from '../extensions/SecureAxios'
import {UPDATE_TABLE_DATA_ITEM} from "../redux/actionType";
import {fetchReportStatusWithRetry} from "./report";

const buildContentRequestBody = (searchOptions) => {
    const requestBody = {
        keyword: searchOptions.keyword,
        page: searchOptions.pageOptions.page,
        size: searchOptions.pageOptions.pageLimit,
    }

    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        requestBody.sort = searchOptions.sortOptions.sortField

        if (requestBody.sort === 'week') {
            requestBody.sort = 'due'
        }

        requestBody.sortDir = searchOptions.sortOptions.sortOrder
    }


    if (searchOptions.customSearchOptions?.states?.length) {
        requestBody.states = searchOptions.customSearchOptions.states
    }

    if (searchOptions.customSearchOptions?.weekFrom) {
        requestBody.week = searchOptions.customSearchOptions.weekFrom
        requestBody.weekFrom = searchOptions.customSearchOptions.weekFrom

        if (searchOptions.customSearchOptions?.weekTo) {
            requestBody.weekTo = searchOptions.customSearchOptions.weekTo
        }
    }

    if (searchOptions.customSearchOptions?.delayWeekFrom) {
        requestBody.delayWeek = searchOptions.customSearchOptions.delayWeekFrom
        requestBody.delayWeekFrom = searchOptions.customSearchOptions.delayWeekFrom

        if (searchOptions.customSearchOptions?.delayWeekTo) {
            requestBody.delayWeekTo = searchOptions.customSearchOptions.delayWeekTo
        }
    }

    if (searchOptions.customSearchOptions?.contentTypeRegex) {
        requestBody.contentTypeRegex = searchOptions.customSearchOptions.contentTypeRegex

        if (searchOptions.customSearchOptions?.contentTypeExclusiveRegex) {
            requestBody.contentTypeExclusiveRegex = searchOptions.customSearchOptions.contentTypeExclusiveRegex
        }
    }

    if (searchOptions.customSearchOptions?.milestone) {
        requestBody.milestone = true
    }

    if (searchOptions.customSearchOptions?.relationshipId) {
        requestBody.relationshipId = searchOptions.customSearchOptions.relationshipId
    }

    if (searchOptions.customSearchOptions?.contentLocalizationCode?.length) {
        requestBody.locale = searchOptions.customSearchOptions.contentLocalizationCode
    }

    return requestBody
}

const buildContentUrl = (url) => {
    let contentUrl = `${url}`

    if (store.getState().switchTenant?.tenant?.id) {
        contentUrl += `&tenantId=${store.getState().switchTenant?.tenant.id}`
    }

    return contentUrl
}

/**
 * An action to fetch contents from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchContents = async (searchOptions) => {
    let contentUrl = buildContentUrl('/cms/content')

    let requestHeaders = {
        'Content-Type': 'application/json',
    }

    if (searchOptions.customSearchOptions?.translateTo) {
        requestHeaders = {
            'Content-Type': 'application/json',
            'X-Locale': searchOptions.customSearchOptions?.translateTo,
        }
    }

    const requestBody = buildContentRequestBody(searchOptions)
    const { data } = await SecureAxios.post(contentUrl, requestBody,{
        headers: requestHeaders
    })
    return data
}

/**
 * An action to fetch contents from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchContentForGraphs = async (searchOptions) => {
    let appendSearchOptions = {
        ...searchOptions,
        pageOptions: {
            page: 1,
            pageLimit: 1000,
        },
    }

    let contentUrl = buildContentUrl('/cms/content')

    let requestHeaders = {
        'Content-Type': 'application/json',
    }

    if (searchOptions.customSearchOptions?.translateTo) {
        requestHeaders = {
            'Content-Type': 'application/json',
            'X-Locale': searchOptions.customSearchOptions?.translateTo,
        }
    }

    const requestBody = buildContentRequestBody(appendSearchOptions)
    const { data } = await SecureAxios.post(contentUrl, requestBody,{
        headers: requestHeaders
    })
    return data
}

/**
 * An action to fetch contents from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchContentRecommendations = async (searchOptions) => {

    let requestHeaders = {
        'Content-Type': 'application/json',
    }

    if (searchOptions.customSearchOptions?.translateTo) {
        requestHeaders = {
            'Content-Type': 'application/json',
            'X-Locale': searchOptions.customSearchOptions?.translateTo,
        }
    }

    const requestBody = {
        queryVector: searchOptions.customSearchOptions.queryVector,
        topk: searchOptions.customSearchOptions.topk,
        developmentStageIds: searchOptions.customSearchOptions.developmentStageIds,
        includeScore: searchOptions.customSearchOptions.includeScore,
        acceptableVectorSearchScore: searchOptions.customSearchOptions.acceptableVectorSearchScore,
        locale: searchOptions.customSearchOptions.contentLocalizationCodes,
        relationshipId: searchOptions.customSearchOptions.relationshipId,
        structureRegex: searchOptions.customSearchOptions.structureRegex,
        structureExclusiveRegex: searchOptions.customSearchOptions.structureExclusiveRegex,
    }

    if (searchOptions.customSearchOptions?.weekFrom && searchOptions.customSearchOptions?.weekTo) {
        requestBody.weekFrom = searchOptions.customSearchOptions.weekFrom
        requestBody.weekTo = searchOptions.customSearchOptions.weekTo
    } else {
        requestBody.week = searchOptions.customSearchOptions.weekFrom
    }

    if (searchOptions.customSearchOptions?.milestone) {
        requestBody.milestone = true
    }

    const { data } = await SecureAxios.post('/cms/content/recommendation', requestBody, {
        headers: requestHeaders
    })
    return data
}

/**
 * Generate Audit Report
 * @param searchOptions
 * @returns {Promise<any>}
 */
export const generateContentReport = async (searchOptions) => {
    let contentUrl = buildContentUrl('/admin/cms/content/csv/report/async')

    let requestHeaders = {
        'Content-Type': 'application/json',
    }

    if (searchOptions.customSearchOptions?.translateTo) {
        requestHeaders = {
            'X-Locale': searchOptions.customSearchOptions?.translateTo,
        }
    }

    const requestBody = buildContentRequestBody(searchOptions)
    const reportResponse = await SecureAxios.post(contentUrl, requestBody,{
        headers: requestHeaders
    })

    const reportId = reportResponse.data.data.reportId

    // Fetch content report status and continuously retry every 5 seconds until success
    const { data } = await fetchReportStatusWithRetry(reportId);

    return data.data
}

/**
 * An action to fetch default content reference mappings from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchDefaultContentReferenceMappings = async (searchOptions) => {
    let defaultContentReferenceMappingUrl = `/admin/cms/default/content/type/reference/mapping?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        defaultContentReferenceMappingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.filteredByDue) {
        defaultContentReferenceMappingUrl += `&filteredByDue=${searchOptions.customSearchOptions.filteredByDue}`
    }

    const { data } = await SecureAxios.get(defaultContentReferenceMappingUrl)
    return data
}

/**
 * An action to create the current default content reference mapping
 */
export async function createDefaultContentReferenceMapping(defaultContentReferenceMapping) {
    const requestBody = {
        ...defaultContentReferenceMapping,
    }
    const { data } = await SecureAxios.post(`/admin/cms/default/content/type/reference/mapping`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'defaultContentReferenceMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to update the current default content reference mapping
 */
export async function updateDefaultContentReferenceMapping(defaultContentReferenceMapping) {
    const requestBody = {
        ...defaultContentReferenceMapping,
    }
    const { data } = await SecureAxios.put(
        `/admin/cms/default/content/type/reference/mapping/${defaultContentReferenceMapping.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'defaultContentReferenceMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch content tenant interest mappings from neuro api
 *
 * @param searchOptions
 * @returns {Promise<void>}
 */
export const fetchContentTenantInterestMappings = async (searchOptions) => {
    let contentTenantInterestMappingUrl = `/admin/content/tenant/interest/mapping?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        contentTenantInterestMappingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.tenantId) {
        contentTenantInterestMappingUrl += `&tenantId=${searchOptions.customSearchOptions.tenantId}`
    }

    const { data } = await SecureAxios.get(contentTenantInterestMappingUrl)
    return data
}

/**
 * An action to create the content tenant interest mapping
 */
export async function createContentTenantInterestMapping(contentTenantInterestMapping) {
    const requestBody = {
        ...contentTenantInterestMapping,
    }
    const { data } = await SecureAxios.post(`/admin/content/tenant/interest/mapping`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'contentTenantInterestMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to update the content tenant interest mapping
 */
export async function updateContentTenantInterestMapping(contentTenantInterestMapping) {
    const requestBody = {
        ...contentTenantInterestMapping,
    }
    const { data } = await SecureAxios.put(
        `/admin/content/tenant/interest/mapping/${contentTenantInterestMapping.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'contentTenantInterestMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch content tenant milestone skill mappings from neuro api
 *
 * @param searchOptions
 * @returns {Promise<void>}
 */
export const fetchContentTenantMilestoneSkillMappings = async (searchOptions) => {
    let contentTenantMilestoneSkillMappingUrl = `/admin/content/tenant/milestone/skill/mapping?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        contentTenantMilestoneSkillMappingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }

    if (searchOptions.customSearchOptions?.tenantId) {
        contentTenantMilestoneSkillMappingUrl += `&tenantId=${searchOptions.customSearchOptions.tenantId}`
    }

    const { data } = await SecureAxios.get(contentTenantMilestoneSkillMappingUrl)
    return data
}

/**
 * An action to create the content tenant milestone skill mapping
 */
export async function createContentTenantMilestoneSkillMapping(contentTenantMilestoneSkillMapping) {
    const requestBody = {
        ...contentTenantMilestoneSkillMapping,
    }
    const { data } = await SecureAxios.post(`/admin/content/tenant/milestone/skill/mapping`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'contentTenantMilestoneSkillMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to update the content tenant milestone skill mapping
 */
export async function updateContentTenantMilestoneSkillMapping(contentTenantMilestoneSkillMapping) {
    const requestBody = {
        ...contentTenantMilestoneSkillMapping,
    }
    const { data } = await SecureAxios.put(
        `/admin/content/tenant/milestone/skill/mapping/${contentTenantMilestoneSkillMapping.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'contentTenantMilestoneSkillMappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch content types from neuro api
 */
export const fetchContentTypes = async () => {
    const { data } = await SecureAxios.get(`/cms/content/type?page=1&size=100000&sort=type&sortDir=asc`)
    return data.data.content
}

/**
 * An action to fetch relationships from neuro api
 */
export const fetchRelationships = async () => {
    const {data} = await SecureAxios.get(`/cms/relationship?page=1&size=100000`)
    return data.data.content
}