import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageRow: {
            display: 'flex',
        },
        messageRowRight: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        messageLeft: {
            position: 'relative',
            marginLeft: '20px',
            marginBottom: '10px',
            padding: '10px',
            width: '60%',
            textAlign: 'left',
            border: '1px solid #c2c2c2',
            borderRadius: '10px',
            '&:after': {
                content: "''",
                position: 'absolute',
                width: '0',
                height: '0',
                borderTop: '15px solid #c2c2c2',
                borderLeft: '15px solid transparent',
                borderRight: '15px solid transparent',
                top: '0',
                left: '-15px',
            },
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '0',
                height: '0',
                borderTop: '17px solid #c2c2c2',
                borderLeft: '16px solid transparent',
                borderRight: '16px solid transparent',
                top: '-1px',
                left: '-17px',
            },
        },
        messageRight: {
            position: 'relative',
            marginRight: '20px',
            marginBottom: '10px',
            padding: '10px',
            backgroundColor: '#c2c2c2',
            width: '60%',
            textAlign: 'left',
            font: "400 .9em 'Open Sans', sans-serif",
            border: '1px solid #dfd087',
            borderRadius: '10px',
            '&:after': {
                content: "''",
                position: 'absolute',
                width: '0',
                height: '0',
                borderTop: '15px solid #c2c2c2',
                borderLeft: '15px solid transparent',
                borderRight: '15px solid transparent',
                top: '0',
                right: '-15px',
            },
            '&:before': {
                content: "''",
                position: 'absolute',
                width: '0',
                height: '0',
                borderTop: '17px solid #dfd087',
                borderLeft: '16px solid transparent',
                borderRight: '16px solid transparent',
                top: '-1px',
                right: '-17px',
            },
        },

        messageContent: {
            padding: 0,
            margin: 2,
            whiteSpace: `pre-line`,
            lineBreak: `anywhere`,
        },
        messageTimeStampRight: {
            position: 'absolute',
            fontSize: '.85em',
            fontWeight: 300,
            marginTop: '10px',
            bottom: '-3px',
            right: '5px',
            padding: '2px',
        },

        bot: {
            color: 'white',
            backgroundColor: '#535ac8',
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        avatarNothing: {
            color: 'transparent',
            backgroundColor: 'transparent',
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        displayName: {
            marginLeft: '20px',
        },
    })
)

export const MessageLeft = (props: {
    message: string
    certified?: boolean
    referenceIds?: string[]
    timestamp?: string | undefined
    displayName: string
}) => {
    const message = props.message ? props.message : ''
    const certified = props.certified ? props.certified : false
    const referenceIds = props.referenceIds ? props.referenceIds : []
    const timestamp = props.timestamp ? props.timestamp : ''
    const displayName = props.displayName ? props.displayName : 'Bot'
    const classes = useStyles();


    // State to handle dialog visibility
    const [openDialog, setOpenDialog] = useState(false);

    // Function to handle the dialog open/close
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <div className={classes.messageRow}>
                <Avatar className={classes.bot}>
                    {displayName
                        .split(' ')
                        .slice(0, 2)
                        .map((word) => word.charAt(0))
                        .join('')
                        .toUpperCase()}
                </Avatar>
                <div>
                    <div className={classes.messageLeft}>
                        <div>
                            <ReactMarkdown className={classes.messageContent}>{message}</ReactMarkdown>
                        </div>
                        {timestamp && (
                            <div className={classes.messageTimeStampRight}>
                                {timestamp}
                            </div>
                        )}
                        <Box textAlign={'right'}>
                            <CopyToClipboard text={message}>
                                <IconButton color="primary">
                                    <ContentCopyIcon />
                                </IconButton>
                            </CopyToClipboard>
                            {certified && (
                                <IconButton color="primary" onClick={handleDialogOpen}>
                                    <VerifiedUserIcon />
                                </IconButton>
                            )}
                        </Box>
                    </div>
                </div>
            </div>

            {/* Dialog for showing the knowledge source */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Knowledge Source</DialogTitle>
                <DialogContent>
                    <Box display="grid" gridTemplateColumns="1fr 1em" gap={1}>
                        {referenceIds.map((referenceId) => (
                            <>
                                <p key={referenceId}>{referenceId}</p>
                                <CopyToClipboard text={referenceId}>
                                    <IconButton color="secondary">
                                        <ContentCopyIcon />
                                    </IconButton>
                                </CopyToClipboard>
                            </>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const MessageRight = (props: {
    message: string
    timestamp?: string | undefined
    displayName: string
}) => {
    const classes = useStyles({ isKnowledgeBase: false });
    const message = props.message ? props.message : 'no message'
    const displayName = props.displayName ? props.displayName : 'User'
    const timestamp = props.timestamp ? props.timestamp : ''
    return (
        <div className={classes.messageRowRight}>
            <div className={classes.messageRight}>
                <p className={classes.messageContent}>{message}</p>
                {timestamp && (
                    <div className={classes.messageTimeStampRight}>
                        {timestamp}
                    </div>
                )}
                <Box textAlign={'right'}>
                    <CopyToClipboard text={message}>
                        <IconButton color="primary">
                            <ContentCopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </Box>
            </div>
            <Avatar className={classes.bot}>
                {displayName
                    .split(' ')
                    .slice(0, 2)
                    .map((word) => word.charAt(0))
                    .join('')
                    .toUpperCase()}
            </Avatar>
        </div>
    )
}