import React, {FunctionComponent, useState} from 'react'
import {useMutation} from 'react-query'
import {connect} from 'react-redux'
import {Box} from '@mui/material'
import {Formik} from 'formik'
import * as yup from 'yup'
import {
    AUTH_TYPE,
    ENDPOINT_TYPE,
    ParentAssistApiKeyIntegration,
    ParentAssistInitExternalIntegration,
    ParentAssistResumeExternalIntegration,
    ParentAssistTenantMappingWrapper,
    ParentAssistTenantSetting,
    QUERY_ASSIST_INTEGRATION_MODE,
    TenantSetting,
} from '../../../../../interfaces/SettingType'
import {updateUserSetting} from '../../../../../actions/user'
import ErrorMessage from '../../../../../components/ErrorMessage'
import {ApiError} from '../../../../../interfaces/ErrorType'
import ButtonExt from '../../../../../components/ButtonExt'
import CheckboxExt from "../../../../../components/Checkbox";
import AutocompleteExt from "../../../../../components/Autocomplete";
import {isEmptyObject} from "../../../../../share/ObjectUtil";
import OAuth2SettingDetail, {
    convertOAuth2Setting,
    defaultOAuth2Integrations,
    isOAuth2ConfigFulfilled
} from "./auth/oauth2";
import AuthKeySettingDetail from "./auth/key";
import InitConversationIntegrationSettingDetail, {
    convertInitConversationIntegration,
    defaultInitConversationIntegrations,
    isInitConversationConfigFulfilled
} from "./integration/init";
import ResumeConversationIntegrationSettingDetail, {
    convertResumeConversationIntegrationSetting,
    defaultResumeConversationIntegrations,
    isResumeConversationConfigFulfilled
} from "./integration/resume";
import {
    authTypeOptions,
    endpointTypeOptions,
    queryAssistIntegrationModeOptions
} from "../../../../../share/SettingTypeConstant";

const parentAssistTenantSettingSchema = yup.object().shape({
    debug: yup.boolean().optional(),
    parentAssistMapping: yup.object().shape({
        enabled: yup.boolean().required(),
        endpointType: yup.string().required(),
        authType: yup.string().required(),
        // FIXME: Investigate why this is not working
        // authIntegrations: yup.object().when('authType', (authType, schema) => {
        //     if (authType === 'OAUTH2') {
        //         return schema.shape({
        //             tokenUrl: yup.string().required(),
        //             tokenMethod: yup.string().required(),
        //             tokenContentType: yup.string().required(),
        //             tokenCustomHeaders: yup.array().optional(),
        //             tokenRequestPayload: yup.array().optional(),
        //             tokenResponseAccessTokenField: yup.string().required(),
        //             tokenResponseRefreshTokenField: yup.string().optional(),
        //             tokenResponseAdditionalRefreshTokenFields: yup.array().optional(),
        //             refreshTokenUrl: yup.string().optional(),
        //         }).concat(
        //             yup.object().shape({
        //                 refreshTokenMethod: yup.string().required(),
        //                 refreshTokenContentType: yup.string().required(),
        //                 refreshTokenCustomHeaders: yup.array().optional(),
        //                 refreshTokenRequestFields: yup.array().optional(),
        //                 refreshTokenRequestPayload: yup.array().optional(),
        //                 refreshTokenResponseAccessTokenField: yup.string().required(),
        //             }).when('refreshTokenUrl', {
        //                 is: (url: string) => url !== '',
        //                 otherwise: yup.object().notRequired(),
        //             })
        //         );
        //     } else if (authType === 'API_KEY') {
        //         return schema.shape({
        //             key: yup.string().required(),
        //         });
        //     } else {
        //         return schema.notRequired(); // If authType is neither, this field is not required
        //     }
        // }),
        // initConversationIntegrations: yup.object().shape({
        //     initUrl: yup.string().required(),
        //     initMethod: yup.string().required(),
        //     initContentType: yup.string().required(),
        //     initCustomHeaders: yup.array().of(yup.object()),
        //     initRequestPayload: yup.array().of(yup.object()),
        //     initResponseIdField: yup.string().required(),
        //     initResponseReplyField: yup.string().required(),
        //     initResponseAdditionalFields: yup.array().of(yup.object()),
        // }),
        // resumeConversationIntegrations: yup.object().shape({
        //     resumeUrl: yup.string().required(),
        //     resumeMethod: yup.string().required(),
        //     resumeContentType: yup.string().required(),
        //     resumeCustomHeaders: yup.array().of(yup.object()),
        //     resumeRequestIdField: yup.string().required(),
        //     resumeRequestIdType: yup.string().required(),
        //     resumeRequestPayloadFields: yup.array().of(yup.object()),
        //     resumeResponseAnswerField: yup.string().required(),
        //     resumeResponseAdditionalFields: yup.array().of(yup.object()),
        // }),
    }),
})

const ParentAssistTenantSettingDetail: FunctionComponent<
    ParentAssistTenantMappingWrapper
    > = ({ userSetting, wrapper, callback }) => {

    const oAuth2Integrations= defaultOAuth2Integrations;

    const isValidParentAssistMapping = wrapper?.parentAssistMapping &&
        !isEmptyObject(wrapper?.parentAssistMapping?.authIntegrations) &&
        !isEmptyObject(wrapper?.parentAssistMapping?.initConversationIntegrations) &&
        !isEmptyObject(wrapper?.parentAssistMapping?.resumeConversationIntegrations);

    const [parentAssistTenantSetting, setParentAssistTenantSetting] =
        useState<ParentAssistTenantSetting>(
            isValidParentAssistMapping
                ? {
                    ...wrapper,
                    parentAssistMapping: {
                        ...wrapper.parentAssistMapping,
                    }
                } as ParentAssistTenantSetting
                : {
                    parentAssistMapping: {
                        enabled: false,
                        endpointType: ENDPOINT_TYPE.REST,
                        authType: AUTH_TYPE.OAUTH2,
                        authIntegrations: oAuth2Integrations,
                        queryAssistIntegrationMode: QUERY_ASSIST_INTEGRATION_MODE.DEFAULT,
                        initConversationIntegrations: defaultInitConversationIntegrations,
                        resumeConversationIntegrations: defaultResumeConversationIntegrations,
                    }
                }
        );

    /**
     * Mutate parent assist tenant setting update
     */
    const parentAssistTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        any
        >(updateUserSetting, {
        onSuccess: (data) => {
            callback(data)
        },
    })

    /**
     * Invoke an action to update the parent assist tenant setting
     * @param {*} e - event
     */
    const onSave = (values: ParentAssistTenantSetting) => {
        // NOTE: Enable this only for debugging purposes
        // console.log("Before Conversion:", values);

        // Convert the Map to an object
        const convertedValues = convertValues(values);

        // Validate the converted values
        const notFulfilled = !isFulfilled(convertedValues);
        if (notFulfilled) {
            return;
        }

        // NOTE: Enable this only for debugging purposes
        // console.log("After Conversion:", convertedValues); // Ensure the Map is now an object
        parentAssistTenantSettingUpdateMutation.mutate(convertedValues);
    }

    /**
     * Convert the Map to an object
     * @param {*} values - parent assist tenant setting
     */
    const convertValues = (values: ParentAssistTenantSetting) => {
        let authIntegrations;
        if (values.parentAssistMapping?.authType === AUTH_TYPE.OAUTH2) {
            authIntegrations = convertOAuth2Setting(values);
        } else {
            authIntegrations = values.parentAssistMapping?.authIntegrations;
        }

        return {
            ...values,
            parentAssistMapping: {
                ...values.parentAssistMapping,
                authIntegrations: authIntegrations,
                initConversationIntegrations: convertInitConversationIntegration(values),
                resumeConversationIntegrations: convertResumeConversationIntegrationSetting(values),
            }
        };
    }

    /**
     * Check if the parent assist tenant setting is fulfilled
     * @param convertedValues
     */
    const isFulfilled = (convertedValues: any) => {
        if (convertedValues.parentAssistMapping.authType === AUTH_TYPE.OAUTH2) {
            if (!isOAuth2ConfigFulfilled(convertedValues)) {
                return false;
            }
        }

        if (convertedValues.parentAssistMapping.initConversationIntegrations as ParentAssistInitExternalIntegration) {
            if (!isInitConversationConfigFulfilled(convertedValues)) {
                return false;
            }
        }

        if (convertedValues.parentAssistMapping.resumeConversationIntegrations as ParentAssistResumeExternalIntegration) {
            if (!isResumeConversationConfigFulfilled(convertedValues)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Page containing parent assist tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {parentAssistTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={parentAssistTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={parentAssistTenantSetting}
                validationSchema={parentAssistTenantSettingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box>
                            <Box
                                display="grid"
                                gap="30px"
                                mb="30px"
                                gridTemplateColumns="repeat(2, minmax(0,1fr))"
                            >
                                <CheckboxExt
                                    name="parentAssistMapping.enabled"
                                    label="Enabled"
                                    onBlurEvent={handleBlur}
                                    onChangeEvent={handleChange}
                                    value={
                                        values.parentAssistMapping?.enabled
                                    }
                                />

                                <AutocompleteExt
                                    name="parentAssistMapping.endpointType"
                                    multiSelection={false}
                                    label="Endpoint Type"
                                    selectedValue={
                                        values.parentAssistMapping?.endpointType
                                    }
                                    options={endpointTypeOptions}
                                    onBlurEvent={handleBlur}
                                    onSelect={(v) => {
                                        if (values.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping) {
                                            setParentAssistTenantSetting({
                                                ...parentAssistTenantSetting,
                                                parentAssistMapping: {
                                                    ...parentAssistTenantSetting.parentAssistMapping,
                                                    endpointType: v,
                                                },
                                            })
                                            values.parentAssistMapping.endpointType = v
                                        }
                                    }}
                                    disableUnselectAll={true}
                                    required={true}
                                />

                                <AutocompleteExt
                                    name="parentAssistMapping.authType"
                                    multiSelection={false}
                                    label="Auth Type"
                                    selectedValue={
                                        values.parentAssistMapping?.authType
                                    }
                                    options={authTypeOptions}
                                    onBlurEvent={handleBlur}
                                    onSelect={(v) => {
                                        if (values.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping) {
                                            const parentAssistAuthIntegrationChange = v === AUTH_TYPE.OAUTH2 ? oAuth2Integrations : {key: ''} as ParentAssistApiKeyIntegration

                                            setParentAssistTenantSetting({
                                                ...parentAssistTenantSetting,
                                                parentAssistMapping: {
                                                    ...parentAssistTenantSetting.parentAssistMapping,
                                                    authType: v,
                                                    authIntegrations: parentAssistAuthIntegrationChange,
                                                },
                                            })
                                            values.parentAssistMapping.authType = v
                                            values.parentAssistMapping.authIntegrations = parentAssistAuthIntegrationChange
                                        }
                                    }}
                                    disableUnselectAll={true}
                                    required={true}
                                />
                            </Box>

                            {values.parentAssistMapping?.authType === AUTH_TYPE.OAUTH2 && (
                                <OAuth2SettingDetail
                                    values={values}
                                    parentAssistTenantSetting={parentAssistTenantSetting}
                                    setParentAssistTenantSetting={setParentAssistTenantSetting}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            )}

                            {values.parentAssistMapping?.authType === AUTH_TYPE.API_KEY && (
                                <AuthKeySettingDetail
                                    values={values}
                                    parentAssistTenantSetting={parentAssistTenantSetting}
                                    setParentAssistTenantSetting={setParentAssistTenantSetting}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                />
                            )}

                            <AutocompleteExt
                                style={{ marginBottom: `30px` }}
                                name="parentAssistMapping.queryAssistIntegrationMode"
                                multiSelection={false}
                                label="Query Assist Integration Mode"
                                selectedValue={
                                    values.parentAssistMapping?.queryAssistIntegrationMode
                                }
                                options={queryAssistIntegrationModeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    if (values.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping) {
                                        setParentAssistTenantSetting({
                                            ...parentAssistTenantSetting,
                                            parentAssistMapping: {
                                                ...parentAssistTenantSetting.parentAssistMapping,
                                                queryAssistIntegrationMode: v,
                                            },
                                        })
                                        values.parentAssistMapping.queryAssistIntegrationMode = v
                                    }
                                }}
                                disableUnselectAll={true}
                                required={true}
                            />

                            <InitConversationIntegrationSettingDetail
                                values={values}
                                parentAssistTenantSetting={parentAssistTenantSetting}
                                setParentAssistTenantSetting={setParentAssistTenantSetting}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />

                            <ResumeConversationIntegrationSettingDetail
                                values={values}
                                parentAssistTenantSetting={parentAssistTenantSetting}
                                setParentAssistTenantSetting={setParentAssistTenantSetting}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                            />

                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    parentAssistTenantSettingUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    parentAssistTenantSettingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.userSettingPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the country list through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ParentAssistTenantSettingDetail)