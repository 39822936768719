import store from '../redux/store'
import {
    UNSET_TENANT,
    REMOVE_USER, UNSET_USER,
} from '../redux/actionType'
import { fetchAllCountries, fetchAllLocales } from './localization'

export const loadCurrentConfigurations = () => {
    return async () => {
        if (store.getState().user && store.getState().user.user) {
            try {
                await Promise.all([
                    fetchAllCountries(),
                    fetchAllLocales()
                ]);
            } catch (error) {
                console.log(error);
            }
        }
    };
};

export const removeAllConfigurations = () => {
    store.dispatch({
        type: UNSET_USER,
    })

    store.dispatch({
        type: UNSET_TENANT,
    })

    store.dispatch({
        type: REMOVE_USER,
    })
}
