import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import {Formik} from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {connect} from "react-redux";
import {
    DefaultContentReferenceMapping, DefaultContentReferenceMappingRequest,
    DefaultContentReferenceMappingWrapper, ReferenceItem
} from "../../../../../../../interfaces/ContentAssistType";
import {
    createDefaultContentReferenceMapping, fetchContentTypes,
    updateDefaultContentReferenceMapping
} from "../../../../../../../actions/contentAssists";
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import {useAuthQueryWithQueryFunction} from "../../../../../../../extensions/UseAuthQuery";
import TagInputExt from "../../../../../../../components/TagInput";
import {ApiError} from "../../../../../../../interfaces/ErrorType";
import ErrorMessage from "../../../../../../../components/ErrorMessage";
import Header from "../../../../../../../components/Header";
import ButtonExt from "../../../../../../../components/ButtonExt";
import AccordionExt from "../../../../../../../components/AccordionExt";

const defaultContentReferenceMappingSchema = yup.object().shape({
    contentTypeId: yup.string().required('required'),
    eligibleRangeStart: yup.number().min(1).required('required'),
    eligibleRangeEnd: yup.number().min(1).required('required'),
    referenceItems: yup.array<ReferenceItem>(yup.object().shape(
        {
            text: yup.string().required('required'),
            urls: yup.array().min(1).required('required')
        }
    )).min(1).required('required'),
})

const DefaultContentReferenceMappingDetail: FunctionComponent<DefaultContentReferenceMappingWrapper> = ({
                                                            userSetting,
                                                            isNew = false,
                                                            wrapper,
                                                        }) => {
    const navigate = useNavigate()
    const [defaultContentReferenceMapping, setDefaultContentReferenceMapping] = useState<DefaultContentReferenceMappingRequest>(
        wrapper
            ? {
                ...wrapper,
                contentTypeId: wrapper.contentType!!.id!!,
            }
            : {
                id: undefined,
                contentTypeId: undefined,
                eligibleRangeStart: undefined,
                eligibleRangeEnd: undefined,
                referenceItems: []
            }
    )

    /**
     * Mutate default content reference mapping create
     */
    const defaultContentReferenceMappingCreateMutation = useMutation<DefaultContentReferenceMapping, ApiError, DefaultContentReferenceMappingRequest>(
        createDefaultContentReferenceMapping,
        {
            onSuccess: (data) => {
                setDefaultContentReferenceMapping({
                    ...data,
                    contentTypeId: data.contentType!!.id!!
                })
            },
        }
    )

    /**
     * Mutate default content reference mapping update
     */
    const defaultContentReferenceMappingUpdateMutation = useMutation<DefaultContentReferenceMapping, ApiError, DefaultContentReferenceMappingRequest>(
        updateDefaultContentReferenceMapping,
        {
            onSuccess: (data) => {
                setDefaultContentReferenceMapping({
                    ...data,
                    contentTypeId: data.contentType!!.id!!
                })
            },
        }
    )

    /**
     * Fetch types list
     * */
    const contentTypesQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentTypes', fetchContentTypes, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTypeOptions = contentTypesQuery.data?.map((contentType) => {
        return {
            value: contentType.id,
            label: contentType.type
        }
    })

    if (defaultContentReferenceMappingCreateMutation.isSuccess) {
        navigate(`/default/content/reference/mapping`)
    }

    /**
     * Invoke an action to create/ update default content reference mapping
     * @param {*} e - event
     */
    const onSave = (values: DefaultContentReferenceMappingRequest) => {
        if (!values.id) {
            defaultContentReferenceMappingCreateMutation.mutate(values)
        } else {
            defaultContentReferenceMappingUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing locale details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/default/content/reference/mapping`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Default Content Reference Mapping" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {defaultContentReferenceMappingCreateMutation.isError && (
                    <ErrorMessage error={defaultContentReferenceMappingCreateMutation.error} />
                )}

                {defaultContentReferenceMappingUpdateMutation.isError && (
                    <ErrorMessage error={defaultContentReferenceMappingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={defaultContentReferenceMapping}
                validationSchema={defaultContentReferenceMappingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            mb="30px"
                            gridTemplateColumns="repeat(3, minmax(0,1fr))"
                        >
                            {defaultContentReferenceMapping.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range Start"
                                value={values.eligibleRangeStart}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeStart"
                                error={!!touched.eligibleRangeStart && !!errors.eligibleRangeStart}
                                helperText={touched.eligibleRangeStart && errors.eligibleRangeStart}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range End"
                                value={values.eligibleRangeEnd}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeEnd"
                                error={!!touched.eligibleRangeEnd && !!errors.eligibleRangeEnd}
                                helperText={touched.eligibleRangeEnd && errors.eligibleRangeEnd}
                            />
                        </Box>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(1, minmax(0,1fr))"
                        >
                            <AutocompleteExt
                                name="contentTypeId"
                                multiSelection={false}
                                disableUnselectAll={true}
                                label="Content Type"
                                selectedValue={values?.contentTypeId}
                                options={availableTypeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setDefaultContentReferenceMapping({
                                        ...defaultContentReferenceMapping,
                                        contentTypeId: v
                                    })
                                    values.contentTypeId = v
                                }}
                                error={!!touched.contentTypeId && !!errors.contentTypeId}
                                helperText={touched.contentTypeId && errors.contentTypeId}
                            />

                            {values.referenceItems.map((item, index) => {
                                return (
                                    <Box>
                                        <AccordionExt
                                            name={`${index}`}
                                            title={item.text ? item.text : ``}
                                            errorMessage={
                                                (!item.text && item.urls?.length === 0) ? 'Text and at least one URL are required' :
                                                (!item.text && item.urls?.length !== 0) ? 'Text is required' :
                                                (item.text && item.urls?.length === 0) ? 'Specify at least one URL' : ''
                                            }
                                            error={!item.text || item.urls?.length === 0}
                                            component={
                                                <Box display="grid"
                                                     gap="30px"
                                                     gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                                >
                                                    <TextField
                                                        variant="filled"
                                                        type="text"
                                                        label="Reference text"
                                                        value={item.text}
                                                        onChange={(e) => {
                                                            setDefaultContentReferenceMapping({
                                                                ...defaultContentReferenceMapping,
                                                                referenceItems: [
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(0, index),
                                                                    {
                                                                        ...defaultContentReferenceMapping.referenceItems[index],
                                                                        text: e.target.value
                                                                    },
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(index + 1)
                                                                ]
                                                            })
                                                            values.referenceItems[index].text = e.target.value
                                                        }}
                                                        name="referenceText"
                                                    />
                                                    <TagInputExt
                                                        name="referenceUrls"
                                                        label="Reference URLs"
                                                        values={item.urls.map((each) => {
                                                            return {id: each, text: each}
                                                        })}
                                                        onItemAdd={(v) => {
                                                            setDefaultContentReferenceMapping({
                                                                ...defaultContentReferenceMapping,
                                                                referenceItems: [
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(0, index),
                                                                    {
                                                                        ...defaultContentReferenceMapping.referenceItems[index],
                                                                        urls: [...defaultContentReferenceMapping.referenceItems[index].urls, v.id]
                                                                    },
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(index + 1)
                                                                ]
                                                            })
                                                            values.referenceItems[index].urls.push(v.id)
                                                        }}
                                                        onItemDelete={(index) => {
                                                            setDefaultContentReferenceMapping({
                                                                ...defaultContentReferenceMapping,
                                                                referenceItems: [
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(0, index),
                                                                    {
                                                                        ...defaultContentReferenceMapping.referenceItems[index],
                                                                        urls: defaultContentReferenceMapping.referenceItems[index].urls.filter((_, i) => i !== index)
                                                                    },
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(index + 1)
                                                                ]
                                                            })
                                                            values.referenceItems[index].urls.splice(index, 1)
                                                        }}
                                                        allowDragDrop={true}
                                                        onItemDrag={(v, currentIndex, dragIndex) => {
                                                            const cloneUrls = [...values.referenceItems[index].urls]
                                                            cloneUrls.splice(currentIndex, 1)
                                                            cloneUrls.splice(dragIndex, 0, v.id)
                                                            setDefaultContentReferenceMapping({
                                                                ...defaultContentReferenceMapping,
                                                                referenceItems: [
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(0, index),
                                                                    {
                                                                        ...defaultContentReferenceMapping.referenceItems[index],
                                                                        urls: cloneUrls
                                                                    },
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(index + 1)
                                                                ]
                                                            })
                                                            values.referenceItems[index].urls = cloneUrls
                                                        }}
                                                    />
                                                </Box>
                                            }
                                            actionRenderer={
                                                <Box>
                                                    <ButtonExt
                                                        style={{padding: `2px`, minWidth: `2px`}}
                                                        type="button"
                                                        value=""
                                                        icon={<RemoveIcon />}
                                                        onClickEvent={() => {
                                                            setDefaultContentReferenceMapping({
                                                                ...defaultContentReferenceMapping,
                                                                referenceItems: [
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(0, index),
                                                                    ...defaultContentReferenceMapping.referenceItems.slice(index + 1)
                                                                ]
                                                            })
                                                            values.referenceItems.splice(index, 1)
                                                        }}
                                                        disabled={values.referenceItems.length === 1}
                                                    />
                                                </Box>
                                            }
                                        />
                                    </Box>
                                );
                            })}

                            <Box style={{padding: `0`}} textAlign='left'>
                                <ButtonExt
                                    style={{padding: `2px`, minWidth: `2px`}}
                                    type="button"
                                    value=""
                                    icon={<AddIcon />}
                                    onClickEvent={() => {
                                        setDefaultContentReferenceMapping({
                                            ...defaultContentReferenceMapping,
                                            referenceItems: [
                                                ...defaultContentReferenceMapping.referenceItems,
                                                {
                                                    text: '',
                                                    urls: []
                                                }
                                            ]
                                        })
                                        values.referenceItems.push({
                                            text: '',
                                            urls: []
                                        })
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    defaultContentReferenceMappingCreateMutation.isLoading ||
                                    defaultContentReferenceMappingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    defaultContentReferenceMappingCreateMutation.isLoading ||
                                    defaultContentReferenceMappingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.contentAssistPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(DefaultContentReferenceMappingDetail)