import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {
    LOAD_COUNTRIES_LIST,
    LOAD_LOCALES_LIST,
    UPDATE_COUNTRIES,
    UPDATE_LOCALES, UPDATE_TABLE_DATA_ITEM,
} from '../redux/actionType'

/**
 * An action to fetch all countries
 */
export async function fetchAllCountries() {
    if (!store.getState().userSetting?.data?.administratorRolePermission?.localizationPermission?.enabled) {
        return []
    }

    let getCountryListUrl = '/admin/country?page=1&size=100000'

    const { data } = await SecureAxios.get(getCountryListUrl)

    store.dispatch({
        type: LOAD_COUNTRIES_LIST,
        payload: data.data.content,
    })

    return data.data.content
}

/**
 * An action to fetch all locales
 */
export async function fetchAllLocales() {
    if (!store.getState().userSetting?.data?.administratorRolePermission?.localizationPermission?.enabled) {
        return []
    }

    let getLocaleListUrl = '/admin/locale?page=1&size=100000'

    const { data } = await SecureAxios.get(getLocaleListUrl)

    store.dispatch({
        type: LOAD_LOCALES_LIST,
        payload: data.data.content,
    })

    return data.data.content
}

/**
 * An action to fetch locale from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchLocales = async (searchOptions) => {
    let localeUrl = `/admin/locale?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        localeUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(localeUrl)
    return data
}

/**
 * An action to create the current locale
 */
export async function createLocale(locale) {
    const { data } = await SecureAxios.post(`/admin/locale`, locale, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_LOCALES,
        payload: { data: data.data },
    })

    return data.data
}

/**
 * An action to update the locale
 */
export async function updateLocale(locale) {
    const { data } = await SecureAxios.put(
        `/admin/locale/${locale.id}/update`,
        locale,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_LOCALES,
        payload: { keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch countries from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchCountries = async (searchOptions) => {
    let countryUrl = `/admin/country?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        countryUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(countryUrl)
    return data
}

/**
 * An action to create the current country
 */
export async function createCountry(country) {
    const requestBody = {
        ...country,
        localeIds: country.locales.map((locale) => locale.id),
    }
    const { data } = await SecureAxios.post(`/admin/country`, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    store.dispatch({
        type: UPDATE_COUNTRIES,
        payload: { data: data.data },
    })

    return data.data
}

/**
 * An action to update the country
 */
export async function updateCountry(country) {
    const requestBody = {
        ...country,
        localeIds: country.locales.map((locale) => locale.id),
    }
    const { data } = await SecureAxios.put(
        `/admin/country/${country.id}/update`,
        requestBody,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_COUNTRIES,
        payload: { keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to fetch aws locale mapping from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchAwsLocaleMappings = async (searchOptions) => {
    let awsLocaleMappingUrl = `/admin/aws/locale/mapping?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        awsLocaleMappingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(awsLocaleMappingUrl)
    return data
}

/**
 * An action to fetch google locale mapping from neuro api
 *
 * No pagination will be handled at this stage
 */
export const fetchGoogleLocaleMappings = async (searchOptions) => {
    let googleLocaleMappingUrl = `/admin/google/locale/mapping?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    if (
        searchOptions.sortOptions?.sortField &&
        searchOptions.sortOptions?.sortOrder
    ) {
        googleLocaleMappingUrl += `&sort=${searchOptions.sortOptions.sortField}&sortDir=${searchOptions.sortOptions.sortOrder}`
    }
    const { data } = await SecureAxios.get(googleLocaleMappingUrl)
    return data
}

/**
 * An action to update the locale
 */
export async function updateAwsLocaleMapping(awsLocaleMapping) {
    const { data } = await SecureAxios.put(
        `/admin/aws/locale/mapping/${awsLocaleMapping.id}/update`,
        awsLocaleMapping,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'aws-locale-mappings', keyField: 'id', data: data.data },
    })

    return data.data
}

/**
 * An action to update the locale
 */
export async function updateGoogleLocaleMapping(googleLocaleMapping) {
    const { data } = await SecureAxios.put(
        `/admin/google/locale/mapping/${googleLocaleMapping.id}/update`,
        googleLocaleMapping,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    store.dispatch({
        type: UPDATE_TABLE_DATA_ITEM,
        payload: { key: 'google-locale-mappings', keyField: 'id', data: data.data },
    })

    return data.data
}
