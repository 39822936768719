import React, {FunctionComponent, useMemo, useState} from 'react'
import { v4 as uuidv4 } from 'uuid'
import {Box, FormHelperText, IconButton, InputLabel, TextField, useTheme} from '@mui/material'
import { tokens } from '../theme'
import {Add, Delete} from "@mui/icons-material";
import PasswordInput from "./PasswordInput";
import {KeyPair} from "../interfaces/SettingType";

const MultiKeyValueInput: FunctionComponent<MultiKeyValueInputProp> = ({
                                                          style,
                                                          name,
                                                          values,
                                                          label,
                                                          required = false,
                                                          editable = true,
                                                          onBlurEvent,
                                                          onChangeEvent,
                                                          error,
                                                          encrypted,
                                                          helperText,
                                                      }) => {
    const uuid = useMemo(() => uuidv4(), [])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [keyValue, setKeyValue] = useState<KeyPair>({ key: '', value: '' })

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalItemAdd = async (v: KeyPair) => {
        if (onChangeEvent) {
            if (values) {
                onChangeEvent([...values, v])
            } else {
                onChangeEvent([v])
            }

            setKeyValue({
                key: '',
                value: '',
            })
        }
    }

    const onInternalItemUpdate = async (v: KeyPair, index: number) => {
        if (onChangeEvent) {
            if (values) {
                values[index] = v
                onChangeEvent([...values])
            }
        }
    }

    const onInternalItemDelete = async (index: number) => {
        if (onChangeEvent) {
            if (values) {
                values.splice(index, 1)
                onChangeEvent([...values])
            }
        }
    }

    return (
        <FormHelperText style={style} onBlur={onInternalBlurEvent} error={error}>
            <Box sx={{
                border: `1px solid ${colors.grey[400]}`, // Light gray border
                borderRadius: "8px",
                padding: "10px",
                marginLeft: `10px`,
            }} key={uuid}>
                {label && (
                    <InputLabel
                        style={{ fontSize: `x-small`, paddingBottom: `10px`, marginBottom: `15px` }}
                    >
                        {label}
                    </InputLabel>
                )}

                {values && values.length > 0 && (
                    <Box display="grid" gridTemplateColumns="1fr 2fr 10em" gap={1}>
                        {values.map((value: KeyPair, index: number) => (
                            <React.Fragment key={`${value.key}-${index}`}>
                                <TextField
                                    style={{ marginBottom: `15px` }}
                                    label='Key'
                                    variant="outlined"
                                    fullWidth
                                    value={value.key}
                                />
                                {encrypted ? (
                                    <PasswordInput
                                        style={{ marginBottom: `15px` }}
                                        name={value.key}
                                        label='Value'
                                        value={value.value}
                                        onChangeEvent={(e) => onInternalItemUpdate({ key: value.key, value: e.target.value }, index)}
                                        error={error || value.value.trim().length === 0}
                                        helperText={helperText || (value.value.trim().length === 0 ? 'This field is required' : '')}
                                        required={true}
                                    />
                                ): (
                                    <TextField
                                        style={{ marginBottom: `15px` }}
                                        type='text'
                                        label={value.key}
                                        variant="outlined"
                                        fullWidth
                                        value={value.value}
                                        onChange={(e) => onInternalItemUpdate({ key: value.key, value: e.target.value }, index)}
                                        error={error || value.value.trim().length === 0}
                                        helperText={helperText || (value.value.trim().length === 0 ? 'This field is required' : '')}
                                        required={true}
                                    />
                                )}
                                <Box mb="15px">
                                    <IconButton onClick={() => onInternalItemDelete(index)}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </React.Fragment>
                        ))}
                    </Box>
                )}

                <Box display="grid" gridTemplateColumns="1fr 2fr 10em" gap={1} mb="15px">
                    <TextField
                        label='Key'
                        variant="outlined"
                        fullWidth
                        value={keyValue.key}
                        onChange={(e) => {
                            setKeyValue({
                                ...keyValue,
                                key: e.target.value
                            })
                        }}
                        error={error}
                        helperText={helperText}
                        required={required && values && values.length === 0}
                    />
                    {encrypted ? (
                        <PasswordInput
                            name={keyValue.key}
                            label='Value'
                            value={keyValue.value}
                            onChangeEvent={(e) => {
                                setKeyValue({
                                    ...keyValue,
                                    value: e.target.value
                                })
                            }}
                            error={error}
                            helperText={helperText}
                            required={required && values && values.length === 0}
                        />
                    ): (
                        <TextField
                            type='text'
                            label='Value'
                            variant="outlined"
                            fullWidth
                            value={keyValue.value}
                            onChange={(e) => {
                                setKeyValue({
                                    ...keyValue,
                                    value: e.target.value
                                })
                            }}
                            error={error}
                            helperText={helperText}
                            required={required && values && values.length === 0}
                        />
                    )}
                    <Box>
                        <IconButton onClick={() => onInternalItemAdd({
                            key: keyValue.key,
                            value: keyValue.value,
                        })} disabled={!keyValue.key || !keyValue.value}>
                            <Add />
                        </IconButton>
                    </Box>
                </Box>
            </Box>

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface MultiKeyValueInputProp {
    style?: React.CSSProperties
    name: string | undefined
    values: KeyPair[] | undefined
    label?: string
    required?: boolean // TODO: this is not implemented yet
    editable?: boolean
    onBlurEvent?: (event: any) => void // Handle event upon onblur
    onChangeEvent?: (values: KeyPair[]) => void
    encrypted?: boolean
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default MultiKeyValueInput