import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    GoogleLocaleMapping, GoogleLocaleMappingWrapper,
    Locale,
} from '../../../../../../../interfaces/LocalizationType'
import {
    updateGoogleLocaleMapping,
} from '../../../../../../../actions/localization'
import { ApiError } from '../../../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../../../components/ErrorMessage'
import ButtonExt from '../../../../../../../components/ButtonExt'
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import {connect} from "react-redux";

const googleLocaleMappingSchema = yup.object().shape({
    localeId: yup.string().required('required'),
    languageCode: yup.string().min(2).max(10).required('required'),
})

const GoogleLocaleMappingDetail: FunctionComponent<GoogleLocaleMappingWrapper> = ({
                                                                                localeList,
                                                                                userSetting,
                                                                                isNew = false,
                                                                                wrapper,
                                                                            }) => {
    const [googleLocaleMapping, setGoogleLocaleMapping] = useState<GoogleLocaleMapping>({
        ...wrapper!!,
        localeId: wrapper!!.locale.id!!,
    })

    const localeOptions = localeList?.map((locale: Locale) => {
        return {
            label: locale.name,
            value: locale.id,
        }
    })

    /**
     * Mutate locale mapping update
     */
    const googleLocaleMappingUpdateMutation = useMutation<GoogleLocaleMapping, ApiError, GoogleLocaleMapping>(
        updateGoogleLocaleMapping,
        {
            onSuccess: (data) => {
                setGoogleLocaleMapping(data)
            },
        }
    )

    /**
     * Invoke an action to update locale
     * @param {*} values - values from formik
     */
    const onSave = (values: GoogleLocaleMapping) => {
        googleLocaleMappingUpdateMutation.mutate(values)
    }

    /**
     * Page containing locale mapping details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {googleLocaleMappingUpdateMutation.isError && (
                    <ErrorMessage error={googleLocaleMappingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={googleLocaleMapping}
                validationSchema={googleLocaleMappingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {googleLocaleMapping.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <AutocompleteExt
                                name="localeId"
                                multiSelection={false}
                                label="Locale Id"
                                selectedValue={values.locale.id}
                                options={localeOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setGoogleLocaleMapping({
                                        ...googleLocaleMapping,
                                        localeId: v,
                                    })
                                    values.localeId = v
                                }}
                                required={true}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Language Code Mapping"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.languageCode}
                                name="languageCode"
                                error={!!touched.languageCode && !!errors.languageCode}
                                helperText={touched.languageCode && errors.languageCode}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    googleLocaleMappingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    googleLocaleMappingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.localizationPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}


/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { localeList: state.localeList.data, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(GoogleLocaleMappingDetail)