import * as React from 'react';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button, InputLabel, Box, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {tokens} from "../theme";

interface ImageProp {
    style?: React.CSSProperties | undefined
    className?: string;
    label?: string;
    url?: string;
    name?: string;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;

const StyledImage = styled('img')`
  width: 100%;
  height: auto;
`;

const ExpandableImage: React.FC<ImageProp> = ({
                                                  style,
                                                  className,
                                                  url,
                                                  label,
                                                  name,
                                              }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            {label && (
                <InputLabel
                    style={{ fontSize: `x-small`, paddingBottom: `10px` }}
                >
                    {label}
                </InputLabel>
            )}

            {!url && (
                <ImageNotSupportedIcon
                    style={style}
                    sx={{ color: colors.greenAccent[500], fontSize: '8rem' }}
                />
            )}

            {url && (
                <IconButton onClick={handleClickOpen}>
                    <img
                        style={style}
                        src={url}
                        alt={name}
                        className={className}
                    />
                </IconButton>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <StyledDialogContent>
                    <StyledImage src={url} alt={name} />
                </StyledDialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ExpandableImage;