import { Tenant } from './TenantType'
import {Country, Locale} from './LocalizationType'
import { CallbackInterface } from './DefaultType'
import {FeatureToggle} from "./FeatureToggleType";
import {AuthUser} from "./UserType";

export interface TenantSettingWrapper {
    switchTenant?: Tenant | undefined
    switchUser?: AuthUser | undefined
}

export interface TenantSetting {
    debugMode: boolean
    preferredTranslationProviderType?: string | undefined
    preferCustomTranslationModel?: string | undefined
    countryMapping?: CountryMapping | undefined
    contentAssistMapping?: ContentAssistTenantMapping | undefined
    parentAssistMapping?: ParentAssistTenantMapping | undefined
    administratorRolePermission?: AdministratorRolePermissionTenantMapping | undefined
}

export interface DefaultTenantSettingWrapper
    extends CallbackInterface<TenantSetting> {
    countryList?: Country[] | undefined
    wrapper?: DefaultTenantSetting | undefined
    userSetting?: TenantSetting | undefined
}

export interface DefaultTenantSetting {
    debugMode: boolean
    preferredTranslationProviderType?: string | undefined
    preferCustomTranslationModel?: string | undefined
    countryMapping?: CountryMapping | undefined
}

interface CountryMapping {
    defaultLocaleId?: string | undefined
    countryId?: string | undefined
    country?: Country
}

export interface ContentAssistTenantMappingWrapper
    extends CallbackInterface<TenantSetting> {
    wrapper?: ContentAssistTenantSetting | undefined
    userSetting?: TenantSetting | undefined
    localeList?: Locale[] | undefined
}

export interface ContentAssistTenantSetting {
    contentAssistMapping?: ContentAssistTenantMapping | undefined
    userSetting?: TenantSetting | undefined
}

export interface ParentAssistTenantMappingWrapper
    extends CallbackInterface<TenantSetting> {
    wrapper?: ParentAssistTenantSetting | undefined
    userSetting?: TenantSetting | undefined
}

export interface ParentAssistTenantSetting {
    parentAssistMapping?: ParentAssistTenantMapping | undefined
    userSetting?: TenantSetting | undefined
}

export interface AdministratorRolePermissionTenantSettingWrapper
    extends CallbackInterface<TenantSetting> {
    wrapper?: AdministratorRolePermissionTenantSetting | undefined
    userSetting?: TenantSetting | undefined
}

export interface AdministratorRolePermissionTenantSetting {
    administratorRolePermission?: AdministratorRolePermissionTenantMapping | undefined
}

export interface AdministratorRolePermissionTenantMapping {
    contentAssistPermission?: AdministratorRolePermission | undefined
    contentAssistRecommendationPermission?: AdministratorRolePermission | undefined
    parentAssistPermission?: AdministratorRolePermission | undefined
    tenantPermission?: AdministratorRolePermission | undefined
    serverConfigPermission?: AdministratorRolePermission | undefined
    localizationPermission?: AdministratorRolePermission | undefined
    userSettingPermission?: AdministratorRolePermission | undefined
    accessControlPermission?: AdministratorRolePermission | undefined
    superAdmin?: boolean | undefined
}

export interface AdministratorRolePermission {
    enabled: boolean
    editable: boolean
}

export interface ContentAssistTenantMapping extends FeatureToggle {
    mediaEnabled: boolean
    recommendationEnabled: boolean
    supportedMediaTypes: string[]
    localizations: KeyPairs[]
    dynamicMongoDatabaseMapping?: DynamicMongoDatabaseMapping | undefined
}

export interface ParentAssistTenantMapping extends FeatureToggle {
    endpointType: ENDPOINT_TYPE
    authType: AUTH_TYPE
    authIntegrations?: ParentAssistAuthIntegration | undefined
    queryAssistIntegrationMode: QUERY_ASSIST_INTEGRATION_MODE
    initConversationIntegrations?: ParentAssistExternalIntegration | undefined
    resumeConversationIntegrations?: ParentAssistExternalIntegration | undefined
}

export interface ParentAssistAuthIntegration {
}

export enum QUERY_ASSIST_INTEGRATION_MODE {
    DEFAULT = 'DEFAULT',
}

export interface ParentAssistExternalIntegration {
}

export interface ParentAssistOAuth2Integration extends ParentAssistAuthIntegration {
    tokenUrl: string
    tokenMethod: METHOD
    tokenContentType: CONTENT_TYPE
    tokenCustomHeaders: Map<string, string>
    tokenRequestPayload: Map<string, string>
    tokenResponseAccessTokenField: string
    tokenResponseRefreshTokenField?: string | undefined
    tokenResponseAdditionalRefreshTokenFields: string[]
    refreshTokenUrl: string
    refreshTokenMethod: METHOD
    refreshTokenContentType: CONTENT_TYPE
    refreshTokenCustomHeaders: Map<string, string>
    refreshTokenRequestFields: string[]
    refreshTokenRequestPayload: Map<string, string>
    refreshTokenResponseAccessTokenField: string
}

export interface ParentAssistApiKeyIntegration extends ParentAssistAuthIntegration {
    key: string
}

export interface ParentAssistInitExternalIntegration extends ParentAssistExternalIntegration {
    initUrl: string
    initMethod: METHOD
    initContentType: CONTENT_TYPE
    initCustomHeaders: Map<string, string>
    initRequestPayload: Map<string, string>
    initResponseIdField: string
    initResponseReplyField: string
    initResponseAdditionalFields: string[]
}

export interface ParentAssistResumeExternalIntegration extends ParentAssistExternalIntegration {
    resumeUrl: string
    resumeMethod: METHOD
    resumeContentType: CONTENT_TYPE
    resumeCustomHeaders: Map<string, string>
    resumeRequestIdField: string
    resumeRequestIdType: PARAM_TYPE
    resumeRequestPayloadFields: string[]
    resumeRequestDefaultPayloads: Map<string, string>
    resumeResponseAnswerField: string
    resumeResponseAdditionalFields: string[]
    resumeAsync: boolean
    resumeAsyncPreference: ASYNC_PREFERENCE
    resumeRedirectUrl: string
    resumeRedirectUrlAuthorizationKey: string
}

export enum ENDPOINT_TYPE {
    REST = 'REST'
}

export enum TYPE {
    INIT = 'INIT',
    RESUME = 'RESUME'
}

export enum AUTH_TYPE {
    API_KEY = 'API_KEY',
    OAUTH2 = 'OAUTH2'
}

export enum CONTENT_TYPE {
    APPLICATION_JSON = 'APPLICATION_JSON',
    APPLICATION_FORM_URLENCODED = 'APPLICATION_FORM_URLENCODED'
}

export enum METHOD {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export enum PARAM_TYPE {
    PATH = 'PATH',
    QUERY = 'QUERY',
    BODY = 'BODY',
    HEADER = 'HEADER'
}

export enum ASYNC_PREFERENCE {
    STATUS = 'STATUS',
    REDIRECT_URL = 'REDIRECT_URL'
}

export interface DynamicMongoDatabaseMapping {
    host: string
    username: string
    password: string
    database: string
    autoIndexCreation: boolean
    retryWrites: boolean
    retryReads: boolean
    connectionPoolSettingsMaxSize: number
    connectionPoolSettingsMinSize: number
    connectionPoolSettingsMaxConnectionLifeTimeInSeconds: number
    connectionPoolSettingsMaxConnectionIdleTimeInSeconds: number
    connectionPoolSettingsMaxWaitTimeInSeconds: number
    socketSettingsReadTimeoutInSeconds: number
    socketSettingsConnectTimeoutInSeconds: number
}

export interface KeyPair {
    key: string
    value: string
}

export interface KeyPairs {
    key: string
    values: string[]
}
