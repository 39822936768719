import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import {Box, InputLabel} from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
    AdministratorRolePermissionTenantSetting,
    AdministratorRolePermissionTenantSettingWrapper,
    TenantSetting,
} from '../../../../../../interfaces/SettingType'
import { updateUserSetting } from '../../../../../../actions/user'
import ErrorMessage from '../../../../../../components/ErrorMessage'
import { ApiError } from '../../../../../../interfaces/ErrorType'
import ButtonExt from '../../../../../../components/ButtonExt'
import SwitchExt from "../../../../../../components/Switch";
import {connect} from "react-redux";

const administratorRolePermissionTenantSettingSchema = yup.object().shape({
    administratorRolePermission: yup.object().shape({
        contentAssistPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        contentAssistRecommendationPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        tenantPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        serverConfigPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        localizationPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        userSettingPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
        accessControlPermission: yup.object().shape({
            enabled: yup.boolean().required(),
            editable: yup.boolean().required(),
        }),
    }),
})

const AdministratorRolePermissionTenantSettingDetail: FunctionComponent<
    AdministratorRolePermissionTenantSettingWrapper
    > = ({ userSetting, wrapper, callback }) => {
    const [administratorRolePermissionTenantSetting, setAdministratorRolePermissionTenantSetting] =
        useState<AdministratorRolePermissionTenantSetting>(
            wrapper
                ? wrapper
                : {
                    administratorRolePermission: {
                        contentAssistPermission: {
                            enabled: true,
                            editable: true,
                        },
                        contentAssistRecommendationPermission: {
                            enabled: true,
                            editable: true,
                        },
                        tenantPermission: {
                            enabled: true,
                            editable: true,
                        },
                        serverConfigPermission: {
                            enabled: true,
                            editable: true,
                        },
                        localizationPermission: {
                            enabled: true,
                            editable: true,
                        },
                        userSettingPermission: {
                            enabled: true,
                            editable: true,
                        },
                        accessControlPermission: {
                            enabled: true,
                            editable: true,
                        },
                        superAdmin: false,
                    }
                }
        )

    /**
     * Mutate administrator role permission tenant setting update
     */
    const administratorRolePermissionTenantSettingUpdateMutation = useMutation<
        TenantSetting,
        ApiError,
        AdministratorRolePermissionTenantSetting
        >(updateUserSetting, {
        onSuccess: (data) => {
            callback(data)
        },
    })

    /**
     * Invoke an action to update administrator role permission tenant setting
     * @param {*} e - event
     */
    const onSave = (values: AdministratorRolePermissionTenantSetting) => {
        administratorRolePermissionTenantSettingUpdateMutation.mutate(values)
    }

    /**
     * Page containing default tenant setting details
     */
    return (
        <Box m="20px">
            <Box style={{ marginBottom: `2em` }}>
                {administratorRolePermissionTenantSettingUpdateMutation.isError && (
                    <ErrorMessage
                        error={administratorRolePermissionTenantSettingUpdateMutation.error}
                    />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={administratorRolePermissionTenantSetting}
                validationSchema={administratorRolePermissionTenantSettingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="5fr 1fr 1fr"
                        >
                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Content Assist
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.contentAssistPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            contentAssistPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.contentAssistPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.contentAssistPermission!!.editable : false,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        contentAssistPermission: {
                                            ...values.administratorRolePermission?.contentAssistPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.contentAssistPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />

                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.contentAssistPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            contentAssistPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.contentAssistPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.contentAssistPermission!!.enabled,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        contentAssistPermission: {
                                            ...values.administratorRolePermission?.contentAssistPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.contentAssistPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Content Assist Recommendation
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.contentAssistRecommendationPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            contentAssistRecommendationPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.contentAssistRecommendationPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.contentAssistRecommendationPermission!!.editable : false,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        contentAssistRecommendationPermission: {
                                            ...values.administratorRolePermission?.contentAssistRecommendationPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.contentAssistRecommendationPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />

                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.contentAssistRecommendationPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            contentAssistRecommendationPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.contentAssistRecommendationPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.contentAssistRecommendationPermission!!.enabled,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        contentAssistRecommendationPermission: {
                                            ...values.administratorRolePermission?.contentAssistRecommendationPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.contentAssistRecommendationPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Tenant
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.tenantPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            tenantPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.tenantPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.tenantPermission!!.editable : false,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        tenantPermission: {
                                            ...values.administratorRolePermission?.tenantPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.tenantPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />
                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.tenantPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            tenantPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.tenantPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.tenantPermission!!.enabled,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        tenantPermission: {
                                            ...values.administratorRolePermission?.tenantPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.tenantPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Server Config
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.serverConfigPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            serverConfigPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.serverConfigPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.serverConfigPermission!!.editable : false,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        serverConfigPermission: {
                                            ...values.administratorRolePermission?.serverConfigPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.serverConfigPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />
                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.serverConfigPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            serverConfigPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.serverConfigPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.serverConfigPermission!!.enabled,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        serverConfigPermission: {
                                            ...values.administratorRolePermission?.serverConfigPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.serverConfigPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Localization
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.localizationPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            localizationPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.localizationPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.localizationPermission!!.editable : false,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        localizationPermission: {
                                            ...values.administratorRolePermission?.localizationPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.localizationPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />
                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.localizationPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            localizationPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.localizationPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.localizationPermission!!.enabled,
                                            }
                                        }
                                    })

                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        localizationPermission: {
                                            ...values.administratorRolePermission?.localizationPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.localizationPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                User Tenant Settings
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.userSettingPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            userSettingPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.userSettingPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.userSettingPermission!!.editable : false,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        userSettingPermission: {
                                            ...values.administratorRolePermission?.userSettingPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.userSettingPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />
                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.userSettingPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            userSettingPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.userSettingPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.userSettingPermission!!.enabled,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        userSettingPermission: {
                                            ...values.administratorRolePermission?.userSettingPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.userSettingPermission!!.enabled,
                                        }
                                    }
                                }}
                            />

                            <InputLabel
                                style={{
                                    fontSize: `small`,
                                }}
                            >
                                Access Control
                            </InputLabel>
                            <SwitchExt
                                name="enabled"
                                label="Enabled"
                                value={values.administratorRolePermission?.accessControlPermission?.enabled}
                                onChange={(enabled) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            accessControlPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.accessControlPermission!!,
                                                enabled: enabled,
                                                editable: enabled ? administratorRolePermissionTenantSetting.administratorRolePermission!!.accessControlPermission!!.editable : false,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        accessControlPermission: {
                                            ...values.administratorRolePermission?.accessControlPermission!!,
                                            enabled: enabled,
                                            editable: enabled ? values.administratorRolePermission!!.accessControlPermission!!.editable : false,
                                        }
                                    }
                                }}
                            />
                            <SwitchExt
                                name="editable"
                                label="Editable"
                                value={values.administratorRolePermission?.accessControlPermission?.editable}
                                onChange={(editable) => {
                                    setAdministratorRolePermissionTenantSetting({
                                        ...administratorRolePermissionTenantSetting,
                                        administratorRolePermission: {
                                            ...administratorRolePermissionTenantSetting.administratorRolePermission,
                                            accessControlPermission: {
                                                ...administratorRolePermissionTenantSetting.administratorRolePermission?.accessControlPermission!!,
                                                editable: editable,
                                                enabled: editable ? true : administratorRolePermissionTenantSetting.administratorRolePermission!!.accessControlPermission!!.enabled,
                                            }
                                        }
                                    })
                                    values.administratorRolePermission = {
                                        ...values.administratorRolePermission,
                                        accessControlPermission: {
                                            ...values.administratorRolePermission?.accessControlPermission!!,
                                            editable: editable,
                                            enabled: editable ? true : values.administratorRolePermission!!.accessControlPermission!!.enabled,
                                        }
                                    }
                                }}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    administratorRolePermissionTenantSettingUpdateMutation.isLoading
                                        ? 'Saving...'
                                        : 'Save'
                                }
                                disabled={
                                    administratorRolePermissionTenantSettingUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.userSettingPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(AdministratorRolePermissionTenantSettingDetail)