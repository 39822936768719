import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import Header from '../../../../../components/Header'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
    Locale,
    LocaleWrapper,
} from '../../../../../interfaces/LocalizationType'
import { createLocale, updateLocale } from '../../../../../actions/localization'
import { ApiError } from '../../../../../interfaces/ErrorType'
import ErrorMessage from '../../../../../components/ErrorMessage'
import ButtonExt from '../../../../../components/ButtonExt'
import {connect} from "react-redux";

const localeSchema = yup.object().shape({
    code: yup.string().required('required'),
    name: yup.string().required('required'),
})

const LocaleDetail: FunctionComponent<LocaleWrapper> = ({
    userSetting,
    isNew = false,
    wrapper,
}) => {
    const navigate = useNavigate()
    const [locale, setLocale] = useState<Locale>(
        wrapper
            ? wrapper
            : {
                  id: undefined,
                  code: '',
                  name: '',
              }
    )

    /**
     * Mutate locale create
     */
    const localeCreateMutation = useMutation<Locale, ApiError, Locale>(
        createLocale,
        {
            onSuccess: (data) => {
                setLocale(data)
            },
        }
    )

    /**
     * Mutate locale update
     */
    const localeUpdateMutation = useMutation<Locale, ApiError, Locale>(
        updateLocale,
        {
            onSuccess: (data) => {
                setLocale(data)
            },
        }
    )

    if (localeCreateMutation.isSuccess) {
        navigate(`/locale`)
    }

    /**
     * Invoke an action to create/ update locale
     * @param {*} e - event
     */
    const onSave = (values: Locale) => {
        if (!values.id) {
            localeCreateMutation.mutate(values)
        } else {
            localeUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing locale details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/locale`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Locale" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {localeCreateMutation.isError && (
                    <ErrorMessage error={localeCreateMutation.error} />
                )}

                {localeUpdateMutation.isError && (
                    <ErrorMessage error={localeUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={locale}
                validationSchema={localeSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {locale.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <TextField
                                variant="filled"
                                type="text"
                                label="Code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.code}
                                name="code"
                                error={!!touched.code && !!errors.code}
                                helperText={touched.code && errors.code}
                                disabled={values.id != null}
                            />

                            <TextField
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    localeCreateMutation.isLoading ||
                                    localeUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    localeCreateMutation.isLoading ||
                                    localeUpdateMutation.isLoading ||
                                    !userSetting?.administratorRolePermission?.localizationPermission?.editable
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(LocaleDetail)
