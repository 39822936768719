import React, { FunctionComponent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {Box, InputLabel, Switch} from '@mui/material'

const SwitchExt: FunctionComponent<SwitchProp> = ({
                                                      name,
                                                      value,
                                                      label,
                                                      required = false,
                                                      editable = true,
                                                      onBlurEvent,
                                                      onChange,
                                                      onChangeEvent,
                                                  }) => {
    const uuid = useMemo(() => uuidv4(), [])

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = async (event: any) => {
        if (onChange) {
            onChange(!value)
        }

        if (onChangeEvent) {
            onChangeEvent(event)
        }
    }

    return (
        <Box>
            {label && (
                <InputLabel
                    style={{
                        fontSize: `x-small`,
                        paddingLeft: `15px`,
                        paddingBottom: `0px`,
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <Switch
                key={uuid}
                name={name}
                checked={value}
                color="secondary"
                onBlur={onInternalBlurEvent}
                onChange={onInternalChangeEvent}
                disabled={!editable}
                required={required}
            />
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface SwitchProp {
    name: string
    label?: string | undefined
    value: boolean | undefined
    required?: boolean
    editable?: boolean
    onChange?: (v: boolean) => void // Handle event upon onclick
    onChangeEvent?: (event: any) => void // Handle event upon onclick
    onBlurEvent?: (event: any) => void // Handle event upon onblur
}

export default SwitchExt