import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Box, FormHelperText, IconButton, InputLabel, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {KeyPair} from "../../../../../../../interfaces/SettingType";
import TextareaAutosizeExt from "../../../../../../../components/TextareaAutosize";
import AccordionExt from "../../../../../../../components/AccordionExt";
import ButtonExt from "../../../../../../../components/ButtonExt";

const InterestItems: FunctionComponent<InterestItemProp> = ({
                                                                  label,
                                                                  values,
                                                                  onBlurEvent,
                                                                  onChangeEvent,
                                                                  error,
                                                                  helperText,
                                                              }) => {
    let uuid = useMemo(() => uuidv4(), [])
    const [valueChanges, setValueChanges] = useState<KeyPair[]>(values || [])

    useEffect(() => {
        setValueChanges(values || []);
    }, [values]);

    const onInternalBlurEvent = async (event: any) => {
        if (onBlurEvent) {
            onBlurEvent(event)
        }
    }

    const onInternalChangeEvent = async (v: KeyPair[]) => {
        if (onChangeEvent) {
            onChangeEvent(v)
        }
    }

    const validateError = (valueChangeItem: KeyPair) => {
        if (valueChangeItem.key?.trim()?.length === 0 && valueChangeItem.value?.trim()?.length === 0) {
            return 'Interest and Description are required'
        }

        if (valueChangeItem.key?.trim()?.length === 0 && valueChangeItem.value?.trim()?.length > 0) {
            return 'Interest is required'
        }

        if (valueChangeItem.key?.trim()?.length > 0 && valueChangeItem.value?.trim().length === 0) {
            return 'Description is required'
        }

        return ''
    }

    return (
        <FormHelperText onBlur={onInternalBlurEvent} error={error}>
            <Box key={`interest-item-${uuid}`}>
                {label && (
                    <InputLabel
                        style={{ fontSize: `small`, paddingLeft: `15px`, paddingBottom: `10px` }}
                    >
                        {label}
                    </InputLabel>
                )}

                {valueChanges.map((valueChangeItem, valueChangeIndex) => {
                    return (
                        <Box key={`accordion-box-${valueChangeIndex}`}>
                            <AccordionExt
                                name={`accordion-${valueChangeIndex}`}
                                title={valueChangeItem.key ? valueChangeItem.key : ``}
                                errorMessage={
                                    validateError(valueChangeItem)
                                }
                                error={!valueChangeItem.key.trim() || valueChangeItem.value?.trim().length === 0}
                                component={
                                    <Box display="grid"
                                         gap="30px"
                                         gridTemplateColumns="repeat(1, minmax(0,1fr))"
                                    >
                                        <TextField
                                            key={`interest-${valueChangeIndex}`}
                                            variant="filled"
                                            type="text"
                                            label="Interest"
                                            value={valueChangeItem.key}
                                            onChange={async (e) => {
                                                const cloneValues = [...valueChanges]
                                                cloneValues[valueChangeIndex].key = e.target.value
                                                setValueChanges(cloneValues)
                                                await onInternalChangeEvent(cloneValues)
                                            }}
                                            name={`interest-${valueChangeIndex}`}
                                        />
                                        <TextareaAutosizeExt
                                            key={`description-${valueChangeIndex}`}
                                            label="Description"
                                            value={valueChangeItem.value}
                                            minRows={3}
                                            maxRows={5}
                                            onChange={async (v) => {
                                                const cloneValues = [...valueChanges]
                                                cloneValues[valueChangeIndex].value = v
                                                setValueChanges(cloneValues)
                                                await onInternalChangeEvent(cloneValues)
                                            }}
                                            name={`description-${valueChangeIndex}`}
                                        />
                                    </Box>
                                }
                                actionRenderer={
                                    <Box>
                                        <ButtonExt
                                            style={{padding: `2px`, minWidth: `2px`}}
                                            type="button"
                                            value=""
                                            icon={<RemoveIcon />}
                                            onClickEvent={async () => {
                                                const cloneValues = [...valueChanges]
                                                cloneValues.splice(valueChangeIndex, 1)
                                                setValueChanges(cloneValues)
                                                await onInternalChangeEvent(cloneValues)
                                            }}
                                        />
                                    </Box>
                                }
                            />
                        </Box>
                    )
                })}

                <Box style={{padding: `0`, marginRight: `5.5em`, marginTop: `1em`}} textAlign='right'>
                    <ButtonExt
                        style={{padding: `2px`, minWidth: `2px`}}
                        type="button"
                        value=""
                        icon={<AddIcon />}
                        onClickEvent={async () => {
                            const cloneValues = [...valueChanges]
                            cloneValues.push({key: '', value: ''})
                            setValueChanges(cloneValues)
                            await onInternalChangeEvent(cloneValues)
                        }}
                    />
                </Box>
            </Box>

            {helperText}
        </FormHelperText>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface InterestItemProp {
    label: string
    values: KeyPair[] | undefined
    onBlurEvent?: (event: any) => void // Handle event upon onBlur
    onChangeEvent?: (v: KeyPair[]) => void // Handle event upon onSelect
    error?: boolean | undefined
    helperText?: React.ReactNode
}

export default InterestItems