import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { Box } from '@mui/material'
import { fetchServerConfigs } from '../../../actions/server'
import {ServerConfigRouter} from "../../../interfaces/ServerConfigType";
import ServerConfigRouterFC from "./router";

/**
 * Server config list
 *
 * @returns server config list page
 */
function ServerConfigs() {
    const expandRow = (row: ServerConfigRouter<any>) => (
        <ServerConfigRouterFC key={row.key} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchServerConfigs(searchOptions)
    }

    const columns = [
        {
            dataField: 'key',
            text: 'Key',
            sort: true,
        }
    ]

    /**
     * Render the server config through Table component
     */
    return (
        <Box m="20px">
            <Header title="Server Config" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`server-configs`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default ServerConfigs
