import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordInput: React.FC<PasswordInputProps> = ({
                                                         style,
                                                         label,
                                                         value,
                                                         error,
                                                         helperText,
                                                         onBlurEvent,
                                                         onChangeEvent,
                                                         required,
                                                         ...props
                                                     }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <TextField
            {...props}
            style={style}
            label={label}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            value={value}
            onBlur={onBlurEvent}
            onChange={onChangeEvent}
            error={error}
            helperText={helperText}
            fullWidth
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export interface PasswordInputProps {
    style?: React.CSSProperties;
    label?: string;
    name: string;
    value: string;
    onChangeEvent: (e: any) => void;
    onBlurEvent?: (e: any) => void;
    error?: boolean;
    helperText?: React.ReactNode;
    required?: boolean;
}

export default PasswordInput;