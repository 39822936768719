import { ColorModeContext, useMode } from './theme'
import {Alert, AlertTitle, CssBaseline, ThemeProvider} from '@mui/material'
import { Routes, Route, useLocation } from 'react-router-dom'
import Topbar from './scenes/global/Topbar'
import Sidebar from './scenes/global/Sidebar'
import Dashboard from './scenes/dashboard'
import Login from './scenes/login'
import { QueryClient, QueryClientProvider } from 'react-query'
import {AuthScope, useAuth} from './actions/auth'
import { Provider } from 'react-redux'
import store from './redux/store'
import { loadCurrentConfigurations } from './actions/configuration'
import TenantDetail from './scenes/client/tenant/detail'
import Tenants from './scenes/client/tenant'
import ContentAssists from './scenes/content/assist'
import CountryDetail from './scenes/config/localization/country/detail'
import Countries from './scenes/config/localization/country'
import Locales from './scenes/config/localization/locale'
import LocaleDetail from './scenes/config/localization/locale/detail'
import TenantSettingDetail from './scenes/setting/tenant'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AuthTransitRoute from "./scenes/transit/route";
import SecurityAccessControl from "./scenes/security/access";
import SecurityAccessControlDetail from "./scenes/security/access/detail";
import ServerConfigs from "./scenes/config/server";
import AwsLocaleMappings from "./scenes/config/localization/locale/aws/mapping";
import Users from "./scenes/client/user";
import {useEffect, useState} from "react";
import {fetchMyUserSetting} from "./actions/user";
import {TenantSetting} from "./interfaces/SettingType";
import GoogleLocaleMappings from "./scenes/config/localization/locale/google/mapping";
import DefaultContentReferenceMappings from "./scenes/content/assist/default/reference/mapping";
import DefaultContentReferenceMappingDetail from "./scenes/content/assist/default/reference/mapping/detail";
import ContentAssistRecommendations from "./scenes/content/assist/recommendation";
import ContentAssistRecommendationGraphs from "./scenes/content/assist/recommendation/topology";
import UnitOfMeasureConversion from "./scenes/uom";
import ContentTenantInterestMappings from "./scenes/content/assist/tenant/interest";
import ContentTenantInterestMappingDetail from "./scenes/content/assist/tenant/interest/detail";
import ContentAssistGraphs from "./scenes/content/assist/topology";
import ContentTenantMilestoneSkillMappings from "./scenes/content/assist/tenant/milestone";
import ContentTenantMilestoneSkillMappingDetail from "./scenes/content/assist/tenant/milestone/detail";
import ContentTenantMilestoneDevelopmentalMappings from "./scenes/content/assist/tenant/milestone/developmental";
import ContentTenantMilestoneDevelopmentalMappingDetail
    from "./scenes/content/assist/tenant/milestone/developmental/detail";
import ParentAssistBot from "./scenes/parent/assist";

function App() {
    const location = useLocation();
    const path = location.pathname;
    const [theme, colorMode] = useMode()
    const [isAuthenticated] = useAuth()
    const queryClient = new QueryClient()

    const [userSetting, setUserSetting] = useState<TenantSetting | undefined>(undefined);
    const [error, setError] = useState(false)

    /**
     * Load current configurations upon startup
     */
    useEffect(() => {
        const initializeApp = async () => {
            try {
                if (isAuthenticated && userSetting === undefined) {
                    const data = await fetchMyUserSetting();
                    setUserSetting(data);

                    // Dispatch the action to load configurations
                    store.dispatch(loadCurrentConfigurations());
                }
            } catch (error) {
                console.error('Error initializing app:', error);
                // Handle error state if needed
                setError(true)
            }
        };

        // Call the function to initialize the app
        initializeApp();
    }, [isAuthenticated, userSetting]);

    if (isAuthenticated && userSetting === undefined && !error) {
        return <>Loading...</>;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <ColorModeContext.Provider value={colorMode}>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <div className="app">
                                {path !== "/transit" && !isAuthenticated && <Login />}

                                {path !== "/transit" &&
                                    ((store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'ADMIN')
                                    || (store.getState().user?.user?.user?.scopes && Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("PLATFORM")))
                                    && isAuthenticated && <Sidebar />}

                                <main className="content">
                                    {isAuthenticated && (
                                        <>
                                            <Topbar />

                                            {(error || (!(store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'ADMIN')
                                                && !(Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("PLATFORM")))) && (
                                                <Alert severity="error">
                                                    <AlertTitle>Access Denied: Insufficient Permissions</AlertTitle>
                                                    <p>We're sorry, but it seems that you don't have the necessary permissions to manage the administration console in the user interface.</p>
                                                    <p>Access to the administration console is restricted to authorized personnel only, as it involves critical settings and configurations for the system.</p>
                                                    <p>If you believe this is an error or you require access for specific tasks, please reach out to your system administrator or the designated support team. They will be able to assist you in obtaining the appropriate permissions or guide you through the necessary steps to achieve your desired objectives.</p>
                                                </Alert>
                                            )}

                                            {((store.getState().user?.user?.user?.role && store.getState().user.user.user.role === 'ADMIN')
                                                && (Array.from(store.getState().user.user.user.scopes, (scope: AuthScope) => scope.type).includes("PLATFORM"))) && (
                                                <>
                                                    <Routes>
                                                        <Route
                                                            path="/"
                                                            element={<Dashboard />}
                                                        />

                                                        <Route
                                                            path="/uom"
                                                            element={<UnitOfMeasureConversion />}
                                                        />

                                                        {store.getState().userSetting?.data?.administratorRolePermission?.contentAssistPermission?.enabled && (
                                                            <>
                                                                <Route
                                                                    path="/content/assist"
                                                                    element={<ContentAssists />}
                                                                />
                                                                <Route
                                                                    path="/content/assist/graph"
                                                                    element={<ContentAssistGraphs />}
                                                                />
                                                                <Route
                                                                    path="/default/content/reference/mapping"
                                                                    element={<DefaultContentReferenceMappings />}
                                                                />
                                                                <Route
                                                                    path="/default/content/reference/mapping/create"
                                                                    element={<DefaultContentReferenceMappingDetail isNew={true} />}
                                                                />

                                                                {/*<Route*/}
                                                                {/*    path="/content/assist/public/api"*/}
                                                                {/*    element={<PublicApiContentAssists />}*/}
                                                                {/*/>*/}

                                                                {store.getState().userSetting?.data?.administratorRolePermission?.contentAssistRecommendationPermission?.enabled && (
                                                                    <>
                                                                        <Route
                                                                            path="/content/assist/recommendation"
                                                                            element={<ContentAssistRecommendations />}
                                                                        />
                                                                        <Route
                                                                            path="/content/assist/recommendation/graph"
                                                                            element={<ContentAssistRecommendationGraphs />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/interest/mapping"
                                                                            element={<ContentTenantInterestMappings />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/interest/mapping/create"
                                                                            element={<ContentTenantInterestMappingDetail isNew={true} />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/milestone/developmental/mapping"
                                                                            element={<ContentTenantMilestoneDevelopmentalMappings />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/milestone/developmental/mapping/create"
                                                                            element={<ContentTenantMilestoneDevelopmentalMappingDetail isNew={true} />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/milestone/skill/mapping"
                                                                            element={<ContentTenantMilestoneSkillMappings />}
                                                                        />
                                                                        <Route
                                                                            path="/content/tenant/milestone/skill/mapping/create"
                                                                            element={<ContentTenantMilestoneSkillMappingDetail isNew={true} />}
                                                                        />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                        {store.getState().userSetting?.data?.administratorRolePermission?.parentAssistPermission?.enabled && (
                                                            <>
                                                                <Route
                                                                    path="/parent/assist"
                                                                    element={<ParentAssistBot />}
                                                                />
                                                            </>
                                                        )}

                                                        {userSetting?.administratorRolePermission?.superAdmin && (
                                                            <Route
                                                                path="/user"
                                                                element={<Users />}
                                                            />
                                                        )}

                                                        {userSetting?.administratorRolePermission?.tenantPermission?.enabled && (
                                                            <>
                                                                <Route
                                                                    path="/tenant"
                                                                    element={<Tenants />}
                                                                />
                                                                <Route
                                                                    path="/tenant/create"
                                                                    element={
                                                                        <TenantDetail
                                                                            isNew={true}
                                                                        />
                                                                    }
                                                                />
                                                            </>
                                                        )}

                                                        {userSetting?.administratorRolePermission?.localizationPermission?.enabled && (
                                                            <>
                                                                <Route
                                                                    path="/locale"
                                                                    element={<Locales />}
                                                                />
                                                                <Route
                                                                    path="/locale/create"
                                                                    element={
                                                                        <LocaleDetail
                                                                            isNew={true}
                                                                        />
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/aws/locale/mapping"
                                                                    element={<AwsLocaleMappings />}
                                                                />
                                                                <Route
                                                                    path="/google/locale/mapping"
                                                                    element={<GoogleLocaleMappings />}
                                                                />
                                                                <Route
                                                                    path="/country"
                                                                    element={<Countries />}
                                                                />
                                                                <Route
                                                                    path="/country/create"
                                                                    element={
                                                                        <CountryDetail
                                                                            isNew={true}
                                                                        />
                                                                    }
                                                                />
                                                                </>
                                                        )}

                                                        {userSetting?.administratorRolePermission?.serverConfigPermission?.enabled && (
                                                            <Route
                                                                path="/server"
                                                                element={
                                                                    <ServerConfigs />
                                                                }
                                                            />
                                                        )}

                                                        {userSetting?.administratorRolePermission?.userSettingPermission?.enabled && (
                                                            <Route
                                                                path="/setting/tenant"
                                                                element={
                                                                    <TenantSettingDetail />
                                                                }
                                                            />
                                                        )}

                                                        {userSetting?.administratorRolePermission?.accessControlPermission?.enabled && (
                                                            <>
                                                                <Route
                                                                    path="/security/access"
                                                                    element={
                                                                        <SecurityAccessControl />
                                                                    }
                                                                />

                                                                <Route
                                                                    path="/security/access/create"
                                                                    element={
                                                                        <SecurityAccessControlDetail
                                                                            isNew={true}
                                                                        />
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Routes>
                                                </>
                                            )}
                                        </>
                                    )}

                                    <Routes>
                                        <Route path="/transit" element={<AuthTransitRoute />} />
                                    </Routes>
                                </main>
                            </div>
                        </ThemeProvider>
                    </ColorModeContext.Provider>
                </QueryClientProvider>
            </Provider>
        </DndProvider>
    )
}

export default App
