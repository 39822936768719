import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { connect } from 'react-redux'
import { Box, TextField } from '@mui/material'
import ButtonExt from '../../../../components/ButtonExt'
import {AuthUser, UserWrapper} from "../../../../interfaces/UserType";
import {switchCurrentUser, unsetCurrentUser} from "../../../../actions/user";

const UserDetail: FunctionComponent<UserWrapper> = ({
                                                        switchUser,
                                                        isNew = false,
                                                        wrapper,
                                                    }) => {
    const [user] = useState<AuthUser>(
        wrapper!!
    )

    /**
     * Mutate switch user
     */
    const switchUserMutation = useMutation<any, Error, AuthUser>(
        switchCurrentUser
    )

    /**
     * Mutate unset user
     */
    const unsetUserMutation = useMutation<any, Error, any>(unsetCurrentUser)

    /**
     * Page containing user details
     */
    return (
        <>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <TextField
                    variant="filled"
                    type="text"
                    label="Id"
                    value={user.id}
                    name="id"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Username"
                    value={user.username}
                    name="username"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Email"
                    value={user.email}
                    name="email"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Phone Number"
                    value={user.phoneNumber}
                    name="phoneNumber"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Prefer Name"
                    value={user.preferName}
                    name="preferName"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="First Name"
                    value={user.firstName}
                    name="firstName"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Last Name"
                    value={user.lastName}
                    name="lastName"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Enabled"
                    value={user.enabled}
                    name="enabled"
                />

                <TextField
                    variant="filled"
                    type="text"
                    label="Role"
                    value={user.role}
                    name="role"
                />
            </Box>
            <Box
                display="flex"
                justifyContent="end"
                mt="20px"
                gap="20px"
            >

                {user.role === 'ADMIN' && user.enabled && switchUser?.id !== user.id && (
                    <>
                        <ButtonExt
                            value={
                                switchUserMutation.isLoading
                                    ? 'Switching...'
                                    : 'Switch'
                            }
                            disabled={
                                switchUserMutation.isLoading
                            }
                            onClickEvent={() => {
                                switchUserMutation.mutate(
                                    user
                                )
                            }}
                        />
                    </>
                )}

                {user.role === 'ADMIN' && user.enabled && switchUser?.id === user.id && (
                    <>
                        <ButtonExt
                            value={
                                unsetUserMutation.isLoading
                                    ? 'Unset...'
                                    : 'Unset'
                            }
                            disabled={
                                unsetUserMutation.isLoading
                            }
                            onClickEvent={() => {
                                unsetUserMutation.mutate({})
                            }}
                        />
                    </>
                )}
            </Box>
        </>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchUser: state.user.switchUser }
}

export default connect(mapStateToProps)(UserDetail)
