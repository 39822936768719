import SecureAxios from '../extensions/SecureAxios'
import store from '../redux/store'
import {
    LOAD_MY_USER_SETTING,
    SWITCH_USER,
    UNSET_USER,
} from "../redux/actionType";

/**
 * An action to fetch users
 * @param searchOptions
 * @returns {Promise<*>}
 */
export async function fetchUsers(searchOptions) {
    const userUrl = `/admin/user?keyword=${encodeURIComponent(searchOptions.keyword)}&page=${searchOptions.pageOptions.page}&size=${searchOptions.pageOptions.pageLimit}`
    const { data } = await SecureAxios.get(userUrl)
    return data
}

/**
 * An action to switch current user
 */
export async function switchCurrentUser(user) {
    store.dispatch({ type: SWITCH_USER, payload: user})
}

/**
 * An action to switch current user
 */
export async function unsetCurrentUser() {
    store.dispatch({ type: UNSET_USER, payload: undefined })
}


/**
 * An action to fetch my user setting
 */
export async function fetchMyUserSetting() {
    const { data } = await SecureAxios.get('/user/setting')

    store.dispatch({ type: LOAD_MY_USER_SETTING, payload: data.data })

    return data.data
}

/**
 * An action to fetch user setting
 */
export async function fetchUserSetting() {
    let userSettingUrl = '/user/setting'

    if (store.getState().user?.switchUser?.id) {
        userSettingUrl += `?userId=${
            store.getState().user?.switchUser?.id
        }`
    } else if (store.getState().switchTenant?.tenant?.id) {
        userSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }
    const { data } = await SecureAxios.get(userSettingUrl)

    return data.data
}

/**
 * An action to fetch my available tags
 */
export async function fetchMyTags() {
    let getUserSettingUrl = '/user/setting'

    if (store.getState().switchTenant?.tenant?.id) {
        getUserSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }
    const { data } = await SecureAxios.get(getUserSettingUrl)
    return data.data.contentAssistMapping.contentTags.map((contentTag) => {
        return {
            id: contentTag.key,
            name: contentTag.values[0],
            description: contentTag.values[1],
        }
    })
}

/**
 * An action to update user setting
 */
export async function updateUserSetting(userSetting) {
    let updateUserSettingUrl = '/admin/user/setting/update'

    if (store.getState().user?.switchUser?.id) {
        updateUserSettingUrl += `?userId=${
            store.getState().user?.switchUser?.id
        }`
    } else if (store.getState().switchTenant?.tenant?.id) {
        updateUserSettingUrl += `?tenantId=${
            store.getState().switchTenant?.tenant?.id
        }`
    }

    const requestBody = {
        ...userSetting,
    }

    const { data } = await SecureAxios.put(updateUserSettingUrl, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}
