import React, {FunctionComponent, useMemo} from "react";
import {v4 as uuidv4} from "uuid";
import {Box, InputLabel} from "@mui/material";
import ReactPlayer from "react-player";

/**
 * Extended React component to render a specific player tag, event function binding, and any
 * reusable attributes
 */
const PlayerExt: FunctionComponent<PlayerProp> = ({
                                                      label,
                                                      url,
                                                      controls = true,
                                                      width = '200px',
                                                      height = '200px',
                                             }) => {
    let uuid = useMemo(() => uuidv4(), [])

    return (
        <Box style={{ marginLeft: `1em` }}>
            {label && (
                <InputLabel
                    style={{ fontSize: `x-small`, paddingBottom: `10px` }}
                >
                    {label}
                </InputLabel>
            )}
            <ReactPlayer
                key={uuid}
                url={url}
                controls={controls}
                width={width}
                height={height} />
        </Box>
    )
}

/**
 * Contains the specific props type that can be passing
 */
interface PlayerProp {
    label?: string
    url?: string
    controls?: boolean
    width?: string
    height?: string
}

export default PlayerExt