import {
    getValueByKey,
    updateValueByKey,
    removeKey,
} from '../../extensions/EncryptStorage'
import { CURRENT_TENANT } from '../../actions/tenant'
import { SWITCH_TENANT, UNSET_TENANT, UPDATE_TENANT } from '../actionType'

/**
 * Default state declaration
 */
const initialState = {
    tenant: getValueByKey(CURRENT_TENANT),
}

/**
 * Update current tenant id redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function switchTenant(state = initialState, action) {
    switch (action.type) {
        case SWITCH_TENANT:
        case UPDATE_TENANT: {
            updateValueByKey(CURRENT_TENANT, action.payload)
            return Object.assign({}, state, {
                tenant: action.payload,
            })
        }
        case UNSET_TENANT: {
            removeKey(CURRENT_TENANT)
            return Object.assign({}, state, {
                tenant: undefined,
            })
        }
        default:
            return state
    }
}
