import React from 'react'
import TenantDetail from './detail'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../components/DataGridFilter'
import Header from '../../../../components/Header'
import { Box } from '@mui/material'
import { Country } from '../../../../interfaces/LocalizationType'
import { fetchCountries } from '../../../../actions/localization'
import {connect} from "react-redux";
import {TenantSetting} from "../../../../interfaces/SettingType";

/**
 * Country list
 *
 * @returns country list page
 */
function Countries(props: {userSetting: TenantSetting}) {
    const {userSetting} = props

    const expandRow = (row: Country) => (
        <TenantDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchCountries(searchOptions)
    }

    const columns = [
        {
            dataField: 'code',
            text: 'Code',
            sort: true,
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
        },
    ]

    /**
     * Render the countries through Table component
     */
    return (
        <Box m="20px">
            <Header title="Countries" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`countries`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/country/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.localizationPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(Countries)
