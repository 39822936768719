import React, {useState} from 'react'
import {Box} from '@mui/material'
import {connect} from "react-redux";
import {TenantSetting} from "../../../../../../interfaces/SettingType";
import {
    ContentTenantMilestoneDevelopmentalMapping,
    ContentTenantMilestoneDevelopmentalMappingFilterCustomFilterOptions
} from "../../../../../../interfaces/ContentAssistType";
import DataGridFilter, {SearchOptionsProp} from "../../../../../../components/DataGridFilter";
import {useAuthQueryWithQueryFunction} from "../../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../../interfaces/ErrorType";
import {Tenant} from "../../../../../../interfaces/TenantType";
import {fetchAllTenants} from "../../../../../../actions/tenant";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import {fetchContentTenantMilestoneDevelopmentalMappings} from "../../../../../../actions/contentAssists";
import Header from "../../../../../../components/Header";
import ContentTenantMilestoneDevelopmentalMappingDetail from "./detail";

/**
 * Content tenant milestone developmental mappings
 *
 * @returns content tenant milestone developmental mappings page
 */
function ContentTenantMilestoneDevelopmentalMappings(props: {userSetting: TenantSetting}) {

    const {userSetting} = props

    const [customSearchOptions, setCustomSearchOptions] =
        useState<ContentTenantMilestoneDevelopmentalMappingFilterCustomFilterOptions>({
            tenantId: '',
        })

    const expandRow = (row: ContentTenantMilestoneDevelopmentalMapping) => (
        <ContentTenantMilestoneDevelopmentalMappingDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchContentTenantMilestoneDevelopmentalMappings(searchOptions)
    }

    /**
     * Fetch all tenants
     * */
    const tenantQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Tenant[]
        >('tenants', fetchAllTenants, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTenantOptions = tenantQuery.data?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant.id,
        }
    })

    const customSearchOptionsRenderer = () => (
        <AutocompleteExt
            name="tenantId"
            multiSelection={false}
            label="Tenant..."
            selectedValue={customSearchOptions.tenantId}
            onSelect={(value) => {
                setCustomSearchOptions({
                    ...customSearchOptions,
                    tenantId: value,
                })
            }}
            options={availableTenantOptions}
        />
    )

    const columns = [
        {
            dataField: 'tenant.name',
            text: 'Tenant',
            sort: false,
        },
        {
            dataField: 'developmentStage.type',
            text: 'Development Stage',
            sort: false,
        },
        {
            dataField: 'relationship.name',
            text: 'Relationship',
            sort: false,
        },
        {
            dataField: `eligibleRangeStart`,
            text: `Eligible Range Start`,
            sort: true,
        },
        {
            dataField: `eligibleRangeEnd`,
            text: `Eligible Range End`,
            sort: true,
        },
    ]

    /**
     * Render the content tenant milestone skill mappings page
     */
    return (
        <Box m="20px">
            <Header title="Content Tenant Milestone Developmental Mappings" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`contentTenantMilestoneDevelopmentalMappings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/content/tenant/milestone/developmental/mapping/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.contentAssistPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentTenantMilestoneDevelopmentalMappings)