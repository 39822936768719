import React, {FunctionComponent} from "react";
import {
    CONTENT_TYPE,
    METHOD,
    ParentAssistOAuth2Integration,
    ParentAssistTenantSetting
} from "../../../../../../../interfaces/SettingType";
import {Box, TextField} from "@mui/material";
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import MultiKeyValueInput from "../../../../../../../components/MultiInputKeyValue";
import {toEntries, toKeyPairs, toMap} from "../../../../../../../share/ObjectUtil";
import TagInputExt from "../../../../../../../components/TagInput";
import {contentTypeOptions, methodOptions} from "../../../../../../../share/SettingTypeConstant";

const OAuth2SettingDetail: FunctionComponent<OAuth2SettingProps> = ({
                                                                        values,
                                                                        parentAssistTenantSetting,
                                                                        setParentAssistTenantSetting,
                                                                        handleBlur,
                                                                        handleChange
}) => {
    return (
        <>
            <TextField
                name="parentAssistMapping.authIntegrations.tokenUrl"
                label="Token Url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={
                    (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenUrl
                }
                fullWidth
                required={true}
            />

            <Box
                display="grid"
                gap="30px"
                mb="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <AutocompleteExt
                    name="parentAssistMapping.authIntegrations.tokenMethod"
                    multiSelection={false}
                    label="Token Method"
                    selectedValue={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenMethod
                    }
                    options={methodOptions}
                    onBlurEvent={handleBlur}
                    onSelect={(v) => {
                        if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                            && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                            setParentAssistTenantSetting({
                                ...parentAssistTenantSetting,
                                parentAssistMapping: {
                                    ...parentAssistTenantSetting.parentAssistMapping,
                                    authIntegrations: {
                                        ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                        tokenMethod: v,
                                    },
                                },
                            });
                            (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenMethod = v
                        }
                    }}
                    disableUnselectAll={true}
                    required={true}
                />

                <AutocompleteExt
                    name="parentAssistMapping.authIntegrations.tokenContentType"
                    multiSelection={false}
                    label="Token Content Type"
                    selectedValue={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenContentType
                    }
                    options={contentTypeOptions}
                    onBlurEvent={handleBlur}
                    onSelect={(v) => {
                        if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                            && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                            setParentAssistTenantSetting({
                                ...parentAssistTenantSetting,
                                parentAssistMapping: {
                                    ...parentAssistTenantSetting.parentAssistMapping,
                                    authIntegrations: {
                                        ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                        tokenContentType: v,
                                    },
                                },
                            });
                            (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenContentType = v
                        }
                    }}
                    disableUnselectAll={true}
                    required={true}
                />
            </Box>

            <MultiKeyValueInput
                style={{marginBottom: `30px`}}
                name="parentAssistMapping.authIntegrations.tokenCustomHeaders"
                label="Token Custom Headers"
                values={
                    toKeyPairs((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenCustomHeaders)
                }
                onBlurEvent={handleBlur}
                onChangeEvent={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = toMap(v)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    tokenCustomHeaders: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenCustomHeaders = valueChanged
                    }
                }}
                required={false}
            />

            <MultiKeyValueInput
                style={{marginBottom: `30px`}}
                name="parentAssistMapping.authIntegrations.tokenRequestPayload"
                label="Token Request Payload"
                values={
                    toKeyPairs((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenRequestPayload)
                }
                onBlurEvent={handleBlur}
                onChangeEvent={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = toMap(v)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    tokenRequestPayload: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenRequestPayload = valueChanged
                    }
                }}
                encrypted={true}
                required={true}
            />

            <Box
                display="grid"
                gap="30px"
                mb="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <TextField
                    name="parentAssistMapping.authIntegrations.tokenResponseAccessTokenField"
                    label="Token Response Access Token Field"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAccessTokenField
                    }
                    required={true}
                />

                <TextField
                    name="parentAssistMapping.authIntegrations.tokenResponseRefreshTokenField"
                    label="Token Response Refresh Token Field"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenResponseRefreshTokenField
                    }
                    required={false}
                />
            </Box>

            <TagInputExt
                name="parentAssistMapping.authIntegrations.tokenResponseAdditionalRefreshTokenFields"
                label="Token Response Additional Refresh Token Fields"
                values={
                    (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields.map((v) => {
                        return {
                            id: v,
                            text: v,
                        }
                    })
                }
                onItemAdd={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = []
                        if ((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields) {
                            valueChanged.push(...(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields)
                        }
                        valueChanged.push(v.id)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    tokenResponseAdditionalRefreshTokenFields: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields = valueChanged
                    }
                }}
                onItemDelete={(index) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged: string[] = (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields
                        valueChanged.splice(index, 1)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    tokenResponseAdditionalRefreshTokenFields: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).tokenResponseAdditionalRefreshTokenFields = valueChanged
                    }
                }}
                onBlurEvent={handleBlur}
                required={false}
            />

            <TextField
                style={{marginTop: `30px`}}
                name="parentAssistMapping.authIntegrations.refreshTokenUrl"
                label="Refresh Token Url"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={
                    (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl
                }
                required={false}
            />

            <Box
                display="grid"
                gap="30px"
                mb="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <AutocompleteExt
                    name="parentAssistMapping.authIntegrations.refreshTokenMethod"
                    multiSelection={false}
                    label="Refresh Token Method"
                    selectedValue={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenMethod
                    }
                    options={methodOptions}
                    onBlurEvent={handleBlur}
                    onSelect={(v) => {
                        if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                            && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                            setParentAssistTenantSetting({
                                ...parentAssistTenantSetting,
                                parentAssistMapping: {
                                    ...parentAssistTenantSetting.parentAssistMapping,
                                    authIntegrations: {
                                        ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                        refreshTokenMethod: v,
                                    },
                                },
                            });
                            (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenMethod = v
                        }
                    }}
                    disableUnselectAll={true}
                    required={(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl !== ''}
                />

                <AutocompleteExt
                    name="parentAssistMapping.authIntegrations.refreshTokenContentType"
                    multiSelection={false}
                    label="Refresh Token Content Type"
                    selectedValue={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenContentType
                    }
                    options={contentTypeOptions}
                    onBlurEvent={handleBlur}
                    onSelect={(v) => {
                        if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                            && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                            setParentAssistTenantSetting({
                                ...parentAssistTenantSetting,
                                parentAssistMapping: {
                                    ...parentAssistTenantSetting.parentAssistMapping,
                                    authIntegrations: {
                                        ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                        refreshTokenContentType: v,
                                    },
                                },
                            });
                            (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenContentType = v
                        }
                    }}
                    disableUnselectAll={true}
                    required={(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl !== ''}
                />
            </Box>

            <MultiKeyValueInput
                style={{marginBottom: `30px`}}
                name="parentAssistMapping.authIntegrations.refreshTokenCustomHeaders"
                label="Refresh Token Custom Headers"
                values={
                    toKeyPairs((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenCustomHeaders)
                }
                onBlurEvent={handleBlur}
                onChangeEvent={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = toMap(v)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    refreshTokenCustomHeaders: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenCustomHeaders = valueChanged
                    }
                }}
                required={false}
            />

            <TagInputExt
                name="parentAssistMapping.authIntegrations.refreshTokenRequestFields"
                label="Refresh Token Request Fields"
                values={
                    (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields.map((v) => {
                        return {
                            id: v,
                            text: v,
                        }
                    }) || []
                }
                onItemAdd={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = []
                        if ((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields) {
                            valueChanged.push(...(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields)
                        }
                        valueChanged.push(v.id)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    refreshTokenRequestFields: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields = valueChanged
                    }
                }}
                onItemDelete={(index) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged: string[] = (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields
                        valueChanged.splice(index, 1)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    refreshTokenRequestFields: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestFields = valueChanged
                    }
                }}
                onBlurEvent={handleBlur}
                required={(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl !== ''}
            />

            <MultiKeyValueInput
                style={{marginTop: `30px`, marginBottom: `30px`}}
                name="parentAssistMapping.authIntegrations.refreshTokenRequestPayload"
                label="Refresh Token Request Payload"
                values={
                    toKeyPairs((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestPayload)
                }
                onBlurEvent={handleBlur}
                onChangeEvent={(v) => {
                    if (values.parentAssistMapping && values.parentAssistMapping.authIntegrations
                        && parentAssistTenantSetting.parentAssistMapping && parentAssistTenantSetting.parentAssistMapping.authIntegrations) {

                        const valueChanged = toMap(v)

                        setParentAssistTenantSetting({
                            ...parentAssistTenantSetting,
                            parentAssistMapping: {
                                ...parentAssistTenantSetting.parentAssistMapping,
                                authIntegrations: {
                                    ...(parentAssistTenantSetting.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration),
                                    refreshTokenRequestPayload: valueChanged
                                },
                            },
                        });
                        (values.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestPayload = valueChanged
                    }
                }}
                encrypted={true}
                required={(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl !== ''}
            />

            <Box
                display="grid"
                gap="30px"
                mb="30px"
                gridTemplateColumns="repeat(2, minmax(0,1fr))"
            >
                <TextField
                    name="parentAssistMapping.authIntegrations.refreshTokenResponseAccessTokenField"
                    label="Refresh Token Response Access Token Field"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={
                        (values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenResponseAccessTokenField
                    }
                    required={(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenUrl !== ''}
                />
            </Box>
        </>
    );
}

export interface OAuth2SettingProps {
    values: ParentAssistTenantSetting;
    parentAssistTenantSetting: ParentAssistTenantSetting;
    setParentAssistTenantSetting: (value: ParentAssistTenantSetting) => void;
    handleBlur: (event: any) => void;
    handleChange: (event: any) => void;
}

/**
 * Default values for OAuth2 Integration
 */
export const defaultOAuth2Integrations= {
    tokenUrl: '',
    tokenMethod: METHOD.POST,
    tokenContentType: CONTENT_TYPE.APPLICATION_FORM_URLENCODED,
    tokenCustomHeaders: new Map<string, string>(), // Use Map instead of {}
    tokenRequestPayload: new Map<string, string>(), // Use Map instead of {}
    tokenResponseAccessTokenField: '',
    tokenResponseRefreshTokenField: '',
    tokenResponseAdditionalRefreshTokenFields: [],
    refreshTokenUrl: '',
    refreshTokenMethod: METHOD.POST,
    refreshTokenContentType: CONTENT_TYPE.APPLICATION_FORM_URLENCODED,
    refreshTokenCustomHeaders: new Map<string, string>(), // Use Map instead of {}
    refreshTokenRequestFields: [],
    refreshTokenRequestPayload: new Map<string, string>(), // Use Map instead of {}
    refreshTokenResponseAccessTokenField: '',
} as ParentAssistOAuth2Integration

/**
 * Check if all required fields are filled
 * @param convertedValues
 */
export const isOAuth2ConfigFulfilled = (convertedValues: any) => {
    const oAuth2Integration = convertedValues.parentAssistMapping.authIntegrations as ParentAssistOAuth2Integration;

    if (Object.values(oAuth2Integration.tokenCustomHeaders).some((v) => v === '' || v === null)) {
        return false;
    }

    if (Object.values(oAuth2Integration.tokenRequestPayload).some((v) => v === '' || v === null)) {
        return false;
    }

    if (Object.values(oAuth2Integration.refreshTokenCustomHeaders).some((v) => v === '' || v === null)) {
        return false;
    }

    if (Object.values(oAuth2Integration.refreshTokenRequestPayload).some((v) => v === '' || v === null)) {
        return false;
    }

    return true;
}

/**
 * Convert the values to the format that the backend expects
 * @param values
 */
export const convertOAuth2Setting = (values: ParentAssistTenantSetting) => {
    return {
        ...(values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration),
        tokenCustomHeaders: toEntries((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenCustomHeaders),
        tokenRequestPayload: toEntries((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).tokenRequestPayload),
        refreshTokenCustomHeaders: toEntries((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenCustomHeaders),
        refreshTokenRequestPayload: toEntries((values.parentAssistMapping?.authIntegrations as ParentAssistOAuth2Integration).refreshTokenRequestPayload),
    }
}

export default OAuth2SettingDetail;
