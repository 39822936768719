import {DevelopmentStage} from "../interfaces/DevelopmentStageType";
import {calculateWeekNumber, calculateMonthNumber as thisCalculateMonthNumber} from "./WeekConversion";

// Function to calculate the month number
export function calculateMonthNumber(day: number, developmentStage: DevelopmentStage): number {
    if (!developmentStage.supportedMonthConversion) {
        throw new Error("Convert due to month is not supported for development stage " + developmentStage.type);
    }

    const initialWeek = calculateWeekNumber(day);
    try {
        return thisCalculateMonthNumber(initialWeek, developmentStage);
    } catch (e) {
        return -1;
    }
}