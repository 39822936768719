import React, { useEffect, useRef, useState } from 'react'
import { Paper } from '@material-ui/core'
import {
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    Typography,
    useTheme
} from '@mui/material'
import { connect } from 'react-redux'
import {tokens} from "../../../theme";
import {AuthUser} from "../../../interfaces/UserType";
import {MessageLeft, MessageRight} from "./message";
import {Tenant} from "../../../interfaces/TenantType";
import {useMutation} from "react-query";
import {ApiError} from "../../../interfaces/ErrorType";
import {initParentAssist, resetParentAssistAuth, resumeParentAssist} from "../../../actions/parentAssists";
import ErrorMessage from "../../../components/ErrorMessage";
import Reply from "./reply";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ClearAllIcon from '@mui/icons-material/ClearAll';

function ParentAssistBot(props: { switchTenant: Tenant | undefined, switchUser: AuthUser | undefined }) {
    const { switchTenant, switchUser } = props
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [assistId, setAssistId] = useState<string>('')
    const [messages, setMessages] = useState<Message[]>([])
    const messagesEndRef = useRef<HTMLDivElement>(null)

    const resetParentAssistAuthMutation = useMutation<
        any,
        ApiError,
        any
        >(resetParentAssistAuth)

    const initParentAssistMutation = useMutation<
        any,
        ApiError,
        any
        >(initParentAssist)

    const resumeParentAssistMutation = useMutation<
        any,
        ApiError,
        any
        >(resumeParentAssist)

    const reset = () => {
        setAssistId('')
        setMessages([])
    }

    const resetAll = () => {
        resetParentAssistAuthMutation.mutate({}, {
            onSuccess: async () => {
                reset()
            }
        })
    }

    const handleMessage = (v: string) => {
        setMessages([
            ...messages,
            {
                message: v,
                certified: false,
                referenceIds: [],
                isBot: false
            }
        ])

        resumeParentAssistMutation.mutate(
            { assistId: assistId, requestBody: {query: v} },
            {
                onSuccess: async (data) => {
                    setMessages([
                        ...messages,
                        {
                            message: v,
                            certified: false,
                            referenceIds: [],
                            isBot: false
                        },
                        {
                            message: data.response.completion,
                            certified: data.response.additionalProperties['embedded_response'] && data.response.additionalProperties['referenceIds'].length > 0,
                            referenceIds: data.response.additionalProperties['referenceIds'],
                            isBot: true
                        }
                    ])
                    console.log('Additional Properties: ', data.response.additionalProperties)
                }
            }
        )
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        })
    }

    useEffect(() => {
        if (assistId.length === 0 && messages.length === 0) {
            initParentAssistMutation.mutate({}, {
                onSuccess: async (data) => {
                    setAssistId(data.id)
                    setMessages([
                        {
                            message: data.completion,
                            certified: false,
                            referenceIds: [],
                            isBot: true
                        }
                    ])
                }
            })
        }

        scrollToBottom()
    }, [messages])

    return (
        <Box m="20px">
            {initParentAssistMutation.isError && (
                <ErrorMessage error={initParentAssistMutation.error} />
            )}

            {resumeParentAssistMutation.isError && (
                <ErrorMessage error={resumeParentAssistMutation.error} />
            )}

            <Box display="flex" gap={1}>
                <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ mb: '5px' }}
                >
                    Parent Assist
                </Typography>
                <IconButton color="inherit" onClick={reset} disabled={resumeParentAssistMutation.isLoading || initParentAssistMutation.isLoading}>
                    <AutorenewIcon />
                </IconButton>
                <IconButton color="inherit" onClick={resetAll} disabled={resumeParentAssistMutation.isLoading || initParentAssistMutation.isLoading}>
                    <ClearAllIcon />
                </IconButton>
            </Box>

            <Box
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.grey[100]
                                : colors.primary[900],
                    },

                    '& .MuiPaper-MessageBody': {
                        width: 'calc( 100% - 20px )',
                        margin: 1,
                        overflowY: 'scroll',
                        height: '50vh',
                    },

                    '& .MuiBox-Container': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },

                    '& .MuiBox-Panel': {
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        position: 'relative',
                    },
                }}
                className="MuiBox-Container"
            >
                <Paper className="MuiBox-Panel">
                    <Paper className="MuiPaper-MessageBody">
                        {messages.map((message) => {
                            return (
                                <>
                                    {!message.isBot && (
                                        <MessageRight
                                            message={message.message}
                                            displayName={
                                                switchTenant
                                                    ? `${switchTenant.name} (${switchTenant.id})`
                                                    : (switchUser ? `${switchUser.username ? switchUser.username : switchUser.email} (${switchUser.id})` : '')
                                            }
                                        />
                                    )}

                                    {message.isBot && (
                                        <MessageLeft
                                            message={message.message}
                                            certified={message.certified}
                                            referenceIds={message.referenceIds}
                                            displayName="Bot"
                                        />
                                    )}
                                </>
                            )
                        })}

                        <Backdrop
                            sx={{
                                color: '#fff',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={resumeParentAssistMutation.isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <div ref={messagesEndRef} />
                    </Paper>
                    <Reply
                        onMessage={handleMessage}
                        disabled={assistId.length === 0 || initParentAssistMutation.isLoading || resumeParentAssistMutation.isLoading}
                        maxLength={2000}
                    />
                </Paper>
            </Box>
        </Box>
    )
}

interface Message {
    message: string
    certified: boolean
    referenceIds?: string[]
    isBot: boolean
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { switchTenant: state.switchTenant.tenant, switchUser: state.user.switchUser }
}

export default connect(mapStateToProps)(ParentAssistBot)