import SecureAxios from "../extensions/SecureAxios";
import store from "../redux/store";

export async function resetParentAssistAuth() {
    let parentAssistAuthResetUrl = `/admin/parent/assist/auth/reset`
    if (store.getState().switchTenant?.tenant?.id) {
        parentAssistAuthResetUrl += `?tenantId=${store.getState().switchTenant?.tenant.id}`
    }

    await SecureAxios.post(parentAssistAuthResetUrl, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export async function initParentAssist() {
    let initParentAssistUrl = `/parent/assist/init`
    if (store.getState().switchTenant?.tenant?.id) {
        initParentAssistUrl += `?tenantId=${store.getState().switchTenant?.tenant.id}`
    }

    const { data } = await SecureAxios.post(initParentAssistUrl, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return data.data
}

export async function resumeParentAssist(resumeRequest) {
    const requestBody = {
        ...resumeRequest.requestBody
    }

    let resumeParentAssistUrl = `/parent/assist/${resumeRequest.assistId}/async/resume`
    if (store.getState().switchTenant?.tenant?.id) {
        resumeParentAssistUrl += `?tenantId=${store.getState().switchTenant?.tenant.id}`
    }

    let { data } = await SecureAxios.post(resumeParentAssistUrl, requestBody, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    const asyncId = data.data.asyncId

    return await pollResumeStatus(asyncId)
}

const pollResumeStatus = async (asyncId, maxRetries = 30, delay = 1000) => {
    let attempts = 0;

    while (attempts < maxRetries) {
        const update = await getResumeStatusUpdate(asyncId);

        if (update.status === 'COMPLETED') return update;
        if (update.status === 'FAILED') throw new Error('An error occurred while processing the request');

        attempts++;
        delay = delay * 1.5;
        await new Promise(resolve => setTimeout(resolve, delay));
    }

    throw new Error('Polling timeout: Status update took too long.');
}

export const getResumeStatusUpdate = async (asyncId) => {
    const { data } = await SecureAxios.post(`/parent/assist/async/resume/${asyncId}/status`, {}, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return data.data
}