// Assuming the following constants are defined:
import {DevelopmentStage} from "../interfaces/DevelopmentStageType";

export const DAY_OF_WEEK = 7; // Number of days in a week
export const WEEK_OF_MONTH = 4.34524; // Number of weeks in a month

// Function to calculate the week number
export function calculateWeekNumber(day: number): number {
    const dayInWeek = day % DAY_OF_WEEK;

    // For instance,
    // if day is 51, Week = 8, and the remainder of 2
    // if day is 49, Week = 7, and no remainder
    return (dayInWeek > 0) ? Math.floor(day / DAY_OF_WEEK) + 1 : Math.floor(day / DAY_OF_WEEK);
}

// Function to calculate the day number
export function calculateMonthNumber(week: number, developmentStage: DevelopmentStage): number {
    if (week < developmentStage.weekFrom || week > developmentStage.weekTo) {
        throw new Error("The week is out of the development stage's week range. Please enter a valid week from " + developmentStage.weekFrom + " to " + developmentStage.weekTo);
    }

    // Calculate the month based on the provided week
    const weeksPerMonth = WEEK_OF_MONTH; // Number of weeks in a month
    const weeksSinceStart = week - developmentStage.weekFrom + 1;
    const monthDouble = Math.ceil(weeksSinceStart / weeksPerMonth);

    const monthIncludeOffset = monthDouble + developmentStage.validMonthFrom - 1;

    return +monthIncludeOffset; // casting to int
}