import React, {useState} from 'react'
import {Box, TextField, Typography} from '@mui/material'
import {useMutation} from "react-query";
import LoadingOverlay from 'react-loading-overlay-ts';
import {useAuthQueryWithQueryFunction} from "../../extensions/UseAuthQuery";
import {ApiError} from "../../interfaces/ErrorType";
import {
    calculateDueByMonthAndSelectedDevelopmentStage,
    calculateDueByWeekAndSelectedDevelopmentStage,
    calculateMonthByDueAndSelectedDevelopmentStage, calculateMonthByWeekAndSelectedDevelopmentStage,
    calculateWeekByDueAndSelectedDevelopmentStage,
    calculateWeekRangeByMonthAndSelectedDevelopmentStage,
    fetchDevelopmentStages
} from "../../actions/developmentStage";
import Header from "../../components/Header";
import {UoMConversionType} from "../../interfaces/UoMConversionType";
import AutocompleteExt from "../../components/Autocomplete";
import {calculateWeekNumber} from "../../share/WeekConversion";
import {calculateMonthNumber} from "../../share/MonthConversion";

/**
 * Render unit of measure conversion
 *
 * @returns contents
 */
function UnitOfMeasureConversion() {

    const defaultUoMConversionType: UoMConversionType = {
        developmentStage: undefined,
        valueFrom: NaN,
        valueTo: '',
    }

    const [monthToWeekRange, setMonthToWeekRange] = useState<UoMConversionType>(defaultUoMConversionType)
    const [weekToDue, setWeekToDue] = useState<UoMConversionType>(defaultUoMConversionType)
    const [weekToMonth, setWeekToMonth] = useState<UoMConversionType>(defaultUoMConversionType)
    const [monthToDue, setMonthToDue] = useState<UoMConversionType>(defaultUoMConversionType)
    const [dueToMonth, setDueToMonth] = useState<UoMConversionType>(defaultUoMConversionType)
    const [dueToWeek, setDueToWeek] = useState<UoMConversionType>(defaultUoMConversionType)

    const defaultError = {
        developmentStage: '',
        valueFrom: '',
    }

    const [monthToWeekRangeErrors, setMonthToWeekRangeErrors] = useState(defaultError);
    const [weekToDueErrors, setWeekToDueErrors] = useState(defaultError);
    const [weekToMonthErrors, setWeekToMonthErrors] = useState(defaultError);
    const [monthToDueErrors, setMonthToDueErrors] = useState(defaultError);
    const [dueToMonthErrors, setDueToMonthErrors] = useState(defaultError);
    const [dueToWeekErrors, setDueToWeekErrors] = useState(defaultError);

    /**
     * Fetch development stage list
     */
    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Calculate week range by month and selected development stage
     */
    const calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekRangeByMonthAndSelectedDevelopmentStage)

    /**
     * Calculate due by week and selected development stage
     */
    const calculateDueByWeekAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateDueByWeekAndSelectedDevelopmentStage)

    /**
     * Calculate month by week and selected development stage
     */
    const calculateMonthByWeekAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateMonthByWeekAndSelectedDevelopmentStage)

    /**
     * Calculate due by month and selected development stage
     */
    const calculateDueByMonthAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateDueByMonthAndSelectedDevelopmentStage)

    /**
     * Calculate month by due and selected development stage
     */
    const calculateMonthByDueAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateMonthByDueAndSelectedDevelopmentStage)

    /**
     * Calculate week by due and selected development stage
     */
    const calculateWeekByDueAndSelectedDevelopmentStageMutation = useMutation<
        any,
        ApiError,
        any
        >(calculateWeekByDueAndSelectedDevelopmentStage)

    const developmentStages = developmentStageQuery.data

    /**
     * Render UoM conversion
     */
    return (
        <Box m="20px">
            <Header title="Unit of Measure Conversion" />

            <Box>
                <Box m="2em">
                    <LoadingOverlay active={calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                        <form>
                            <Typography variant="h5">
                                Month to Week Range
                            </Typography>

                            <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                                <AutocompleteExt
                                    name="developmentStageId"
                                    multiSelection={false}
                                    label="Development Stage..."
                                    selectedValue={monthToWeekRange?.developmentStage?.id}
                                    onSelect={(value) => {
                                        setMonthToWeekRange({
                                            ...monthToWeekRange,
                                            developmentStage: developmentStages?.find((each) => each.id === value),
                                            valueFrom: NaN,
                                            valueTo: '',
                                        })
                                    }}
                                    options={developmentStages?.filter((each) => each.supportedMonthConversion).map((each) => ({value: each.id, label: each.type,}))}
                                    required={true}
                                />

                                <TextField
                                    name='monthToWeekRange.valueFrom'
                                    label={`${monthToWeekRange.developmentStage?.id ? `Month (${monthToWeekRange.developmentStage?.validMonthFrom} - ${monthToWeekRange.developmentStage?.validMonthTo})` : 'Month'}`}
                                    type="number"
                                    value={monthToWeekRange?.valueFrom}
                                    onBlur={(e) => {
                                        if (!monthToWeekRange.valueFrom) {
                                            return;
                                        }

                                        if (monthToWeekRange.valueFrom < monthToWeekRange.developmentStage!!.validMonthFrom || monthToWeekRange.valueFrom > monthToWeekRange.developmentStage!!.validMonthTo) {
                                            setMonthToWeekRangeErrors({
                                                ...monthToWeekRangeErrors,
                                                valueFrom: `Month should be between ${monthToWeekRange.developmentStage?.validMonthFrom} and ${monthToWeekRange.developmentStage?.validMonthTo}`,
                                            })

                                            return;
                                        }

                                        setMonthToWeekRangeErrors({
                                            ...monthToWeekRangeErrors,
                                            valueFrom: '',
                                        })

                                        calculateWeekRangeByMonthAndSelectedDevelopmentStageMutation.mutate({
                                            developmentStageId: monthToWeekRange?.developmentStage?.id,
                                            month: monthToWeekRange.valueFrom,
                                        }, {
                                            onSuccess: (data) => {
                                                setMonthToWeekRange({
                                                    ...monthToWeekRange,
                                                    valueTo: `${data[0]} - ${data[1]}`,
                                                })
                                            }
                                        })
                                    }}
                                    onChange={(e) => {
                                        const month = parseFloat(e.target.value)

                                        setMonthToWeekRange({
                                            ...monthToWeekRange,
                                            valueFrom: month,
                                        })
                                    }}
                                    error={!!monthToWeekRangeErrors.valueFrom}
                                    helperText={monthToWeekRangeErrors.valueFrom}
                                    disabled={!monthToWeekRange?.developmentStage?.id}
                                />

                                <TextField
                                    label="Week Range"
                                    type="text"
                                    value={monthToWeekRange?.valueTo}
                                />
                            </Box>
                        </form>
                    </LoadingOverlay>
                </Box>

                <Box m="2em">
                    <LoadingOverlay active={calculateDueByWeekAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                    <Typography variant="h5">
                        Week to Due
                    </Typography>

                    <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                        <AutocompleteExt
                            name="developmentStageId"
                            multiSelection={false}
                            label="Development Stage..."
                            selectedValue={weekToDue?.developmentStage?.id}
                            onSelect={(value) => {
                                setWeekToDue({
                                    ...weekToDue,
                                    developmentStage: developmentStages?.find((each) => each.id === value),
                                    valueFrom: NaN,
                                    valueTo: '',
                                })
                            }}
                            options={developmentStages?.map((each) => ({value: each.id, label: each.type,}))}
                        />

                        <TextField
                            label={`${weekToDue.developmentStage?.id ? `Week (${weekToDue.developmentStage?.weekFrom} - ${weekToDue.developmentStage?.weekTo})` : 'Week'}`}
                            type="number"
                            value={weekToDue?.valueFrom}
                            onBlur={(e) => {
                                if (!weekToDue.valueFrom) {
                                    return;
                                }

                                if (weekToDue.valueFrom < weekToDue.developmentStage!!.weekFrom || weekToDue.valueFrom > weekToDue.developmentStage!!.weekTo) {
                                    setWeekToDueErrors({
                                        ...weekToDueErrors,
                                        valueFrom: `Week should be between ${weekToDue.developmentStage?.weekFrom} and ${weekToDue.developmentStage?.weekTo}`,
                                    })

                                    return;
                                }

                                setWeekToDueErrors({
                                    ...weekToDueErrors,
                                    valueFrom: '',
                                })

                                calculateDueByWeekAndSelectedDevelopmentStageMutation.mutate({
                                    developmentStageId: weekToDue?.developmentStage?.id,
                                    week: weekToDue.valueFrom,
                                }, {
                                    onSuccess: (data) => {
                                        setWeekToDue({
                                            ...weekToDue,
                                            valueTo: `${data}`,
                                        })
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setWeekToDue({
                                    ...weekToDue,
                                    valueFrom: parseFloat(e.target.value),
                                })
                            }}
                            error={!!weekToDueErrors.valueFrom}
                            helperText={weekToDueErrors.valueFrom}
                            disabled={!weekToDue?.developmentStage?.id}
                        />

                        <TextField
                            label="Due"
                            type="text"
                            value={weekToDue?.valueTo}
                        />

                    </Box>
                </LoadingOverlay>
                </Box>

                <Box m="2em">
                    <LoadingOverlay active={calculateMonthByWeekAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                        <Typography variant="h5">
                            Week to Month
                        </Typography>

                        <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                            <AutocompleteExt
                                name="developmentStageId"
                                multiSelection={false}
                                label="Development Stage..."
                                selectedValue={weekToMonth?.developmentStage?.id}
                                onSelect={(value) => {
                                    setWeekToMonth({
                                        ...weekToMonth,
                                        developmentStage: developmentStages?.find((each) => each.id === value),
                                        valueFrom: NaN,
                                        valueTo: '',
                                    })
                                }}
                                options={developmentStages?.filter(each => each.supportedMonthConversion).map((each) => ({value: each.id, label: each.type,}))}
                            />

                            <TextField
                                label={`${weekToDue.developmentStage?.id ? `Week (${weekToMonth.developmentStage?.weekFrom} - ${weekToMonth.developmentStage?.weekTo})` : 'Week'}`}
                                type="number"
                                value={weekToMonth?.valueFrom}
                                onBlur={(e) => {
                                    if (!weekToMonth.valueFrom) {
                                        return;
                                    }

                                    if (weekToMonth.valueFrom < weekToMonth.developmentStage!!.weekFrom || weekToMonth.valueFrom > weekToMonth.developmentStage!!.weekTo) {
                                        setWeekToMonthErrors({
                                            ...weekToMonthErrors,
                                            valueFrom: `Week should be between ${weekToMonth.developmentStage?.weekFrom} and ${weekToMonth.developmentStage?.weekTo}`,
                                        })

                                        return;
                                    }

                                    setWeekToMonthErrors({
                                        ...weekToMonthErrors,
                                        valueFrom: '',
                                    })

                                    calculateMonthByWeekAndSelectedDevelopmentStageMutation.mutate({
                                        developmentStageId: weekToMonth?.developmentStage?.id,
                                        week: weekToMonth.valueFrom,
                                    }, {
                                        onSuccess: (data) => {
                                            setWeekToMonth({
                                                ...weekToMonth,
                                                valueTo: `${data}`,
                                            })
                                        }
                                    })
                                }}
                                onChange={(e) => {
                                    setWeekToMonth({
                                        ...weekToMonth,
                                        valueFrom: parseFloat(e.target.value),
                                    })
                                }}
                                error={!!weekToMonthErrors.valueFrom}
                                helperText={weekToMonthErrors.valueFrom}
                                disabled={!weekToMonth?.developmentStage?.id}
                            />

                            <TextField
                                label="Month"
                                type="text"
                                value={weekToMonth?.valueTo}
                            />

                        </Box>
                    </LoadingOverlay>
                </Box>

                <Box m="2em">
                    <LoadingOverlay active={calculateDueByMonthAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                    <Typography variant="h5">
                        Month to Due
                    </Typography>

                    <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                        <AutocompleteExt
                            name="developmentStageId"
                            multiSelection={false}
                            label="Development Stage..."
                            selectedValue={monthToDue?.developmentStage?.id}
                            onSelect={(value) => {
                                setMonthToDue({
                                    ...monthToDue,
                                    developmentStage: developmentStages?.find((each) => each.id === value),
                                    valueFrom: NaN,
                                    valueTo: '',
                                })
                            }}
                            options={developmentStages?.filter((each) => each.supportedMonthConversion).map((each) => ({value: each.id, label: each.type,}))}
                        />

                        <TextField
                            label={`${monthToDue.developmentStage?.id ? `Month (${monthToDue.developmentStage?.validMonthFrom} - ${monthToDue.developmentStage?.validMonthTo})` : 'Month'}`}
                            type="number"
                            value={monthToDue?.valueFrom}
                            onBlur={(e) => {
                                if (!monthToDue.valueFrom) {
                                    return;
                                }

                                if (monthToDue.valueFrom < monthToDue.developmentStage!!.validMonthFrom || monthToDue.valueFrom > monthToDue.developmentStage!!.validMonthTo) {
                                    setMonthToDueErrors({
                                        ...monthToDueErrors,
                                        valueFrom: `Month should be between ${monthToDue.developmentStage?.validMonthFrom} and ${monthToDue.developmentStage?.validMonthTo}`,
                                    })

                                    return;
                                }

                                setMonthToDueErrors({
                                    ...monthToDueErrors,
                                    valueFrom: '',
                                })

                                calculateDueByMonthAndSelectedDevelopmentStageMutation.mutate({
                                    developmentStageId: monthToDue?.developmentStage?.id,
                                    month: monthToDue.valueFrom,
                                }, {
                                    onSuccess: (data) => {
                                        setMonthToDue({
                                            ...monthToDue,
                                            valueTo: `${data}`,
                                        })
                                    }
                                })

                            }}
                            onChange={(e) => {
                                const month = parseFloat(e.target.value)
                                setMonthToDue({
                                    ...monthToDue,
                                    valueFrom: month,
                                })
                            }}
                            error={!!monthToDueErrors.valueFrom}
                            helperText={monthToDueErrors.valueFrom}
                            disabled={!monthToDue?.developmentStage?.id}
                        />

                        <TextField
                            label="Due"
                            type="text"
                            value={monthToDue?.valueTo}
                        />

                    </Box>
                </LoadingOverlay>
                </Box>

                <Box m="2em">
                    <LoadingOverlay active={calculateMonthByDueAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                    <Typography variant="h5">
                        Due to Month
                    </Typography>

                    <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                        <AutocompleteExt
                            name="developmentStageId"
                            multiSelection={false}
                            label="Development Stage..."
                            selectedValue={dueToMonth?.developmentStage?.id}
                            onSelect={(value) => {
                                setDueToMonth({
                                    ...dueToMonth,
                                    developmentStage: developmentStages?.find((each) => each.id === value),
                                    valueFrom: NaN,
                                    valueTo: '',
                                })
                            }}
                            options={developmentStages?.filter((each) => each.supportedMonthConversion).map((each) => ({value: each.id, label: each.type,}))}
                        />

                        <TextField
                            label={`${dueToMonth.developmentStage?.id ? `Due (${((dueToMonth.developmentStage?.weekFrom!! - 1) * 7) + 1} - ${dueToMonth.developmentStage?.weekTo!! * 7})` : 'Due'}`}
                            type="number"
                            value={dueToMonth?.valueFrom}
                            onBlur={(e) => {
                                console.log(dueToMonth.valueFrom)
                                if (!dueToMonth.valueFrom) {
                                    return;
                                }

                                const estimatedMonth = calculateMonthNumber(dueToMonth.valueFrom, dueToMonth.developmentStage!!);
                                if (estimatedMonth === -1 || !(estimatedMonth >= dueToMonth.developmentStage!!.validMonthFrom || estimatedMonth <= dueToMonth.developmentStage!!.validMonthTo)) {
                                    setDueToMonthErrors({
                                        ...dueToMonthErrors,
                                        valueFrom: `Due should be between ${((dueToMonth.developmentStage?.weekFrom!! - 1) * 7) + 1} and ${dueToMonth.developmentStage?.weekTo!! * 7}`,
                                    })

                                    return;
                                }

                                setDueToMonthErrors({
                                    ...dueToMonthErrors,
                                    valueFrom: '',
                                })

                                calculateMonthByDueAndSelectedDevelopmentStageMutation.mutate({
                                    developmentStageId: dueToMonth?.developmentStage?.id,
                                    due: dueToMonth.valueFrom,
                                }, {
                                    onSuccess: (data) => {
                                        setDueToMonth({
                                            ...dueToMonth,
                                            valueTo: `${data[0]}`,
                                        })
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setDueToMonth({
                                    ...dueToMonth,
                                    valueFrom: parseFloat(e.target.value),
                                })
                            }}
                            error={!!dueToMonthErrors.valueFrom}
                            helperText={dueToMonthErrors.valueFrom}
                            disabled={!dueToMonth?.developmentStage?.id}
                        />

                        <TextField
                            label="Month"
                            type="text"
                            value={dueToMonth?.valueTo}
                        />

                    </Box>
                </LoadingOverlay>
                </Box>

                <Box m="2em">
                    <LoadingOverlay active={calculateWeekByDueAndSelectedDevelopmentStageMutation.isLoading} spinner text="Loading...">
                    <Typography variant="h5">
                        Due to Week
                    </Typography>

                    <Box gap="10px" display="grid" gridTemplateColumns="1fr 1fr 1fr">
                        <AutocompleteExt
                            name="developmentStageId"
                            multiSelection={false}
                            label="Development Stage..."
                            selectedValue={dueToWeek?.developmentStage?.id}
                            onSelect={(value) => {
                                setDueToWeek({
                                    ...dueToWeek,
                                    developmentStage: developmentStages?.find((each) => each.id === value),
                                    valueFrom: NaN,
                                    valueTo: '',
                                })
                            }}
                            options={developmentStages?.map((each) => ({value: each.id, label: each.type,}))}
                        />

                        <TextField
                            label={`${dueToWeek.developmentStage?.id ? `Due (${((dueToWeek.developmentStage?.weekFrom!! - 1) * 7) + 1} - ${dueToWeek.developmentStage?.weekTo!! * 7})` : 'Due'}`}
                            type="number"
                            value={dueToWeek?.valueFrom}
                            onBlur={(e) => {
                                if (!dueToWeek.valueFrom) {
                                    return;
                                }

                                const week = calculateWeekNumber(dueToWeek.valueFrom);

                                if (week < dueToWeek.developmentStage!!.weekFrom || week > dueToWeek.developmentStage!!.weekTo) {
                                    setDueToWeekErrors({
                                        ...dueToWeekErrors,
                                        valueFrom: `Due should be between ${((dueToWeek.developmentStage?.weekFrom!! - 1) * 7) + 1} and ${dueToWeek.developmentStage?.weekTo!! * 7}`,
                                    })

                                    return;
                                }

                                setDueToWeekErrors({
                                    ...dueToWeekErrors,
                                    valueFrom: '',
                                })

                                calculateWeekByDueAndSelectedDevelopmentStageMutation.mutate({
                                    developmentStageId: dueToWeek?.developmentStage?.id,
                                    due: dueToWeek.valueFrom,
                                }, {
                                    onSuccess: (data) => {
                                        setDueToWeek({
                                            ...dueToWeek,
                                            valueTo: `${data[0]}`,
                                        })
                                    }
                                })
                            }}
                            onChange={(e) => {
                                setDueToWeek({
                                    ...dueToWeek,
                                    valueFrom: parseFloat(e.target.value),
                                })
                            }}
                            error={!!dueToWeekErrors.valueFrom}
                            helperText={dueToWeekErrors.valueFrom}
                            disabled={!dueToWeek?.developmentStage?.id}
                        />

                        <TextField
                            label="Week"
                            type="text"
                            value={dueToWeek?.valueTo}
                        />
                    </Box>
                </LoadingOverlay>
                </Box>
            </Box>
        </Box>
    )
}

export default UnitOfMeasureConversion