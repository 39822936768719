import {LOAD_MY_USER_SETTING} from "../actionType";

/**
 * Default state declaration
 */
const initialState = {
    data: undefined
}

/**
 * Load user setting redux state through reducer
 *
 * @param {*} state - immutable state for page/ component update when there is changes depending on action
 * @param {*} action - an action contains type of changes, and payload for update
 * @returns
 */
export default function userSetting(state = initialState, action) {
    switch (action.type) {
        case LOAD_MY_USER_SETTING: {
            return Object.assign({}, state, {
                data: action.payload,
            })
        }
        default:
            return state
    }
}
