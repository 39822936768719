import React, { useEffect, useState } from 'react'
import {
    Box, Checkbox,
    IconButton,
    TablePagination,
    TableSortLabel,
    useTheme,
} from '@mui/material'
import { tokens } from '../theme'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface DataGridProp {
    keyField: string
    multiSelect?: boolean
    columns: DataGridColumnDescription[]
    data: any[]
    expandRow: (row: any) => any
    selectedRow?: (rows: any[]) => void
    rowPerPageOptions: number[]
    paginationFactory?: PaginationFactory | undefined
    onTableChange: (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => void
}

export interface PaginationFactory {
    page: number
    totalSize: number
    sizePerPage: number
}

export interface DataGridColumnDescription {
    dataField: string
    conversion?: string | undefined
    text: string
    converter?: (v: any) => any
    sort?: boolean | undefined
}

export interface TablePaginationChangeProp {
    page: number
    sizePerPage: number
    sortField?: string | undefined
    sortOrder?: string | undefined
}

function DataGrid({
                      keyField,
                      multiSelect = false,
                      columns,
                      data,
                      expandRow,
                      selectedRow,
                      rowPerPageOptions,
                      paginationFactory,
                      onTableChange,
                  }: DataGridProp) {
    const [selectedAll, setSelectedAll] = useState<boolean>(false)
    const [selectedRows, setSelectedRows] = useState<any[]>([])
    const [expandedRows, setExpandedRows] = useState<number[]>([])
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(rowPerPageOptions[0])
    const [sortField, setSortField] = useState<string | undefined>()
    const [sortOrder, setSortOrder] = useState<string | undefined>()

    useEffect(() => {
        setExpandedRows([])
        clearSelectedAll()
    }, [data])

    const toggleExpendedRow = (index: number) => {
        if (expandedRows.includes(index)) {
            setExpandedRows([])
        } else {
            setExpandedRows([index])
        }
    }

    const toggleSelectedAll = () => {
        const checked = !selectedAll
        setSelectedAll(checked)
        const selected = checked ? data : []
        setSelectedRows(selected);

        if (selectedRow) {
            selectedRow(selected)
        }
    }

    const clearSelectedAll = () => {
        setSelectedAll(false)
        setSelectedRows([]);

        if (selectedRow) {
            selectedRow([])
        }
    }

    const toggleSelectedRow = (row: any) => {
        let selected = selectedRows
        if (selectedRows.includes(row)) {
            selected = selected.filter((r) => r !== row)
            setSelectedRows(selected);
        } else {
            selected = [...selectedRows, row]
            setSelectedRows(selected);
        }

        if (selectedRow) {
            selectedRow(selected)
        }
    }

    const onInternalTableChange = (
        type: string,
        valueChange: TablePaginationChangeProp
    ) => {
        if (type === 'pagination') {
            setPage(valueChange.page)
            setSizePerPage(valueChange.sizePerPage)
        }

        if (type === 'sort') {
            valueChange.sortOrder = 'asc'
            if (sortOrder && sortField === valueChange.sortField) {
                valueChange.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc'
            }
            setSortField(valueChange.sortField)
            setSortOrder(valueChange.sortOrder)
        }

        clearSelectedAll()

        onTableChange(type, valueChange)
    }

    const extractNestedField = (row: any, dataField: string, conversion?: string | undefined, converter?: (v: any) => any) => {
        const fields = dataField.split('.');

        let value = row;
        for (let field of fields) {
            value = value[field];

            if (value === undefined) {
                return null;
            }

            if (conversion) {
                if (value instanceof Array) {
                    value = value.map(each => each[conversion])
                } else {
                    value = value[conversion]
                }
            }
        }

        if (converter) {
            return converter(value)
        }

        return value;
    };

    return (
        <Box m="20px">
            <Box
                m="40px 0 0 0"
                sx={{
                    '& .MuiCheckbox-root, .Mui-checked': {
                        color: `${colors
                            .greenAccent[300]} !important`,
                    },
                    '& .MuiPaper-TableContainer': {
                        minHeight: '50vh',
                    },
                    '& .MuiTableContainer-root': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.primary[500]
                                : colors.primary[400],
                    },
                    '& .MuiTableCell-root': {
                        border: 'none',
                        borderBottom: `1px solid ${
                            theme.palette.mode === 'dark'
                                ? colors.grey[500]
                                : colors.grey[800]
                        }`,
                    },
                    '& .MuiTableCell-head': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                    '& .MuiTableRowMain-root': {
                        cursor: 'pointer',
                    },
                    '& .MuiTableRowMain-root:hover': {
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? colors.primary[500]
                                : colors.primary[400],
                        filter: `brightness(${
                            theme.palette.mode === 'dark' ? '200%' : '95%'
                        })`,
                        cursor: 'pointer',
                    },
                    '& .MuiTablePagination-root': {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: 'none',
                    },
                }}
            >
                <TableContainer
                    className="MuiPaper-TableContainer"
                    key={keyField}
                    component={Paper}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                {multiSelect && (
                                    <TableCell
                                        style={{ width: `10px` }}>
                                        <Checkbox
                                            checked={selectedAll}
                                            onChange={(event) => toggleSelectedAll()}
                                            disabled={data?.length === 0}
                                        />
                                    </TableCell>
                                )}
                                <TableCell
                                    style={{ width: `10px` }}
                                ></TableCell>
                                {columns &&
                                    columns.map((column) => {
                                        if (column.sort) {
                                            return (
                                                <TableCell
                                                    key={column.dataField}
                                                    sortDirection={
                                                        sortOrder === 'desc'
                                                            ? 'desc'
                                                            : sortOrder ===
                                                            'asc'
                                                                ? 'asc'
                                                                : undefined
                                                    }
                                                >
                                                    <TableSortLabel
                                                        active={
                                                            sortField ===
                                                            column.dataField
                                                        }
                                                        direction={
                                                            sortOrder === 'desc'
                                                                ? 'desc'
                                                                : sortOrder ===
                                                                'asc'
                                                                    ? 'asc'
                                                                    : undefined
                                                        }
                                                        onClick={() =>
                                                            onInternalTableChange(
                                                                'sort',
                                                                {
                                                                    page: page,
                                                                    sizePerPage:
                                                                    sizePerPage,
                                                                    sortField:
                                                                    column.dataField,
                                                                }
                                                            )
                                                        }
                                                    >
                                                        {column.text}
                                                    </TableSortLabel>
                                                </TableCell>
                                            )
                                        } else {
                                            return (
                                                <TableCell>
                                                    {column.text}
                                                </TableCell>
                                            )
                                        }
                                    })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <React.Fragment key={row.id}>
                                    <TableRow
                                        className="MuiTableRowMain-root"
                                    >
                                        {multiSelect && (
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedRows.includes(row)}
                                                    onChange={() => toggleSelectedRow(row)}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell onClick={() => toggleExpendedRow(index)}>
                                            <IconButton
                                                style={{ padding: `0` }}
                                            >
                                                {(expandedRows.length === 0 ||
                                                    index !==
                                                    expandedRows[0]) && (
                                                    <KeyboardArrowRightIcon />
                                                )}
                                                {expandedRows.length > 0 &&
                                                    index ===
                                                    expandedRows[0] && (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                            </IconButton>
                                        </TableCell>
                                        {columns &&
                                            columns.map((column, index) => {
                                                const value = extractNestedField(row, column.dataField, column.conversion, column.converter);
                                                return (
                                                    <>
                                                        {index === 0 && (
                                                            <TableCell
                                                                style={{
                                                                    color: colors
                                                                        .greenAccent[300],
                                                                }}
                                                            >
                                                                {value !=
                                                                    null &&
                                                                    String(
                                                                        value
                                                                    )}
                                                            </TableCell>
                                                        )}
                                                        {index > 0 && (
                                                            <TableCell>
                                                                {value !=
                                                                    null &&
                                                                    String(
                                                                        value
                                                                    )}
                                                            </TableCell>
                                                        )}
                                                    </>
                                                )
                                            })}
                                    </TableRow>
                                    {expandedRows.includes(index) && (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length + 1}
                                            >
                                                {expandRow(row)}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {paginationFactory && (
                    <TablePagination
                        rowsPerPageOptions={rowPerPageOptions}
                        component="div"
                        count={paginationFactory.totalSize}
                        rowsPerPage={paginationFactory.sizePerPage}
                        page={paginationFactory.page - 1}
                        onPageChange={(
                            event: React.MouseEvent<HTMLButtonElement> | null,
                            page: number
                        ) =>
                            onInternalTableChange('pagination', {
                                page: page + 1,
                                sizePerPage: sizePerPage,
                            })
                        }
                        onRowsPerPageChange={(
                            event: React.ChangeEvent<
                                HTMLTextAreaElement | HTMLInputElement
                                >
                        ) =>
                            onInternalTableChange('pagination', {
                                page: 1,
                                sizePerPage: parseInt(event.target.value),
                            })
                        }
                    />
                )}
            </Box>
        </Box>
    )
}

export default DataGrid
