import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../../../components/DataGridFilter'
import Header from '../../../../../../components/Header'
import { Box } from '@mui/material'
import {AwsLocaleMapping} from '../../../../../../interfaces/LocalizationType'
import AwsLocaleMappingDetail from "./detail";
import {fetchAwsLocaleMappings} from "../../../../../../actions/localization";

/**
 * Aws Locale Mapping list
 *
 * @returns locale mapping list page
 */
function AwsLocaleMappings() {
    const expandRow = (row: AwsLocaleMapping) => (
        <AwsLocaleMappingDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchAwsLocaleMappings(searchOptions)
    }

    const columns = [
        {
            dataField: 'locale.code',
            text: 'Locale Code',
        },
        {
            dataField: 'languageCode',
            text: 'Language Code Mapping',
            sort: true,
        }
    ]

    /**
     * Render the locale mapping through Table component
     */
    return (
        <Box m="20px">
            <Header title="Aws Locale Mappings" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`aws-locale-mappings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default AwsLocaleMappings