import React, {useState} from 'react'
import {Box, TextField} from '@mui/material'
import {connect} from "react-redux";
import {fetchDefaultContentReferenceMappings} from "../../../../../../actions/contentAssists";
import {
    DefaultContentReferenceMapping, DefaultContentReferenceMappingFilterCustomFilterOptions
} from "../../../../../../interfaces/ContentAssistType";
import DefaultContentReferenceMappingDetail from "./detail";
import {TenantSetting} from "../../../../../../interfaces/SettingType";
import DataGridFilter, {SearchOptionsProp} from "../../../../../../components/DataGridFilter";
import Header from "../../../../../../components/Header";

/**
 * Locale list
 *
 * @returns locale list page
 */
function DefaultContentReferenceMappings(props: {userSetting: TenantSetting}) {

    const {userSetting} = props

    const [customSearchOptions, setCustomSearchOptions] =
        useState<DefaultContentReferenceMappingFilterCustomFilterOptions>({
            filteredByDue: NaN,
        })

    const expandRow = (row: DefaultContentReferenceMapping) => (
        <DefaultContentReferenceMappingDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchDefaultContentReferenceMappings(searchOptions)
    }

    const customSearchOptionsRenderer = () => (
        <TextField
            type="number"
            variant="filled"
            name="filteredByDue"
            value={customSearchOptions.filteredByDue}
            label="Filtered by due..."
            InputProps={{
                inputProps: {
                    min: 1,
                },
            }}
            onChange={(event) => {
                setCustomSearchOptions({
                    ...customSearchOptions,
                    filteredByDue: parseInt(event.target.value),
                })
            }}
        />
    )

    const columns = [
        {
            dataField: 'contentType',
            text: 'Content Type',
            sort: false,
            converter: (value: any) => {
                return value.type
            }
        },
        {
            dataField: `eligibleRangeStart`,
            text: `Eligible Range Start`,
            sort: true,
        },
        {
            dataField: `eligibleRangeEnd`,
            text: `Eligible Range End`,
            sort: true,
        },
        {
            dataField: 'referenceItems',
            text: 'Reference Urls',
            sort: false,
            converter: (value: any) => {
                const result = value.map((item: any) => {
                    return item.text + ' ' + item.urls.join(' ');
                }).join(', ');

                const charLimit = 50;

                const limitedResult = result.length > charLimit ? `${result.substring(0, charLimit)}...` : result;

                return limitedResult;
            }
        },
    ]

    /**
     * Render the default content reference mapping through Table component
     */
    return (
        <Box m="20px">
            <Header title="Default Content Reference Mappings" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`defaultContentReferenceMappings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                customSearchOptions={customSearchOptions}
                resetCustomSearchOptions={setCustomSearchOptions}
                customSearchOptionsRenderer={customSearchOptionsRenderer()}
                searchFilterCols={3}
                disabledMatchAll={true}
                createPageUrl="/default/content/reference/mapping/create"
                hasCreatePermission={userSetting?.administratorRolePermission?.contentAssistPermission?.editable}
                expandRow={expandRow}
            />
        </Box>
    )
}

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(DefaultContentReferenceMappings)