import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material'
import { useContext, useState } from 'react'
import { ColorModeContext, tokens } from '../../theme'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import {AuthToken, constructAuthRedirectUrl, signout, transit} from '../../actions/auth'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Tenant } from '../../interfaces/TenantType'
import AutocompleteExt from "../../components/Autocomplete";
import {TenantSetting} from "../../interfaces/SettingType";

const Topbar = (props: { user: AuthToken, switchTenant: Tenant, userSetting: TenantSetting }) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const colorMode = useContext(ColorModeContext)
    const { user, switchTenant, userSetting } = props
    const navigate = useNavigate()
    const [profileAnchorEl, setProfileAnchorEl] = useState(null)
    const [settingAnchorEl, setSettingAnchorEl] = useState(null)

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* Switch Bar */}
            <Box
                display="flex"
                borderRadius="3px"
                sx={{
                    backgroundColor: colors.primary[400],
                    width: `auto`,

                    "& .MuiFilledInput-root .MuiFilledInput-input": {
                        width: `100% !important`
                    },
                }}
            >
                <AutocompleteExt
                    name='dashboards'
                    multiSelection={false}
                    label='Switch Dashboard'
                    options={user?.user?.dashboards?.map(dashboard => {
                        return {
                            label: dashboard.name,
                            value: dashboard.url
                        }
                    })}
                    onSelect={async (v) => {
                        const data = await transit(user)
                        const authTransitRedirectUrl = constructAuthRedirectUrl(data.code, v + "/transit")
                        window.location.replace(authTransitRedirectUrl)
                    }}
                    editable={user?.user?.dashboards && user.user.dashboards.length > 0}
                />
            </Box>

            {/* Icons */}

            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === 'dark' ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>

                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton
                    onClick={(e: any) => setSettingAnchorEl(e.currentTarget)}
                >
                    <SettingsOutlinedIcon />
                </IconButton>
                {userSetting?.administratorRolePermission?.userSettingPermission?.enabled && (
                    <Menu
                        id="setting-menu"
                        anchorEl={settingAnchorEl}
                        keepMounted
                        open={Boolean(settingAnchorEl)}
                        onClose={() => setSettingAnchorEl(null)}
                    >
                        <Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{ m: '5px 0 5px 20px' }}
                        >
                            Setting
                        </Typography>

                        <MenuItem
                            onClick={() => navigate('/setting/tenant')}
                            key={`${switchTenant ? 'Tenant' : 'User'}`}
                        >
                            <SettingsAccessibilityIcon /> &nbsp; &nbsp;{' '}
                            {`${switchTenant ? 'Tenant' : 'User'}`}
                        </MenuItem>
                    </Menu>
                )}
                <IconButton
                    onClick={(e: any) => setProfileAnchorEl(e.currentTarget)}
                >
                    <PersonOutlinedIcon />
                </IconButton>
                <Menu
                    id="profile-menu"
                    anchorEl={profileAnchorEl}
                    keepMounted
                    open={Boolean(profileAnchorEl)}
                    onClose={() => setProfileAnchorEl(null)}
                >
                    <MenuItem onClick={() => signout()} key="Logout">
                        <ExitToAppIcon /> &nbsp; &nbsp; Logout
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { user: state.user.user, switchTenant: state.switchTenant.tenant, userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(Topbar)
