import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import {Formik} from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {connect} from "react-redux";
import {
    ContentTenantMilestoneDevelopmentalMapping,
    ContentTenantMilestoneDevelopmentalMappingRequest,
    ContentTenantMilestoneDevelopmentalMappingWrapper
} from "../../../../../../../interfaces/ContentAssistType";
import {useAuthQueryWithQueryFunction} from "../../../../../../../extensions/UseAuthQuery";
import {ApiError} from "../../../../../../../interfaces/ErrorType";
import {Tenant} from "../../../../../../../interfaces/TenantType";
import {fetchAllTenants} from "../../../../../../../actions/tenant";
import {fetchDevelopmentStages} from "../../../../../../../actions/developmentStage";
import {
    createContentTenantMilestoneDevelopmentalMapping,
    fetchRelationships, updateContentTenantMilestoneDevelopmentalMapping
} from "../../../../../../../actions/contentAssists";
import ErrorMessage from "../../../../../../../components/ErrorMessage";
import AutocompleteExt from "../../../../../../../components/Autocomplete";
import TagInputExt from "../../../../../../../components/TagInput";
import CopyToClipboard from "react-copy-to-clipboard";
import ButtonExt from "../../../../../../../components/ButtonExt";
import Header from "../../../../../../../components/Header";

const contentTenantMilestoneDevelopmentalMappingSchema = yup.object().shape({
    tenantId: yup.string().required('required'),
    eligibleRangeStart: yup.number().min(1).required('required'),
    eligibleRangeEnd: yup.number().min(1).required('required'),
    developmentStageId: yup.string().required('required'),
    relationshipId: yup.string().required('required'),
    developmentalMilestones: yup.array().min(1).required('required'),
})

const ContentTenantMilestoneDevelopmentalMappingDetail: FunctionComponent<ContentTenantMilestoneDevelopmentalMappingWrapper> = ({
                                                                                                        userSetting,
                                                                                                        isNew = false,
                                                                                                        wrapper,
                                                                                                    }) => {
    const navigate = useNavigate()
    const [contentTenantMilestoneDevelopmentalMappingRequest, setContentTenantMilestoneDevelopmentalMappingRequest] = useState<ContentTenantMilestoneDevelopmentalMappingRequest>(
        wrapper
            ? {
                ...wrapper,
                tenantId: wrapper.tenant!!.id!!,
                developmentStageId: wrapper.developmentStage?.id,
                relationshipId: wrapper.relationship?.id,
            }
            : {
                id: undefined,
                tenantId: undefined,
                developmentStageId: undefined,
                relationshipId: undefined,
                eligibleRangeStart: undefined,
                eligibleRangeEnd: undefined,
                developmentalMilestones: [],
            }
    )

    /**
     * Mutate content tenant milestone developmental mapping create
     */
    const contentTenantMilestoneDevelopmentalMappingCreateMutation = useMutation<ContentTenantMilestoneDevelopmentalMapping, ApiError, ContentTenantMilestoneDevelopmentalMappingRequest>(
        createContentTenantMilestoneDevelopmentalMapping,
        {
            onSuccess: (data) => {
                setContentTenantMilestoneDevelopmentalMappingRequest({
                    ...data,
                    tenantId: data.tenant!!.id!!
                })
            },
        }
    )

    /**
     * Mutate default content tenant milestone developmental mapping update
     */
    const contentTenantMilestoneDevelopmentalMappingUpdateMutation = useMutation<ContentTenantMilestoneDevelopmentalMapping, ApiError, ContentTenantMilestoneDevelopmentalMappingRequest>(
        updateContentTenantMilestoneDevelopmentalMapping,
        {
            onSuccess: (data) => {
                setContentTenantMilestoneDevelopmentalMappingRequest({
                    ...data,
                    tenantId: data.tenant!!.id!!
                })
            },
        }
    )

    /**
     * Fetch all tenants
     * */
    const tenantQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Tenant[]
        >('tenants', fetchAllTenants, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch relationship list
     * */
    const contentRelationshipQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentRelationships', fetchRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTenantOptions = tenantQuery.data?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant.id,
        }
    })

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    })

    const availableRelationshipOptions = contentRelationshipQuery.data?.map((contentRelationship) => {
        return {
            value: contentRelationship.id,
            label: contentRelationship.name,
        }
    })

    if (contentTenantMilestoneDevelopmentalMappingCreateMutation.isSuccess) {
        navigate(`/content/tenant/milestone/developmental/mapping`)
    }

    /**
     * Invoke an action to create/ update content tenant milestone developmental mapping
     * @param {*} e - event
     */
    const onSave = (values: ContentTenantMilestoneDevelopmentalMappingRequest) => {
        if (!values.id) {
            contentTenantMilestoneDevelopmentalMappingCreateMutation.mutate(values)
        } else {
            contentTenantMilestoneDevelopmentalMappingUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing content tenant milestone developmental mapping detail
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/content/tenant/milestone/developmental/mapping`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Content Tenant Milestone Developmental Mapping" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {contentTenantMilestoneDevelopmentalMappingCreateMutation.isError && (
                    <ErrorMessage error={contentTenantMilestoneDevelopmentalMappingCreateMutation.error} />
                )}

                {contentTenantMilestoneDevelopmentalMappingUpdateMutation.isError && (
                    <ErrorMessage error={contentTenantMilestoneDevelopmentalMappingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={contentTenantMilestoneDevelopmentalMappingRequest}
                validationSchema={contentTenantMilestoneDevelopmentalMappingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            mb="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {contentTenantMilestoneDevelopmentalMappingRequest.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <AutocompleteExt
                                name="developmentStageId"
                                multiSelection={false}
                                label="Development Stage..."
                                selectedValue={values.developmentStageId}
                                onSelect={(value) => {
                                    setContentTenantMilestoneDevelopmentalMappingRequest({
                                        ...contentTenantMilestoneDevelopmentalMappingRequest,
                                        developmentStageId: value
                                    })
                                    values.developmentStageId = value
                                }}
                                options={availableDevelopmentStageOptions}
                                error={!!touched.developmentStageId && !!errors.developmentStageId}
                                helperText={touched.developmentStageId && errors.developmentStageId}
                            />

                            <AutocompleteExt
                                name="relationshipId"
                                multiSelection={false}
                                label="Relationship..."
                                selectedValue={values.relationshipId}
                                onSelect={(value) => {
                                    setContentTenantMilestoneDevelopmentalMappingRequest({
                                        ...contentTenantMilestoneDevelopmentalMappingRequest,
                                        relationshipId: value
                                    })
                                    values.relationshipId = value
                                }}
                                options={availableRelationshipOptions}
                                error={!!touched.relationshipId && !!errors.relationshipId}
                                helperText={touched.relationshipId && errors.relationshipId}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range Start"
                                value={values.eligibleRangeStart}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeStart"
                                error={!!touched.eligibleRangeStart && !!errors.eligibleRangeStart}
                                helperText={touched.eligibleRangeStart && errors.eligibleRangeStart}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range End"
                                value={values.eligibleRangeEnd}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeEnd"
                                error={!!touched.eligibleRangeEnd && !!errors.eligibleRangeEnd}
                                helperText={touched.eligibleRangeEnd && errors.eligibleRangeEnd}
                            />

                            <AutocompleteExt
                                name="tenantId"
                                multiSelection={false}
                                label="Tenant"
                                selectedValue={values.tenantId}
                                options={availableTenantOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setContentTenantMilestoneDevelopmentalMappingRequest({
                                        ...contentTenantMilestoneDevelopmentalMappingRequest,
                                        tenantId: v
                                    })
                                    values.tenantId = v
                                }}
                                error={!!touched.tenantId && !!errors.tenantId}
                                helperText={touched.tenantId && errors.tenantId}
                                editable={!contentTenantMilestoneDevelopmentalMappingRequest.id}
                            />
                        </Box>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(1, minmax(0,1fr))"
                        >
                            <Box display="grid" gridTemplateColumns="1fr 50px">
                                <TagInputExt
                                    name="developmentalMilestones"
                                    label="Developmental Milestones"
                                    values={values.developmentalMilestones?.map((developmentalMilestone) => {
                                        return {
                                            id: developmentalMilestone,
                                            text: developmentalMilestone,
                                        }
                                    })}
                                    onItemAdd={(v) => {
                                        setContentTenantMilestoneDevelopmentalMappingRequest({
                                            ...contentTenantMilestoneDevelopmentalMappingRequest,
                                            developmentalMilestones: [...contentTenantMilestoneDevelopmentalMappingRequest.developmentalMilestones, v.text]
                                        })
                                        values.developmentalMilestones = [...values.developmentalMilestones, v.text]
                                    }}
                                    onItemDelete={(index) => {
                                        const newDevelopmentalMilestones = contentTenantMilestoneDevelopmentalMappingRequest.developmentalMilestones.filter((_, i) => i !== index)
                                        setContentTenantMilestoneDevelopmentalMappingRequest({
                                            ...contentTenantMilestoneDevelopmentalMappingRequest,
                                            developmentalMilestones: newDevelopmentalMilestones
                                        })
                                        values.developmentalMilestones = newDevelopmentalMilestones
                                    }}
                                    allowDragDrop={true}
                                    onItemDrag={(v, currentIndex, dragIndex) => {
                                        const newDevelopmentalMilestones = contentTenantMilestoneDevelopmentalMappingRequest.developmentalMilestones
                                        newDevelopmentalMilestones.splice(currentIndex, 1)
                                        newDevelopmentalMilestones.splice(dragIndex, 0, v.text)
                                        setContentTenantMilestoneDevelopmentalMappingRequest({
                                            ...contentTenantMilestoneDevelopmentalMappingRequest,
                                            developmentalMilestones: newDevelopmentalMilestones
                                        })
                                        values.developmentalMilestones = newDevelopmentalMilestones
                                    }}
                                    error={!!touched.developmentalMilestones && !!errors.developmentalMilestones}
                                    helperText={touched.developmentalMilestones && errors.developmentalMilestones}
                                    required={true}
                                />
                                {values.developmentalMilestones.length > 0 && (
                                    <CopyToClipboard text={values.developmentalMilestones.join(",")}>
                                        <IconButton color="secondary">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </CopyToClipboard>
                                )}
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    contentTenantMilestoneDevelopmentalMappingCreateMutation.isLoading ||
                                    contentTenantMilestoneDevelopmentalMappingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    (
                                        contentTenantMilestoneDevelopmentalMappingCreateMutation.isLoading
                                        || contentTenantMilestoneDevelopmentalMappingUpdateMutation.isLoading
                                        || !userSetting?.administratorRolePermission?.contentAssistPermission?.editable
                                    )
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentTenantMilestoneDevelopmentalMappingDetail)