import SecureAxios from "../extensions/SecureAxios";

/**
 * Fetch report status with retry
 * @param reportId
 * @returns {Promise<any>}
 */
export const fetchReportStatusWithRetry = async (reportId) => {
    const retryInterval = 5000; // 5 seconds

    const fetchStatus = async () => {
        const { data } = await SecureAxios.get(`/admin/csv/report/status?reportId=${reportId}`);

        if (data && data.data && data.data.status === 'SUCCESS') {
            // If status is successful, resolve the promise
            return data;
        } else {
            // If status is not successful, retry after the interval
            return new Promise(resolve => setTimeout(resolve, retryInterval)).then(fetchStatus);
        }
    };

    return fetchStatus();
};