import React from 'react';
import { TextField, Box } from '@mui/material';
import {GeneralServerConfig} from "../../../../../interfaces/ServerConfigType";
import * as yup from "yup";
import ButtonExt from "../../../../../components/ButtonExt";
import {Formik} from "formik";
import {connect} from "react-redux";

const generalServerConfigSchema = yup.object().shape({
    lockReleaseInSeconds: yup.number().required('required'),
    cacheReleaseInSeconds: yup.number().required('required'),
    superAdminEmail: yup.string().required('required'),
    platformScopeId: yup.string().required('required'),
})

const GeneralServerConfigFC: React.FC<GeneralServerConfig> = ({
                                                                  userSetting,
                                                                  key,
                                                                  value,
                                                                  setValue,
                                                                  onChange,
                                                                  isLoading,
                                                                }) => {

    return (
        <Formik
            onSubmit={onChange}
            initialValues={value}
            validationSchema={generalServerConfigSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    >
                        <TextField
                            variant="filled"
                            type="number"
                            label="Lock Release In Seconds"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lockReleaseInSeconds}
                            name="lockReleaseInSeconds"
                            error={!!touched.lockReleaseInSeconds && !!errors.lockReleaseInSeconds}
                            helperText={touched.lockReleaseInSeconds && errors.lockReleaseInSeconds}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Cache Release In Seconds"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cacheReleaseInSeconds}
                            name="cacheReleaseInSeconds"
                            error={!!touched.cacheReleaseInSeconds && !!errors.cacheReleaseInSeconds}
                            helperText={touched.cacheReleaseInSeconds && errors.cacheReleaseInSeconds}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Super Admin Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.superAdminEmail}
                            name="superAdminEmail"
                            error={!!touched.superAdminEmail && !!errors.superAdminEmail}
                            helperText={touched.superAdminEmail && errors.superAdminEmail}
                        />

                        <TextField
                            variant="filled"
                            type="text"
                            label="Platform Scope Id"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.platformScopeId}
                            name="platformScopeId"
                            error={!!touched.platformScopeId && !!errors.platformScopeId}
                            helperText={touched.platformScopeId && errors.platformScopeId}
                        />

                    </Box>
                    <Box
                        display="flex"
                        justifyContent="end"
                        mt="20px"
                        gap="20px"
                    >
                        <ButtonExt
                            type="submit"
                            value={isLoading ? 'Saving' : 'Save'}
                            disabled={
                                isLoading ||
                                !userSetting?.administratorRolePermission?.serverConfigPermission?.editable
                            }
                        />
                    </Box>
                </form>
            )}
        </Formik>
    );
};

/**
 * Connect and retrieve redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(GeneralServerConfigFC)
