import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../components/DataGridFilter'
import Header from '../../../components/Header'
import { Box } from '@mui/material'
import {AuthUser} from "../../../interfaces/UserType";
import {fetchUsers} from "../../../actions/user";
import UserDetail from "./detail";

/**
 * User list
 *
 * @returns user list page
 */
function Users() {
    const expandRow = (row: AuthUser) => (
        <UserDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchUsers(searchOptions)
    }

    const columns = [
        {
            dataField: 'username',
            text: 'Username',
            sort: true,
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {
            dataField: 'phoneNumber',
            text: 'Phone Number',
        },
        {
            dataField: 'enabled',
            text: 'Enabled',
        },
        {
            dataField: 'role',
            text: 'Role',
        }
    ]

    /**
     * Render the users through Table component
     */
    return (
        <Box m="20px">
            <Header title="Users" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`users`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default Users
