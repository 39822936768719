import React from 'react'
import DataGridFilter, {
    SearchOptionsProp,
} from '../../../../../../components/DataGridFilter'
import Header from '../../../../../../components/Header'
import { Box } from '@mui/material'
import {GoogleLocaleMapping} from '../../../../../../interfaces/LocalizationType'
import {fetchGoogleLocaleMappings} from "../../../../../../actions/localization";
import GoogleLocaleMappingDetail from "./detail";

/**
 * Google Locale Mapping list
 *
 * @returns locale mapping list page
 */
function GoogleLocaleMappings() {
    const expandRow = (row: GoogleLocaleMapping) => (
        <GoogleLocaleMappingDetail key={row.id} wrapper={row} />
    )

    const onSearchPageUseQueryEvent = (searchOptions: SearchOptionsProp) => {
        return fetchGoogleLocaleMappings(searchOptions)
    }

    const columns = [
        {
            dataField: 'locale.code',
            text: 'Locale Code',
        },
        {
            dataField: 'languageCode',
            text: 'Language Code Mapping',
            sort: true,
        }
    ]

    /**
     * Render the google mapping through Table component
     */
    return (
        <Box m="20px">
            <Header title="Google Locale Mappings" />
            <DataGridFilter
                keyField="id"
                useQueryKey={`google-locale-mappings`}
                columns={columns}
                onSearchPageUseQueryEvent={onSearchPageUseQueryEvent}
                searchFilterCols={3}
                disabledMatchAll={true}
                expandRow={expandRow}
            />
        </Box>
    )
}

export default GoogleLocaleMappings