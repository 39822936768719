import {
    ASYNC_PREFERENCE,
    AUTH_TYPE,
    CONTENT_TYPE,
    ENDPOINT_TYPE,
    METHOD,
    PARAM_TYPE,
    QUERY_ASSIST_INTEGRATION_MODE
} from "../interfaces/SettingType";

export const endpointTypeOptions = [
    { value: ENDPOINT_TYPE.REST, label: 'REST' },
];

export const queryAssistIntegrationModeOptions = [
    { value: QUERY_ASSIST_INTEGRATION_MODE.DEFAULT, label: 'Default' },
];

export const authTypeOptions = [
    { value: AUTH_TYPE.OAUTH2, label: 'OAuth2' },
    { value: AUTH_TYPE.API_KEY, label: 'API Key' },
];

export const methodOptions = [
    { value: METHOD.POST, label: 'POST' },
    { value: METHOD.PUT, label: 'PUT' },
    { value: METHOD.PATCH, label: 'PATCH' },
];

export const contentTypeOptions = [
    { value: CONTENT_TYPE.APPLICATION_JSON, label: 'application/json' },
    { value: CONTENT_TYPE.APPLICATION_FORM_URLENCODED, label: 'application/x-www-form-urlencoded' },
];

export const paramTypeOptions = [
    { value: PARAM_TYPE.PATH, label: 'Path' },
    { value: PARAM_TYPE.QUERY, label: 'Query' },
    { value: PARAM_TYPE.BODY, label: 'Body' },
];

export const resumeAsyncPreferenceOptions = [
    { value: ASYNC_PREFERENCE.STATUS, label: 'Status' },
    { value: ASYNC_PREFERENCE.REDIRECT_URL, label: 'Redirect URL' },
];