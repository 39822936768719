import React, { FunctionComponent, useState } from 'react'
import { useMutation } from 'react-query'
import { Box, IconButton, TextField } from '@mui/material'
import {Formik} from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {connect} from "react-redux";
import {
    ContentTenantInterestMapping,
    ContentTenantInterestMappingRequest,
    ContentTenantInterestMappingWrapper, ReferenceItem
} from "../../../../../../interfaces/ContentAssistType";
import {
    createContentTenantInterestMapping, fetchRelationships,
    updateContentTenantInterestMapping
} from "../../../../../../actions/contentAssists";
import {Tenant} from "../../../../../../interfaces/TenantType";
import {fetchAllTenants} from "../../../../../../actions/tenant";
import {ApiError} from "../../../../../../interfaces/ErrorType";
import {useAuthQueryWithQueryFunction} from "../../../../../../extensions/UseAuthQuery";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import AutocompleteExt from "../../../../../../components/Autocomplete";
import ButtonExt from "../../../../../../components/ButtonExt";
import Header from "../../../../../../components/Header";
import InterestItems from "../../../default/reference/mapping/shared/InterestItems";
import {fetchDevelopmentStages} from "../../../../../../actions/developmentStage";

const contentTenantInterestMappingSchema = yup.object().shape({
    tenantId: yup.string().required('required'),
    eligibleRangeStart: yup.number().min(1).required('required'),
    eligibleRangeEnd: yup.number().min(1).required('required'),
    developmentStageId: yup.string().required('required'),
    relationshipId: yup.string().required('required'),
    // FIX ME: let's investigate why this is not working
    // interests: yup.array<KeyPair>(yup.object().shape(
    //     {
    //         key: yup.string().required('required'),
    //         value: yup.string().required('required')
    //     }
    // )).min(1).required('required'),
})

const ContentTenantInterestMappingDetail: FunctionComponent<ContentTenantInterestMappingWrapper> = ({
                                                                                                            userSetting,
                                                                                                            isNew = false,
                                                                                                            wrapper,
                                                                                                        }) => {
    const navigate = useNavigate()
    const [contentTenantInterestMappingRequest, setContentTenantInterestMappingRequest] = useState<ContentTenantInterestMappingRequest>(
        wrapper
            ? {
                ...wrapper,
                tenantId: wrapper.tenant!!.id!!,
                developmentStageId: wrapper.developmentStage?.id,
                relationshipId: wrapper.relationship?.id,
            }
            : {
                id: undefined,
                tenantId: undefined,
                developmentStageId: undefined,
                relationshipId: undefined,
                eligibleRangeStart: undefined,
                eligibleRangeEnd: undefined,
                interests: []
            }
    )

    /**
     * Mutate content tenant interest mapping create
     */
    const contentTenantInterestMappingCreateMutation = useMutation<ContentTenantInterestMapping, ApiError, ContentTenantInterestMappingRequest>(
        createContentTenantInterestMapping,
        {
            onSuccess: (data) => {
                setContentTenantInterestMappingRequest({
                    ...data,
                    tenantId: data.tenant!!.id!!
                })
            },
        }
    )

    /**
     * Mutate default content reference mapping update
     */
    const contentTenantInterestMappingUpdateMutation = useMutation<ContentTenantInterestMapping, ApiError, ContentTenantInterestMappingRequest>(
        updateContentTenantInterestMapping,
        {
            onSuccess: (data) => {
                setContentTenantInterestMappingRequest({
                    ...data,
                    tenantId: data.tenant!!.id!!
                })
            },
        }
    )

    /**
     * Fetch all tenants
     * */
    const tenantQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        Tenant[]
        >('tenants', fetchAllTenants, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch development stage list
     */
    const developmentStageQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('developmentStages', fetchDevelopmentStages, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    /**
     * Fetch relationship list
     * */
    const contentRelationshipQuery = useAuthQueryWithQueryFunction<
        undefined,
        ApiError,
        any[]
        >('contentRelationships', fetchRelationships, {
        refetchOnWindowFocus: false,
        enabled: true,
    })

    const availableTenantOptions = tenantQuery.data?.map((tenant) => {
        return {
            label: tenant.name,
            value: tenant.id,
        }
    })

    const availableDevelopmentStageOptions = developmentStageQuery.data?.map((developmentStage) => {
        return {
            value: developmentStage.id,
            label: developmentStage.type,
        }
    })

    const availableRelationshipOptions = contentRelationshipQuery.data?.map((contentRelationship) => {
        return {
            value: contentRelationship.id,
            label: contentRelationship.name,
        }
    })

    if (contentTenantInterestMappingCreateMutation.isSuccess) {
        navigate(`/content/tenant/interest/mapping`)
    }

    /**
     * Invoke an action to create/ update content tenant interest mapping
     * @param {*} e - event
     */
    const onSave = (values: ContentTenantInterestMappingRequest) => {
        if (!values.id) {
            contentTenantInterestMappingCreateMutation.mutate(values)
        } else {
            contentTenantInterestMappingUpdateMutation.mutate(values)
        }
    }

    /**
     * Page containing content tenant interest mapping details
     */
    return (
        <Box m="20px">
            {isNew && (
                <>
                    <Box
                        display="flex"
                        justifyContent="start"
                        mt="20px"
                        style={{ padding: `10px` }}
                    >
                        <IconButton
                            color="secondary"
                            onClick={() => navigate(`/content/tenant/interest/mapping`)}
                        >
                            <ArrowBackIcon /> Back
                        </IconButton>
                    </Box>

                    <Header title="Create Content Tenant Interest Mapping" />
                </>
            )}

            <Box style={{ marginBottom: `2em` }}>
                {contentTenantInterestMappingCreateMutation.isError && (
                    <ErrorMessage error={contentTenantInterestMappingCreateMutation.error} />
                )}

                {contentTenantInterestMappingUpdateMutation.isError && (
                    <ErrorMessage error={contentTenantInterestMappingUpdateMutation.error} />
                )}
            </Box>

            <Formik
                onSubmit={onSave}
                initialValues={contentTenantInterestMappingRequest}
                validationSchema={contentTenantInterestMappingSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            mb="30px"
                            gridTemplateColumns="repeat(2, minmax(0,1fr))"
                        >
                            {contentTenantInterestMappingRequest.id && (
                                <TextField
                                    variant="filled"
                                    type="text"
                                    label="Id"
                                    value={values.id}
                                    name="id"
                                />
                            )}

                            <AutocompleteExt
                                name="developmentStageId"
                                multiSelection={false}
                                label="Development Stage..."
                                selectedValue={values.developmentStageId}
                                onSelect={(value) => {
                                    setContentTenantInterestMappingRequest({
                                        ...contentTenantInterestMappingRequest,
                                        developmentStageId: value
                                    })
                                    values.developmentStageId = value
                                }}
                                options={availableDevelopmentStageOptions}
                                error={!!touched.developmentStageId && !!errors.developmentStageId}
                                helperText={touched.developmentStageId && errors.developmentStageId}
                            />

                            <AutocompleteExt
                                name="relationshipId"
                                multiSelection={false}
                                label="Relationship..."
                                selectedValue={values.relationshipId}
                                onSelect={(value) => {
                                    setContentTenantInterestMappingRequest({
                                        ...contentTenantInterestMappingRequest,
                                        relationshipId: value
                                    })
                                    values.relationshipId = value
                                }}
                                options={availableRelationshipOptions}
                                error={!!touched.relationshipId && !!errors.relationshipId}
                                helperText={touched.relationshipId && errors.relationshipId}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range Start"
                                value={values.eligibleRangeStart}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeStart"
                                error={!!touched.eligibleRangeStart && !!errors.eligibleRangeStart}
                                helperText={touched.eligibleRangeStart && errors.eligibleRangeStart}
                            />

                            <TextField
                                variant="filled"
                                type="number"
                                label="Eligible Range End"
                                value={values.eligibleRangeEnd}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="eligibleRangeEnd"
                                error={!!touched.eligibleRangeEnd && !!errors.eligibleRangeEnd}
                                helperText={touched.eligibleRangeEnd && errors.eligibleRangeEnd}
                            />

                            <AutocompleteExt
                                name="tenantId"
                                multiSelection={false}
                                label="Tenant"
                                selectedValue={values.tenantId}
                                options={availableTenantOptions}
                                onBlurEvent={handleBlur}
                                onSelect={(v) => {
                                    setContentTenantInterestMappingRequest({
                                        ...contentTenantInterestMappingRequest,
                                        tenantId: v
                                    })
                                    values.tenantId = v
                                }}
                                error={!!touched.tenantId && !!errors.tenantId}
                                helperText={touched.tenantId && errors.tenantId}
                                editable={!contentTenantInterestMappingRequest.id}
                            />
                        </Box>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(1, minmax(0,1fr))"
                        >
                            <InterestItems
                                label="Interests"
                                values={values.interests}
                                onBlurEvent={handleBlur}
                                onChangeEvent={(v) => {
                                    setContentTenantInterestMappingRequest({
                                        ...contentTenantInterestMappingRequest,
                                        interests: v
                                    })
                                    values.interests = v
                                }}
                                // FIX ME: let's investigate why this is not working
                                // error={!!touched.interests && !!errors.interests}
                                // helperText={touched.interests && errors.interests}
                            />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="end"
                            mt="20px"
                            gap="20px"
                        >
                            <ButtonExt
                                type="submit"
                                value={
                                    contentTenantInterestMappingCreateMutation.isLoading ||
                                    contentTenantInterestMappingUpdateMutation.isLoading
                                        ? 'Saving'
                                        : 'Save'
                                }
                                disabled={
                                    (
                                        contentTenantInterestMappingCreateMutation.isLoading
                                        || contentTenantInterestMappingUpdateMutation.isLoading
                                        || !userSetting?.administratorRolePermission?.contentAssistPermission?.editable
                                        || values.interests?.length === 0
                                        || (values.interests?.find(each => each.key.trim().length === 0 || each.value.trim().length === 0) !== undefined)
                                    )
                                }
                            />
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

/**
 * Connect and retrieve the current switch tenant id through redux state
 * @param {*} state - state from redux state
 * @returns
 */
const mapStateToProps = (state: any) => {
    return { userSetting: state.userSetting.data }
}

export default connect(mapStateToProps)(ContentTenantInterestMappingDetail)